import { createSlice } from '@reduxjs/toolkit'

/** ***************************************************************** */
// TODO: migrate state handled by errorSlice to here. The legacy error
// states are limited to dcams upload/Error, upload/Header,
// and upload/UploadIndex.
/** ***************************************************************** */

const notifyState = {
    notify: false,
    notifyTitle: '',
    notifySubTitle: '',

    info: false,
    warning: false,
    error: false,
    success: false,
    alert: false,
}


const notifySlice = createSlice( {
    name: 'notify',
    initialState: notifyState,

    reducers: {
        updateNotifyState: ( draftState, action ) => {
            return {
                ...draftState,
                ...action.payload,
            }
        },
    },
} )

export const {
    updateNotifyState,
} = notifySlice.actions

export default notifySlice.reducer

