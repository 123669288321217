/* eslint-disable no-param-reassign */
import {
    createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import { dataService } from '../services/dataService'
import {
    fireJourneyCallback, setJourneyStatus
} from './journey.slice'

import { bouncerMessages } from './messages'
import { bouncerMatchFailure } from './actions'

import {
    logger, // logJson
} from '../utilities/helpers'

const logJsonError = ( description, json ) => console.error( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #05F0ED' )
const logJson = ( description, json ) => {
    console.log( `%c [${description}]`, 'background: #222; color: #7EDEE0' )
    console.log( `%c ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #bada55' )
}

const ssnState = {
    verificationInProgress: false,
    viewingResult: false,
    ssnResult: '',
    resultCount: 0,

    targetFields: {
        'IDMatch5.0.RJRT.CUSTOM.BOUNCER': [ 'fn', 'ln', 'addr', 'city', 'state', 'zip', 'dob', 'ssn', 'age', ],
        'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN': [ 'fn', 'ln', 'addr', 'city', 'state', 'zip', 'dob', 'ssn', 'age', ],
        'AgeMatch5.0': [ 'fn', 'ln', 'addr', 'zip', 'age', ],
    },

    errorResponse: {},
    promiseRejection: {},
}

export const ssnVerify = createAsyncThunk(
    'ssn/ssnVerify',
    async ( _payload, thunkAPI ) => {
        const { service, } = thunkAPI.getState().journey.config.services.ssn
        const { clientData, } = thunkAPI.getState().init
        const target = thunkAPI.getState().ssn.targetFields[ service ].reduce(( acc, curr ) => {
            if (
                curr === 'ssn'
                && ( !Object.prototype.hasOwnProperty.call( clientData, 'ssn' ) || !clientData?.ssn.length )
                && Object.prototype.hasOwnProperty.call( clientData, 'ssn4' )
            ) {
                acc[ curr ] = thunkAPI.getState().init.clientData?.[ 'ssn4' ]
            } else {
                acc[ curr ] = thunkAPI.getState().init.clientData?.[ curr ]
            }
            return acc
        }, {} )

        const { additionalData, } = clientData
        // logJson( 'additionalData', additionalData )

        const payload = {
            request_id: thunkAPI.getState().init.clientData.request_id,
            journey_stop: thunkAPI.getState().journey.journeyStop,
            service,
            target,
            additionalData,
        }

        // console.log( '[INITIATING SSN VERIFY]' )
        // logJson( 'SSN VERIFY PAYLOAD', payload )

        const response = await dataService.ssnVerify( payload )

        if ( Object.keys( bouncerMessages ).includes( response?.result?.detail )) {
            logger( '[BOUNCER MESSAGE DETAIL RECEIVED]', response.result.detail )
            thunkAPI.dispatch( bouncerMatchFailure( { detail: response.result.detail, } ))
        }

        const status = response?.status
        if ( status ) {
            thunkAPI.dispatch( setJourneyStatus( status ))
        }

        /** *************************************************************************
         * added for Alto
        ************************************************************************* */
        // eslint-disable-next-line no-promise-executor-return
        const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
        if (
            ( response?.result?.action && response?.result?.action !== 'PASS' )
            || response?.error
        ) {
            const { journey, } = thunkAPI.getState().journey.config
            // if ( response?.error?.code === 'QRY6' ) {
            //     const message = {
            //         status: '',
            //         component: 'PHONEMATCH',
            //         error: 'BAD TOKEN',
            //         event: '',
            //     }
            // }
            if ( journey[ journey.length - 1 ].includes( 'ssn' )) { // is last stop
                await sleep( 1000 )
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch( fireJourneyCallback( { status: 'FAIL', } ))
            } else { // not last stop
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch( fireJourneyCallback( { status: 'PENDING', } ))
            }
        } else {
            await sleep( 1000 )
            // See TODO at journey.slice fireJourneyCallback definition
            thunkAPI.dispatch( fireJourneyCallback( { status: 'PASS', } ))
        }
        /** *********************************************************************** */

        return response
    }
)

const ssnSlice = createSlice( {
    name: 'ssn',
    initialState: ssnState,

    reducers: {
        ssnVerificationInProgress: ( draftState, action ) => {
            draftState.verificationInProgress = action.payload
        },
        ssnViewingResult: ( draftState, action ) => {
            draftState.viewingResult = action.payload
        },
        resetResultCount: draftState => {
            draftState.resultCount = 0
        },
    },

    extraReducers: builder => {
        builder
            .addCase( ssnVerify.fulfilled, ( draftState, action ) => {
                logJson( 'ssnVerify received', action.payload )
                if ( action.payload?.result ) {
                    draftState.ssnResult = action.payload.result.action
                }
                // example error
                // [SSNVERIFY RECEIVED] {
                //     "meta": {
                //         "timestamp": "2022-12-12T10:55:33"
                //     },
                //     "error": {
                //         "message": "Input Failed Validation",
                //         "code": "QRY2",
                //         "detail": "Age input must conform to (^>=?21$|^21+)."
                //     }
                // }
                if ( action.payload?.error ) {
                    draftState.errorResponse = action.payload.error
                    draftState.ssnResult = 'FAIL'
                }

                draftState.verificationInProgress = false
                draftState.resultCount += 1
            } )
            .addCase( ssnVerify.rejected, ( draftState, action ) => {
                // action.error is a serialized error value
                try {
                    logJsonError( 'Error', action.error )
                } catch ( e ) {
                    console.error( e )
                } finally {
                    console.log( action.error )
                    draftState.promiseRejection = action.error
                    draftState.ssnResult = 'FAIL'
                    draftState.verificationInProgress = false
                }
            } )
    },
} )

export const {
    ssnVerificationInProgress,
    ssnViewingResult,
    setResultCount,
} = ssnSlice.actions

export default ssnSlice.reducer
