import AWS from 'aws-sdk/dist/aws-sdk-react-native'
import axios from 'axios'

// import {
//     logJson, logger
// } from '../utilities/helpers'

const _getS3Credentials = async () => {
    const response = await fetch(`${process.env.REACT_APP_FRONTEND_URL}/s3_creds`)
    const credentials = await response.json()
    return credentials
}

/**
 * @param {Object} params - {Key: 'key', Body: <body>}
 */
const _postS3ManagedUpload = async params => {
    const credentials = await _getS3Credentials()
    const region = process.env.REACT_APP_AWS_REGION
    const bucketName = process.env.REACT_APP_AWS_BUCKET

    AWS.config.update({
        region,
        credentials,
    })
    // S3 ManagedUpload class supports multipart uploads
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3/ManagedUpload.html
    const upload = new AWS.S3.ManagedUpload({
        params: {
            ...params,
            Bucket: bucketName,
        },
    })
    const promise = upload.promise()
    return promise.then(
        /**
         * data (map)
         * Location (String) the URL of the uploaded object
         * ETag (String) the ETag of the uploaded object
         * Bucket (String) the bucket to which the object was uploaded
         * Key (String) the key to which the object was uploaded
         */
        data => {
            // console.log( '[S3 UPLOAD SUCCESS :: DATASERVICE]' )
            return {
                response: data,
                success: true,
            }
        },
        err => {
            // eslint-disable-next-line no-console
            console.log('[UPLOAD ERROR :: DATASERVICE]', err)
            return { error: err }
        },
    )
}

const _processProveRedirects = async path => {
    try {
        const response = await axios.get(path, {
            maxRedirects: 2,
            headers: {
                origin: process.env.REACT_APP_FRONTEND_URL,
            },
            // `beforeRedirect` defines a function that will be called before redirect.
            // Use this to adjust the request options upon redirecting,
            // to inspect the latest response headers,
            // or to cancel the request by throwing an error
            // If maxRedirects is set to 0, `beforeRedirect` is not used.
            // eslint-disable-next-line consistent-return
            beforeRedirect: (options, { headers }) => {
                if (headers['location'].includes('idresponse.com')) {
                    return response.headers['location']
                }
            },
        })
        return response.headers['location']
    } catch (error) {
        return error
    }
}

const _getIp = async () => {
    try {
        const response = await fetch(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IPDATA_APIKEY}`)

        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json()
            return json
        }
        const text = await response.text()
        return text
    } catch (error) {
        return Promise.reject(error)
    }

    // if ( !response.ok ) {
    //     return Promise.reject(
    //         new Error(
    //             JSON.stringify( {
    //                 status: response.status,
    //             } )
    //         )
    //     )
    // }
}

const _getIpData = async () => {
    try {
        const _defaultResponse = await fetch(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IPDATA_APIKEY}`)

        const defaultResponse = await _defaultResponse.json()

        const _carrierResponse = await fetch(
            `https://api.ipdata.co/${defaultResponse?.ip}/carrier?api-key=${process.env.REACT_APP_IPDATA_APIKEY}`,
        )

        const carrierResponse = await _carrierResponse.json()

        return {
            ...defaultResponse,
            mobileCarrier: carrierResponse || {},
        }
    } catch (error) {
        return Promise.reject(error)
    }
}

// TODO: refactor
const _postBackend = async (payload, path) => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/${path}`,
        // `${REACT_APP_BACKEND_URL}/${path}`,
        {
            method: 'POST',
            body: JSON.stringify(payload),
        },
    )

    // if ( !response.ok ) {
    // if ( response.status >= 500 ) {
    //     return Promise.reject(
    //         new Error(
    //             JSON.stringify( {
    //                 status: response.status,
    //             } )
    //         )
    //     )
    // }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
        const json = await response.json()
        return json
    }
    const text = await response.text()
    return text
}

/** ********** DCAMS */
const fetchInitial = data => _postBackend(data, 'api/frontend/getClientData.php')
const sendEmail = data => _postBackend(data, 'api/frontend/sendEmail.php')
const dcamsVerifyCaptureScan = data => _postBackend(data, 'api/frontend/process.php')
const dcamsVerifyCaptureSelfie = data => _postBackend(data, 'api/frontend/selfie/process.php')
const verificationStatusCheck = data => _postBackend(data, 'api/frontend/statusCheck.php')
const closeFinished = data => _postBackend(data, 'api/frontend/storeCloseReason.php')
const s3ManagedUpload = data => _postS3ManagedUpload(data)

/** ********** KBA */
const getKbaQuestions = data => _postBackend(data, 'vx_api/v1/questions')
const checkKbaAnswers = data => _postBackend(data, 'vx_api/v1/answers')
const fireCallback = data => _postBackend(data, 'callback/journey_cb.php')

/** ********** Phonematch */
const getIp = () => _getIp()
const getIpData = () => _getIpData()
const carrierVerify = data => _postBackend(data, 'vx_api/v1/carrier_verify')
const carrierVerifyContinue = data => _postBackend(data, 'vx_api/v1/carrier_verify_continue')
const otpVerify = data => _postBackend(data, 'vx_api/v1/otp_verify')
const otpVerifyContinue = data => _postBackend(data, 'vx_api/v1/otp_verify_continue')
// const getExternal = path => _getExternal( path )
const getExternal = path => _processProveRedirects(path)

/** ********** SSN */
const ssnVerify = data => _postBackend(data, 'vx_api/v1/proxy_gateway')

/** ********** Device detection */
const reportDevice = data => _postBackend(data, 'vx_api/v1/device_detect')

/** ********** A/B Test */
const getABStatus = data => _postBackend(data, 'vx_api/v1/ab_status')

/** ********** Block Check */
const blockCheck = data => _postBackend(data, 'api/frontend/blockCheck.php')

export const dataService = {
    blockCheck,
    fetchInitial,
    sendEmail,
    dcamsVerifyCaptureScan,
    dcamsVerifyCaptureSelfie,
    verificationStatusCheck,
    closeFinished,
    s3ManagedUpload,

    getKbaQuestions,
    checkKbaAnswers,
    fireCallback,

    getIp,
    getIpData,
    carrierVerify,
    carrierVerifyContinue,
    otpVerify,
    otpVerifyContinue,
    getExternal,

    ssnVerify,

    reportDevice,

    getABStatus,
}
