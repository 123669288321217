import {
    createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import { dataService } from '../../services/dataService'


const closeState = {
    finished: false,
    closeError: false,
}


export const finished = createAsyncThunk(
    'close/finished',
    // eslint-disable-next-line no-unused-vars
    async ( payload, thunkAPI ) => {
        const response = await dataService.closeFinished( payload )
        return response
    }
)


const closeSlice = createSlice( {
    name: 'close',
    initialState: closeState,

    reducers: {},

    extraReducers: builder => {
        builder
            .addCase( finished.fulfilled, draftState => {
                window.postMessage( 'CLOSE', '*' )
                draftState.finished = true
            } )
            .addCase( finished.rejected, draftState => {
                window.postMessage( 'CLOSE', '*' )
                draftState.finished = false
                draftState.closeError = true
            } )
    },
} )

export default closeSlice.reducer
