import React from 'react'
// import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
    BrowserRouter as Router // , Redirect
} from 'react-router-dom'

import Nav from './journeyStops/dcams/default/components/navigation/Nav'


import './css/dcamsGlobals.css'

export default function Error( {
    message,
    description,
} ) {
    // const { pathRoot, } = useSelector( state => state.journey )

    // const renderRedirect = () => <Redirect to="/error" />
    // const renderRedirect = () => <Redirect to={ `${pathRoot}/error` } />
    // const renderRedirect = () => <Redirect to={ `${window.top.document.location.origin}/error` } />

    return (
        <>
            <Router>
                {/* {renderRedirect()} */}
                <Nav />
            </Router>

            <Container className="h-100 mt-5">
                <div
                    style={ {
                        marginTop: '32vh',
                    } }
                >
                    <Row className="text-center h-100 justify-content-center align-items-center">
                        <Col>
                            <Row className="text-center">
                                <Col>
                                    <i className="fal fa-exclamation-circle error-icon" />
                                </Col>
                            </Row>

                            <Row className="text-center mt-3">
                                <Col>
                                    <h2 className="font-weight-bold">{message}</h2>
                                </Col>
                            </Row>

                            <Row className="text-center mt-0 mb-3">
                                <Col>
                                    <p>{description}</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}

