/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import * as qs from 'query-string'
import { useVisitorData, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react'

import { blockCheck, registerFailure, badNavigation, fetchInitialState, updateMissingParams } from './state/init.slice'
import { fetchJourneyConfig, updateJourney } from './state/journey.slice'
import { getDeviceIpData, setFingerprint } from './state/deviceDetect.slice'
import { updateCarrierVerifyContinueTemplate } from './state/phonematch.slice'

import { useGetHandoff } from './hooks/journeyHooks'

import { useMetadata } from './index'

import KbaManager from './journeyStops/kba/manager.js'
import DcamsManager from './journeyStops/dcams/manager.js'
import PhonematchManager from './journeyStops/phonematch/manager.js'
import SsnManager from './journeyStops/ssn/manager.js'

import AppLoading from './App.Loading'
import Error from './App.Error'

export default function AppManager() {
    // const { request_id } = useMetadata()
    const { carrier, request_id } = useMetadata()
    const {
        initialized,
        initError,
        errorMessage: initErrorMessage,
        errorDescription: initErrorDescription,
    } = useSelector(state => state.init)
    const {
        config: { usePostMessage, useEventCallbacks, messageHandler },
        journeyStop,
        journeyLoaded,
        journeyLoading,
        journeyError,
        errorMessage: journeyErrorMessage,
        errorDescription: journeyErrorDescription,
    } = useSelector(state => state.journey)

    const {
        fetchQuestionsError,
        checkAnswersError,
        questionsTimeout,
        moreDetailsError,
        tryAgainError,
        errorMessage: kbaErrorMessage,
        errorDescription: kbaErrorDescription,
    } = useSelector(state => state.kba)

    /** JOURNEY GLOBALS */
    window.usePostMessage = usePostMessage
    window.useEventCallbacks = useEventCallbacks
    window.veratadMessageHandler = messageHandler
    /** ********************** */

    const { getHandoff } = useGetHandoff()
    const dispatch = useDispatch()

    const queryParams = qs.parse(window.location.search, {
        arrayFormat: 'comma',
    })
    // const { parent_loading, errorMessage, request_id, journey_id, device_handoff, missing_params, vfp } = queryParams
    const { parent_loading, errorMessage, journey_id, device_handoff, missing_params, vfp, ab_variant } = queryParams
    const requestIdPayload = { request_id }
    // const journeyIdPayload = { journey_id }
    const fetchJourneyConfigPayload = { journey_id, request_id, carrier }

    // ****************************************************
    // ----------------------------------------------------
    // ** FingerprintJS Pro **
    const [fingerprintSendAttempted, setFingerprintSendAttempted] = useState(false)
    const [fingerprintSent, setFingerprintSent] = useState(false)
    const [cachedRequestId, setCachedRequestId] = useState(null)
    const [visitorData, setVisitorData] = useState(null)

    // const {
    //     isLoading: fpjsLoading,
    //     data: visitorData,
    //     getData,
    // } = useVisitorData({ extendedResult: true, tag: { request_id: request_id } }, { immediate: true })
    const { isLoading: fpjsLoading, getData } = useVisitorData(
        { extendedResult: true, tag: { request_id: request_id } },
        { immediate: false },
    )

    useEffect(() => {
        getData()
            .then(data => {
                setVisitorData(data)
            })
            .catch(error => {
                if (error.message === FingerprintJSPro.ERROR_RATE_LIMIT) {
                    const _message = 'Too many requests. Wait 2 seconds and try again.'
                    console.error(error)
                    return <AppLoading journeyId={journey_id} message={_message} />
                } else {
                    console.error('getData error', error)
                    dispatch(setFingerprint({ error: error.message }))
                }
            })
    }, [])

    useEffect(() => {
        if (cachedRequestId && visitorData && !fingerprintSendAttempted && !fpjsLoading) {
            // console.log(`FP EFFECT ${performance.now()}`)
            setFingerprintSendAttempted(true)

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    request_id: cachedRequestId,
                    fpjs_data: visitorData,
                }),
            }

            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/frontend/fingerprint`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`)
                    }
                    // console.log('Fingerprint posted')
                    return response.json()
                })
                .then(() => {
                    setFingerprintSent(true)
                })
                .catch(_error => {
                    console.error('Error storing fingerprint:', _error)
                })

            dispatch(setFingerprint(visitorData))
        }
    }, [cachedRequestId, visitorData, fingerprintSendAttempted, fpjsLoading])

    useEffect(() => {
        if (visitorData) {
            // console.log(`BLOCKCHECK EFFECT ${performance.now()}`)
            // console.log('visitorData :: blockCheck', visitorData)
            // console.log('request_id :: blockCheck', request_id)
            console.log('cachedRequestId :: blockCheck', cachedRequestId)
            dispatch(blockCheck({ visitor_id: visitorData.visitorId, request_id }))
        }
    }, [visitorData])
    // ----------------------------------------------------
    // ****************************************************

    // ****************************************************
    // ----------------------------------------------------
    // START: Handle api-supplied journey order
    // ** A/B variant handling
    // useEffect(() => {
    //     console.log('[ABV PARAM', ab_variant || 'none')
    //     console.log('[QPARAMS]', queryParams)
    // }, [queryParams])
    // ----------------------------------------------------
    // ****************************************************

    useEffect(() => {
        if (request_id) {
            console.log('request_id in setCached', request_id)
            setCachedRequestId(request_id)
        }
    }, [request_id])

    // useEffect(() => {
    //     if (!journeyLoaded && journey_id) {
    //         dispatch(fetchJourneyConfig(journeyIdPayload)) // START: add carrier and request_id
    //     }
    // }, [])
    useEffect(() => {
        // if (!journeyLoaded && journey_id) {
        // console.log(
        //     '[fetch journey config effect]',
        //     'journeyLoaded',
        //     journeyLoaded,
        //     'journey_id',
        //     journey_id,
        //     'carrier',
        //     carrier,
        //     'request_id',
        //     request_id,
        // )
        // [fetch journey config effect] journeyLoaded false journey_id 21-8dca8e1e-610f-438d-a1b0-4f50efe27765 carrier null request_id efc08f04-d24e-4f83-8e4c-629fa5edd51c
        if (!journeyLoaded && journey_id && carrier && request_id) {
            // console.log('carrier in effect for fetchJourneyConfig', carrier)
            /*
            [Log] carrier in effect for fetchJourneyConfig – "AT&T" (main.f220cc16.js, line 2)
            [Log] [fetchJourneyConfig thunk carrier] – "AT&T" (main.f220cc16.js, line 2)
            [Log] [STOPS] – ["phonematch_21", "ssn_21", "dcams_21"] (3) (main.f220cc16.js, line 2)
            */
            dispatch(fetchJourneyConfig(fetchJourneyConfigPayload))
        }
    }, [])

    // Wawa failure (double parse issue) for missing_params

    // 1
    // useEffect(() => {
    //     if (!initialized) {
    //         if (Object.keys(queryParams).length > 0) {
    //             if (errorMessage) {
    //                 dispatch(registerFailure(queryParams))
    //             }
    //             if (request_id) {
    //                 dispatch(fetchInitialState(requestIdPayload))
    //                 dispatch(getDeviceIpData())
    //             }
    //             console.log('missing_params', missing_params)
    //             console.log('typeof missing_params', typeof missing_params)
    //             if (typeof missing_params === 'string' && missing_params.length > 0) {
    //                 try {
    //                     const parsedMissingParams = JSON.parse(missing_params)
    //                     console.log('updateMissingParams', parsedMissingParams)
    //                     dispatch(updateMissingParams(parsedMissingParams))
    //                 } catch (_error) {
    //                     console.error('Failed to parse missing_params:', _error)
    //                 }
    //             }
    //         } else {
    //             dispatch(badNavigation())
    //         }
    //     }
    // }, [])
    // }, [visitorData])
    // }, [visitorData, initialStateFetched])
    // }, [initialStateFetched])

    // 2

    // useEffect(() => {
    //     if (!initialized) {
    //         if (Object.keys(queryParams).length > 0) {
    //             if (errorMessage) {
    //                 dispatch(registerFailure(queryParams))
    //             }
    //             if (request_id) {
    //                 dispatch(fetchInitialState(requestIdPayload))
    //                 dispatch(getDeviceIpData())
    //             }
    //             if (missing_params) {
    //                 let reconstructedMissingParams = ''
    //                 if (Array.isArray(missing_params) && missing_params.length > 0) {
    //                     reconstructedMissingParams = missing_params.map(decodeURIComponent).join(',')
    //                     reconstructedMissingParams = `[${reconstructedMissingParams}]`
    //                 }
    //                 try {
    //                     const parsedMissingParams = JSON.parse(reconstructedMissingParams)
    //                     console.log('updateMissingParams', parsedMissingParams)
    //                     dispatch(updateMissingParams(parsedMissingParams))
    //                 } catch (_error) {
    //                     console.error('Failed to parse missing_params:', _error)
    //                 }
    //             }
    //         } else {
    //             dispatch(badNavigation())
    //         }
    //     }
    // }, [])
    // // }, [visitorData])
    // // }, [visitorData, initialStateFetched])
    // // }, [initialStateFetched])

    // useEffect(() => {
    //     if (!initialized) {
    //         if (Object.keys(queryParams).length > 0) {
    //             if (errorMessage) {
    //                 dispatch(registerFailure(queryParams))
    //             }
    //             if (request_id) {
    //                 dispatch(fetchInitialState(requestIdPayload))
    //                 dispatch(getDeviceIpData())
    //             }
    //             console.log('missing_params', missing_params)
    //             console.log('typeof missing_params', typeof missing_params)

    //             if (typeof missing_params === 'string' && missing_params.length > 0) {
    //                 try {
    //                     const parsedMissingParams = JSON.parse(missing_params)
    //                     console.log('updateMissingParams (string)', parsedMissingParams)
    //                     dispatch(updateMissingParams(parsedMissingParams))
    //                 } catch (_error) {
    //                     console.error('Failed to parse missing_params:', _error)
    //                 }
    //             } else if (Array.isArray(missing_params) && missing_params.length > 0) {
    //                 console.log('updateMissingParams (object)', missing_params)
    //                 dispatch(updateMissingParams(missing_params))
    //             }
    //         } else {
    //             dispatch(badNavigation())
    //         }
    //     }
    // }, [])

    useEffect(() => {
        if (!initialized) {
            if (Object.keys(queryParams).length > 0) {
                if (errorMessage) {
                    dispatch(registerFailure(queryParams))
                }
                if (request_id) {
                    dispatch(fetchInitialState(requestIdPayload))
                    dispatch(getDeviceIpData())
                }
                console.log('missing_params', missing_params)
                console.log('typeof missing_params', typeof missing_params)

                if (typeof missing_params === 'string' && missing_params.length > 0) {
                    let decodedParams = decodeURIComponent(missing_params)

                    // Check if further decoding is necessary
                    try {
                        decodedParams = decodeURIComponent(decodedParams)
                    } catch (error) {
                        // If decoding fails, it means the parameter was not double-encoded
                    }

                    try {
                        const parsedMissingParams = JSON.parse(decodedParams)
                        console.log('updateMissingParams', parsedMissingParams)
                        dispatch(updateMissingParams(parsedMissingParams))
                    } catch (_error) {
                        console.error('Failed to parse missing_params:', _error)
                    }
                } else if (Array.isArray(missing_params) && missing_params.length > 0) {
                    console.log('updateMissingParams', missing_params)
                    dispatch(updateMissingParams(missing_params))
                }
            } else {
                dispatch(badNavigation())
            }
        }
    }, [])

    useEffect(() => {
        if (initialized && Object.keys(queryParams).length > 0 && vfp) {
            dispatch(
                updateCarrierVerifyContinueTemplate({
                    vfp,
                    request_id,
                }),
            )
        }
    }, [])

    // useEffect(() => {
    //     if (journeyStop) {
    //         console.log('journeyStop', journeyStop)
    //     }
    // }, [journeyStop])

    if (initError) {
        console.error('Init error:', initErrorMessage, initErrorDescription)
        return <Error message={initErrorMessage} description={initErrorDescription} />
    }

    // if (fetchQuestionsError || (checkAnswersError && !questionsTimeout) || moreDetailsError || tryAgainError) {
    //     console.error('KBA error:', kbaErrorMessage, kbaErrorDescription)
    //     return <Error message={kbaErrorMessage} description={kbaErrorDescription} />
    // }
    if ((checkAnswersError && !questionsTimeout) || moreDetailsError || tryAgainError) {
        console.error('KBA error:', kbaErrorMessage, kbaErrorDescription)
        return <Error message={kbaErrorMessage} description={kbaErrorDescription} />
    }

    if (journeyError) {
        console.error('Journey error:', journeyErrorMessage, journeyErrorDescription)
        return <Error message={journeyErrorMessage} description={journeyErrorDescription} />
    }

    const testLoad = false
    if (testLoad || parent_loading || journeyLoading || !initialized) {
        return <AppLoading journeyId={journey_id} />
    }

    return (
        <Switch>
            <Route path='/dcams/:company_id'>
                <DcamsManager />
            </Route>
            <Route path='/kba/:company_id'>
                <KbaManager />
            </Route>
            <Route path='/phonematch/:company_id'>
                <PhonematchManager />
            </Route>
            <Route path='/ssn/:company_id'>
                <SsnManager />
            </Route>
            <Route path={`${process.env.REACT_APP_FRONTEND_URL}/error`}>
                <Error message={initErrorMessage} description={initErrorDescription} />
            </Route>
            <Route
                path='/'
                render={() => {
                    let pathElements
                    if (device_handoff) {
                        dispatch(updateJourney(device_handoff))
                        pathElements = device_handoff.split('_')
                    } else if (journeyStop) {
                        pathElements = journeyStop.split('_')
                    } else {
                        pathElements = getHandoff().split('_')
                    }
                    return <Redirect push to={`/${pathElements[0]}/${pathElements[1]}`} />
                }}
            />
        </Switch>
    )
}
