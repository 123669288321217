/* eslint-disable */

import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
// import { ResizeObserver } from '@juggle/resize-observer'
import { MantineProvider, Global } from '@mantine/core'
import { FpjsProvider, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react'

import * as qs from 'query-string'
import { persistor, store } from './store'
// import { store } from './store'

import AppLoading from './App.Loading'
import AppManager from './App.Manager'

// eslint-disable-next-line no-unused-vars
import { inIframe } from './utilities/helpers'
import CustomFonts from './utilities/customFonts'

// For defining VX theme defaults
import appTheme from './App.theme'

const logJson = (description, json) =>
    console.log(`%c${description.toUpperCase()} ${JSON.stringify(json, null, 4)}`, 'background: #222; color: #bada55')

const queryParams = qs.parse(window.location.search, { arrayFormat: 'comma' })
const { request_id, journey_id } = queryParams

/** **************************** PERSIST BLACKLIST **************************** */
// Legacy patch: accommodate clients for whom persistence is problematic in dev
// or prod environments. Such a blacklist or whitelist should be managed by the
// backend in non-legacy.
const persistBlacklist = ['21-4ea8651d-973a-4264-b692-751a97b98049']
const persistState = !persistBlacklist.includes(journey_id)
/** *************************************************************************** */

/** ****************** MOCK REACT NATIVE WEBVIEW POSTMESSAGE ****************** */
if (!window.ReactNativeWebView) {
    window.ReactNativeWebView = {
        postMessage: m => logJson('[react native webview mock post message]\n', JSON.parse(m)),
    }
}
/** *************************************************************************** */

// /** ************************* MOCK WINDOW POSTMESSAGE ************************* */
// if ( process.env.NODE_ENV !== 'production' ) {
//     window.top.mockPostMessage = {
//         postMessage: m => logJson( '[mock window post message - REMOVE FOR PRODUCTION]\n', JSON.parse( m )),
//     }
// }
// /** *************************************************************************** */

// /** ********************** window.ResizeObserver POLYFILL ********************** */
// if ( !Object.prototype.hasOwnProperty.call( window, 'ResizeObserver' )) {
//     window.ResizeObserver = ResizeObserver
// }
// /** **************************************************************************** */

/** *********************** String.replaceAll POLYFILL *********************** */
if (!String.prototype.replaceAll) {
    // eslint-disable-next-line no-extend-native, func-names
    String.prototype.replaceAll = function (str, newStr) {
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr)
        }
        return this.replace(new RegExp(str, 'g'), newStr)
    }
}
/** ************************************************************************** */

function VxGlobalStyles() {
    // TODO: combine with App.theme.js
    return (
        <Global
            styles={{
                'html, body': { height: '100%' },
                // body: {
                // margin: 0,
                // minHeight: '100vh',
                // display: 'flex',
                // flexFlow: 'column',
                // },

                // see:
                // https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts#L96
                // https://github.com/mantinedev/mantine/discussions/2789#discussioncomment-3963198
                // https://codesandbox.io/s/amazing-fast-n7edl5?file=/src/App.tsx:437-468
                // setting this at the nativeThemeMapper level does not work in iOS Safari
                '[class*="mantine"]:focus': {
                    // outlineOffset: 0,
                    // outline: `2px solid orangered`,
                    outline: 'transparent',
                },
            }}
        />
    )
}

/** **************************** METADATA **************************** */
const MetadataContext = createContext()
export const useMetadata = () => useContext(MetadataContext)

const Wrapper = () => {
    const [metadata, setMetadata] = useState(null)

    useEffect(() => {
        const _getIpData = async () => {
            try {
                const _ipdata = await fetch(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IPDATA_APIKEY}`)
                const ipdata = await _ipdata.json()
                const _carrier = await fetch(
                    `https://api.ipdata.co/${ipdata?.ip}/carrier?api-key=${process.env.REACT_APP_IPDATA_APIKEY}`,
                )
                const carrier = await _carrier.json()
                // --------------------------------------------------------------------------------------------
                // console.log('carrier response', carrier)
                // --> mobile example: [Log] carrier response – {name: "AT&T", mcc: "310", mnc: "030"}
                // --> non-mobile example: carrier response - null
                console.log('metadata', { carrier, request_id })
                // --> mobile example: [Log] metadata – {carrier: {name: "AT&T", mcc: "310", mnc: "030"}, request_id: "972146b3-24b2-4c21-9450-daf2a5c71e5b"}
                // --> non-mobile example: metadata - {carrier: null, request_id: 'c87e77fe-c5eb-4ec2-9b8c-d417717aefb6'}
                // --------------------------------------------------------------------------------------------
                if (carrier && carrier?.name) {
                    setMetadata({ carrier: carrier.name, request_id })
                } else {
                    setMetadata({ carrier: 'NONE', request_id })
                }
            } catch (error) {
                console.error(error)
            }
        }

        _getIpData()
    }, [])

    if (!metadata) {
        return <AppLoading journeyId={journey_id} />
    }

    return (
        <MetadataContext.Provider value={metadata}>
            <AppManager />
        </MetadataContext.Provider>
    )
}

/** ****************************************************************** */

/* <React.StrictMode> */
ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            {persistState ? (
                <PersistGate loading={<AppLoading />} persistor={persistor}>
                    <MantineProvider withGlobalStyles withNormalizeCSS theme={appTheme}>
                        {/* <FpjsProvider
                            loadOptions={ {
                                apiKey: '<PUBLIC_API_KEY>',
                            } }
                        > */}
                        <FpjsProvider
                            loadOptions={{
                                apiKey: 'vCagpcTgawWWhGylTeva',
                                endpoint: ['https://vx-fingerprint.idresponse.com', FingerprintJSPro.defaultEndpoint],
                                scriptUrlPattern: [
                                    'https://vx-fingerprint.idresponse.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
                                    FingerprintJSPro.defaultScriptUrlPattern,
                                ],
                            }}
                        >
                            <VxGlobalStyles />
                            <CustomFonts />
                            {/* <MetadataContext.Provider value={{ request_id }}> */}
                            {/* <AppManager /> */}
                            <Wrapper />
                            {/* </MetadataContext.Provider> */}
                        </FpjsProvider>
                    </MantineProvider>
                </PersistGate>
            ) : (
                <MantineProvider withGlobalStyles withNormalizeCSS theme={appTheme}>
                    <FpjsProvider
                        loadOptions={{
                            apiKey: 'vCagpcTgawWWhGylTeva',
                            endpoint: ['https://vx-fingerprint.idresponse.com', FingerprintJSPro.defaultEndpoint],
                            scriptUrlPattern: [
                                'https://vx-fingerprint.idresponse.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
                                FingerprintJSPro.defaultScriptUrlPattern,
                            ],
                        }}
                    >
                        <VxGlobalStyles />
                        <CustomFonts />
                        {/* <MetadataContext.Provider value={{ request_id }}> */}
                        {/* <AppManager /> */}
                        <Wrapper />
                        {/* </MetadataContext.Provider> */}
                    </FpjsProvider>
                </MantineProvider>
            )}
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
)
/* </React.StrictMode>, */

// const mediaQuery = window.matchMedia( '(max-device-width: 1224px)' )
// const isIframe = inIframe()
// if ( mediaQuery.matches && isIframe ) {
//     window.top.document.location.replace( `${window.top.document.location.origin}/${window.location.search}` )
// } else {
//     ReactDOM.render(
//         <React.StrictMode>
//             <BrowserRouter>
//                 <Provider store={ store }>
//                     {/* <PersistGate loading={ null } persistor={ persistor }> */}
//                     <PersistGate loading={ <AppLoading /> } persistor={ persistor }>
//                         <MantineProvider
//                             withGlobalStyles
//                             withNormalizeCSS
//                             theme={ appTheme }
//                         >
//                             <CustomFonts />

//                             <AppManager />

//                         </MantineProvider>
//                     </PersistGate>
//                 </Provider>
//             </BrowserRouter>
//         </React.StrictMode>,
//         document.getElementById( 'root' )
//     )
// }

// import reportWebVitals from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
