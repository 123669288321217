import {
    createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import { dataService } from '../../services/dataService'


const emailSenderState = {
    /** TODO: unused, preserving for now */
    // see email/Send.js and clientSettings.text.email for text settings
    icon: null,
    title: null,
    text: null,
    subtext: null,
    /** ************************** */

    isLoading: true,
    emailCount: 1,

    success: false,
    sendError: null,

    postError: null,
}


export const sendEmail = createAsyncThunk(
    'emailSender/sendEmail',
    async ( _payload, thunkAPI ) => {
        const sendEmailPayload = {
            settings: thunkAPI.getState().init.clientSettings,
            ...thunkAPI.getState().init.clientData,
        }
        const response = await dataService.sendEmail( sendEmailPayload )
        return response
    }
)


const emailSenderSlice = createSlice( {
    name: 'emailSender',
    initialState: emailSenderState,

    reducers: {
        sendingEmailAgain: ( draftState, action ) => {
            draftState.emailCount += action.payload
            draftState.isLoading = true
        },
        resetSuccess: draftState => {
            draftState.success = false
        },
    },

    extraReducers: builder => {
        builder
            .addCase( sendEmail.fulfilled, ( draftState, action ) => {
                const emailSentState = {
                    ...draftState,
                    isLoading: false,
                    text: action.payload.text,
                    subtext: action.payload.subtext,
                }
                if ( action.payload.result === 'success' ) {
                    return {
                        ...emailSentState,
                        title: 'Email Sent',
                        success: true,
                    }
                }
                return {
                    ...emailSentState,
                    title: 'Email Failed to Send',
                    sendError: true,
                }
            } )
            .addCase( sendEmail.rejected, draftState => {
                return {
                    ...draftState,
                    postError: true,
                }
            } )
    },
} )

export const {
    // incrementEmailCount,
    sendingEmailAgain,
    resetSuccess,
} = emailSenderSlice.actions

export default emailSenderSlice.reducer
