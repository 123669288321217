import {
    createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import { dataService } from '../../services/dataService'

import FrontDlSvg from '../../assets/img/dcams/dl_front.svg'
import BackDlSvg from '../../assets/img/dcams/dl_back.svg'
// import PassportSvg from '../../assets/img/dcams/passport.svg'
import PassportSvg from '../../assets/img/dcams/passportDouble.svg'
import SelfieSvg from '../../assets/img/dcams/selfie.svg'

// import { logJson } from '../../utilities/helpers'


const dcamsCaptureState = {
    // last: false,

    cameraGranted: false,

    imageUploadingFront: false,
    imageUploadingBack: false,
    imageUploadingPassport: false,
    imageUploadingSelfie: false,

    imageUploadedFront: false,
    imageUploadedBack: false,
    imageUploadedPassport: false,
    imageUploadedSelfie: false,

    s3Response: {
        Location: '',
        ETag: '',
        Bucket: '',
        Key: '',
        error: false,
    },
    s3Success: false,
    s3Rejected: false,
    s3Uploaded: false,

    previewFront: FrontDlSvg,
    previewBack: BackDlSvg,
    previewPassport: PassportSvg,
    previewSelfie: SelfieSvg,

    viewingScanStart: false,
    viewingResult: false,
}

export const postS3ManagedUpload = createAsyncThunk(
    's3Upload/postS3ManagedUpload',
    // eslint-disable-next-line no-unused-vars
    async ( params, thunkAPI ) => {
        const uploadResponse = await dataService.s3ManagedUpload( params )
        return uploadResponse
    }
)

const dcamsCaptureSlice = createSlice( {
    name: 'dcamsCapture',
    initialState: dcamsCaptureState,

    reducers: {
        setCameraGranted: ( draftState, action ) => {
            draftState.cameraGranted = action.payload
        },
        // setAlertBanner: ( draftState, action ) => {
        //     if ( action.payload?.message ) {
        //         draftState.showNotification = true
        //         draftState.bannerMessage = action.payload.message
        //     } else {
        //         draftState.showNotification = false
        //         draftState.bannerMessage = ''
        //     }
        // },
        viewingDcamsResult: ( draftState, action ) => {
            draftState.viewingResult = action.payload
        },

        // analog: S3UploadSnap configuration
        // TODO: switch statement?
        s3Uploading: ( draftState, action ) => {
            let uploadingState = { imageUploadingSelfie: true, }
            if ( action.payload === 'front' ) {
                uploadingState = { imageUploadingFront: true, }
            } else if ( action.payload === 'back' ) {
                uploadingState = { imageUploadingBack: true, }
            } else if ( action.payload === 'passport' ) {
                uploadingState = { imageUploadingPassport: true, }
            }
            return {
                ...draftState,
                ...uploadingState,

                s3Response: { ...draftState.s3Response, },
            }
        },

        // legacy analog: success _callback
        s3SuccessFeedback: ( draftState, action ) => {
            let successState = {
                previewSelfie: action.payload.preview,
                imageUploadingSelfie: false,
                imageUploadedSelfie: true,
            }
            if ( action.payload.subjectCaptured === 'front' ) {
                successState = {
                    previewFront: action.payload.preview,
                    imageUploadingFront: false,
                    imageUploadedFront: true,
                }
            } else if ( action.payload.subjectCaptured === 'back' ) {
                successState = {
                    previewBack: action.payload.preview,
                    imageUploadingBack: false,
                    imageUploadedBack: true,
                }
            } else if ( action.payload.subjectCaptured === 'passport' ) {
                successState = {
                    previewPassport: action.payload.preview,
                    imageUploadingPassport: false,
                    imageUploadedPassport: true,
                }
            }
            // logJson( 'setting success state', successState )
            return {
                ...draftState,
                ...successState,

                s3Response: { ...draftState.s3Response, },
            }
        },

        resetS3Success: draftState => {
            return {
                ...draftState,
                s3Response: { ...draftState.s3Response, },

                s3Success: false,
                s3Uploaded: false,

                imageUploadingFront: false,
                imageUploadingBack: false,
                imageUploadingPassport: false,

                // imageUploadedSelfie: false,
                // imageUploadedFront: false,
                // imageUploadedBack: false,
                // imageUploadedPassport: false,
            }
        },
        resetFeedback: ( draftState, action ) => {
            // the default state called by dispatchError(), a refactor of legacy displayError()
            // not clear why error handling used this catch-all
            // legacy was inconsistent or unfinished
            // TODO: refactor and test UploadIndex.dispatchError()
            // NOTE: this reducer fn is called for second upload attempt now,
            // in addition to error states (the 'ALL' case)
            let resetState = {}
            if ( draftState.lastCaptured === 'front' ) {
                resetState = {
                    previewFront: FrontDlSvg,
                    imageUploadingFront: false,
                    imageUploadedFront: false,
                }
            } else if ( draftState.lastCaptured === 'back' ) {
                resetState = {
                    previewBack: BackDlSvg,
                    imageUploadingBack: false,
                    imageUploadedBack: false,
                }
            } else if ( draftState.lastCaptured === 'passport' ) {
                resetState = {
                    previewPassport: SelfieSvg,
                    imageUploadingFront: false,
                    imageUploadedFront: false,
                }
            } else if ( draftState.lastCaptured === 'selfie' ) {
                resetState = {
                    previewSelfie: SelfieSvg,
                    imageUploadingSelfie: false,
                    imageUploadedSelfie: false,
                }
            }
            if ( action.payload === 'ALL' ) {
                resetState = {
                    previewFront: FrontDlSvg,
                    previewBack: BackDlSvg,
                    previewPassport: PassportSvg,
                    previewSelfie: SelfieSvg,
                    imageUploadingFront: false,
                    imageUploadingBack: false,
                    imageUploadingPassport: false,
                    imageUploadingSelfie: false,
                    imageUploadedFront: false,
                    imageUploadedBack: false,
                    imageUploadedPassport: false,
                    imageUploadedSelfie: false,

                }
            }
            return {
                ...draftState,
                ...resetState,
                s3Response: { ...draftState.s3Response, },
            }
        },

        switchCaptureToPassport: draftState => {
            return {
                ...draftState,
                previewFront: FrontDlSvg,
                previewBack: BackDlSvg,
                imageUploadedFront: false,
                imageUploadedBack: false,

                s3Response: { ...draftState.s3Response, },
            }
        },
        switchCaptureToDl: draftState => {
            return {
                ...draftState,
                previewPassport: PassportSvg,
                imageUploadedPassport: false,

                s3Response: { ...draftState.s3Response, },
            }
        },
        dcamsViewingScanStart: ( draftState, action ) => {
            draftState.viewingScanStart = action.payload
        },
    },

    extraReducers: builder => {
        builder
            .addCase( postS3ManagedUpload.fulfilled, ( draftState, action ) => {
                // if ( 'error' in action.payload ) {
                //     return {
                //         ...draftState,
                //         s3Response: {
                //             ...draftState.s3Response,
                //             error: action.payload.error,
                //         },
                //     }
                // }

                // logJson( 's3 fulfilled :: payload', action.payload )
                return {
                    ...draftState,
                    s3Response: {
                        ...draftState.s3Response,
                        ...action.payload.response,
                    },
                    s3Uploaded: true,
                    s3Success: action.payload.success,
                }
            } )
            .addCase( postS3ManagedUpload.rejected, draftState => {
                // action.error is a serialized error value
                return {
                    ...draftState,
                    s3Response: { ...draftState.s3Response, },
                    s3Rejected: true,
                    s3Success: false,
                }
            } )
    },
    /*
    extraReducers: builder => {
        builder
            // apply a "matcher function" to incoming actions
            .addMatcher( isActionWithNumberPayload, ( state, action ) => {} )
            // and provide a default case if no other handlers matched
            .addDefaultCase(( draftState, action ) => { return { ...draftState, } } )
    },
    */
} )

export const {
    setCameraGranted,
    // setAlertBanner,
    viewingDcamsResult,
    s3Uploading,
    s3SuccessFeedback,
    resetS3Success,
    resetFeedback,
    switchCaptureToPassport,
    switchCaptureToDl,
    dcamsViewingScanStart,
} = dcamsCaptureSlice.actions

export default dcamsCaptureSlice.reducer

