import { createSlice } from '@reduxjs/toolkit'


const navigationState = {
    goBack: false,
    viewingLoading: false,
    termsDialogueOpen: false,
    dialogueOpen: false,
    exitDialogueOpen: false,
}

const navigationSlice = createSlice( {
    name: 'navigation',
    initialState: navigationState,

    reducers: {
        navGoBack: ( draftState, action ) => {
            draftState.goBack = action.payload
        },
        navViewingLoading: ( draftState, action ) => {
            draftState.viewingLoading = action.payload
        },
        navSetTermsDialogueOpen: draftState => {
            draftState.termsDialogueOpen = true
        },
        navSetTermsDialogueClosed: draftState => {
            draftState.termsDialogueOpen = false
        },
        navSetDialogueOpen: draftState => {
            draftState.dialogueOpen = true
        },
        navSetDialogueClosed: draftState => {
            draftState.dialogueOpen = false
        },
        navSetExitDialogueOpen: draftState => {
            draftState.exitDialogueOpen = true
        },
        navSetExitDialogueClosed: draftState => {
            draftState.exitDialogueOpen = false
        },
    },
} )

export const {
    navGoBack,
    navViewingLoading,
    navSetTermsDialogueOpen,
    navSetTermsDialogueClosed,
    navSetDialogueOpen,
    navSetDialogueClosed,
    navSetExitDialogueOpen,
    navSetExitDialogueClosed,
} = navigationSlice.actions

export default navigationSlice.reducer
