import React from 'react'
import { useSelector } from 'react-redux'

import {
    useLocation,
    useHistory,
    // withRouter,
} from 'react-router-dom'
import {
    back, close, inIframe
} from '../../../../../utilities/helpers'

import '../../../../../css/dcamsGlobals.css'


function Nav() {
    // const { close_reason_active, } = useSelector( state => state.init.clientSettings )
    const { bypassDesktopIntro, } = useSelector( state => state.init.clientData )
    const { pathRoot, } = useSelector( state => state.journey )

    const location = useLocation()
    const history = useHistory()

    const isiFrame = inIframe()

    // const showBackButton = ( location.pathname === '/' || ( location.pathname === '/qr' && bypassDesktopIntro ) ? false : location.pathname !== '/close' )
    // const showBackButton = ( path === '/dcams/:company_id' || ( path === '/dcams/:company_id/qr' && bypassDesktopIntro ) ? false : path !== '/dcams/:company_id/close' )
    const showBackButton = ( location.pathname === `${pathRoot}` || ( location.pathname === `${pathRoot}/qr` && bypassDesktopIntro ) ? false : location.pathname !== `${pathRoot}/close` )

    return (
        <div>
            {showBackButton && (
                <div className="back-navigation">
                    <button
                        type="button"
                        className="text-muted navigation-button "
                        onClick={ () => back( history ) }
                    >
                        <i className="fal fa-arrow-left navigation-item backNav" />
                    </button>
                </div>
            )}
            {/* {isiFrame && location.pathname !== '/close' && ( */}
            {/* {isiFrame && path !== '/dcams/:company_id/close' && ( */}
            {isiFrame && location.pathname !== `${pathRoot}/close` && (
                <div className="close-navigation">
                    <button
                        type="button"
                        className="text-muted navigation-button"
                        onClick={ () => close() }
                    >
                        <i className="fal fa-times navigation-item text-muted" />
                    </button>
                </div>
            )}
        </div>
    )
}

export default Nav






