/* eslint-disable func-names */
let INITIAL_PAYLOAD

export const storeInitialPayload = ( function () {
    let injected = false
    return function ( ip ) {
        if ( !injected ) {
            INITIAL_PAYLOAD = ip
            injected = true
        }
    }
}())

export const getInitialPayload = () => INITIAL_PAYLOAD
