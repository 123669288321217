const appTheme = {
    components: {},
    headings: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        sizes: {
            h1: {
                fontSize: '26px',
                lineHeight: '39px',
            },
            h2: {
                fontSize: '1.25rem',
                lineHeight: '1.625rem',
            },
            h3: {
                fontSize: 22,
                lineHeight: 1.4,
            },
            h4: {
                fontSize: 18,
                lineHeight: 1.45,
            },
            h5: {
                fontSize: 16,
                lineHeight: 1.5,
            },
            h6: {
                fontSize: 14,
                lineHeight: 1.5,
            },
        },
    },
    // colors: {
    //     red: [ '#ffffff', '#f5ced5', '#f0b6c1', '#eb9dac', '#e68597', '#e06d82', '#db546d', '#d63c59', '#d12344', '#cc0b2f', ],
    // },
}

export default appTheme
