export const logger = msg => {
    if ( process.env.NODE_ENV !== 'production' ) {
        console.log( msg )
    }
}

export const logJson = ( description, json ) => {
    if ( process.env.NODE_ENV !== 'production' ) {
        // console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #05F0ED' )
        console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #bada55' )
    }
}

export const logJsonProduction = ( description, json ) => console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #05F0ED' )

export const sendLog = async ( desc, obj ) => {
    const log = {
        logDesc: desc,
        ...obj,
    }
    try {
        await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/vx_api/v1/log`,
            {
                method: 'POST',
                body: JSON.stringify( log ),
            },
        )
    } catch ( error ) {
        console.error( error )
    }
}

export const isObject = v => Object.prototype.toString.call( v ) === '[object Object]'

// Condition calls to this upon !!isObject
export const isObjEmpty = obj => Object.keys( obj ).length === 0

/** ************************ MESSAGE PASSING ************************* */
// TODO: this fn is named to reflect that we're triggering side-effects in reducer
// functions. It works, but how else can this be solved? Revisit.
//
// Cross-origin message passing is an interface that existing DCAMS clients expect.
//
// This also duplicates useMessagePasser() in hooks/hooks.js. That hook is used
// where it is needed in components, whereas this helper is used in reducers.
//
// These should be refactored to a common interface for VX ver. 2
export const passSideEffectMessage = message => {
    if (
        isObject( message )
            && !message?.callbackEvent
            && window.veratadMessageHandler
            && window.usePostMessage
    ) {
        const mh = {
            ReactNativeWebView: window.ReactNativeWebView,
            window_top: window.top,
            // mockPostMessage: window.top.mockPostMessage,
        }

        // TODO: refactor
        if ( window.veratadMessageHandler === 'ReactNativeWebView' ) {
            mh[ window.veratadMessageHandler ].postMessage( JSON.stringify( message ))
        } else {
            mh[ window.veratadMessageHandler ].postMessage( JSON.stringify( message ), '*' )
        }
    }

    if (
        isObject( message )
            && message?.callbackEvent
            && window.veratadMessageHandler
            && window.useEventCallbacks
    ) {
        const { callbackEvent, } = message
        logger( '[PASS SIDE-EFFECT MESSAGE CALLBACK EVENT]', JSON.stringify( callbackEvent, null, 4 ))
        window.top.postMessage( callbackEvent, '*' )
    }

    // Added for troubleshooting Wawa
    // if ( window.veratadMessageHandler === 'ReactNativeWebView' ) {
    //     window.ReactNativeWebView.postMessage( JSON.stringify( message ))
    // }
    // if ( window.veratadMessageHandler === 'window' ) {
    //     window.top.postMessage( JSON.stringify( message ))
    // }
}
/** ********************** END MESSAGE PASSING *********************** */


/** ********************** MANTINE POLYFILL *********************** */
// node_modules/@mantine/form/esm/resolvers/yup-resolver
export function yupResolver( schema ) {
    const _schema = schema
    return values => {
        try {
            _schema.validateSync( values, { abortEarly: false, } )
            return {}
        } catch ( _yupError ) {
            const yupError = _yupError
            const results = {}
            yupError.inner.forEach( error => {
                results[ error.path.replaceAll( '\\[', '.' ).replaceAll( '\\]', '' ) ] = error.message
            } )
            return results
        }
    }
}
/** ********************** END MANTINE POLYFILL *********************** */


/** ********************** KBA *********************** */
export const getParent = path => path.substring( 0, path.lastIndexOf( '/' ))
/** ********************** End KBA *********************** */


/** ********************** DCAMS *********************** */
export const inIframe = () => {
    try {
        return window.self !== window.top
    } catch ( e ) {
        return true
    }
}

export const nextPath = ( history, path ) => {
    // logger( 'PUSHING NEXT PATH', path )
    history.push( path )
}

export const back = history => {
    history.goBack()
}

export const close = () => {
    window.top.postMessage( 'CLOSE', '*' )
}

export const onQr = () => {
    window.top.postMessage( 'QR', '*' )
}

export const capitalize = string => string.charAt( 0 ).toUpperCase() + string.slice( 1 )

export const getSubjectSubmission = ( matchParams, international, currentTarget = null ) => {
    const isUserUpload = Boolean( currentTarget )
    if ( international ) {
        if ( matchParams.type === 'dl' ) { return 'DL_FRONT' }
        if ( matchParams.type === 'passport' ) { return 'PASSPORT' }
        if ( isUserUpload ) { return 'SELFIE' }
    }
    if ( isUserUpload ) {
        if ( matchParams.type === 'dl' ) {
            return currentTarget === 'front' ? 'DL_FRONT' : 'PDF417_BARCODE'
        }
        if ( matchParams.type === 'passport' ) { return 'PASSPORT' }
        return 'SELFIE'
    }
    if ( matchParams.type === 'dl' ) {
        return matchParams.side === 'front' ? 'DL_FRONT' : 'PDF417_BARCODE'
    }
    return 'PASSPORT'
}

export const getSubjectCaptured = (
    captureResult,
    international,
    currentScan
) => {
    if ( international ) {
        if ( currentScan === 'front' ) {
            return 'front'
        } if ( currentScan === 'back' ) {
            return 'back'
        } if ( currentScan === 'passport' ) {
            return 'passport'
        }
        return 'selfie'
    } if ( captureResult.request === 'DL_FRONT' ) {
        return 'front'
    } if ( captureResult.request === 'PDF417_BARCODE' ) {
        return 'back'
    } if ( captureResult.request === 'PASSPORT' ) {
        return 'passport'
    }
    return 'selfie'
}

// eslint-disable-next-line consistent-return
export const checkCameraGranted = () => {
    // TODO: implement reliable device-agnostic camera access detection (see also ScanIndex.js)
    // scope to front camera vs rear camera (selfie)
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo
    if ( navigator.permissions && navigator.permissions.query ) {
        // logger( 'ENUMERATE NAVIGATOR PERMISSIONS' )
        navigator.permissions.query( { name: 'camera', } )
            .then( perms => perms.state === 'granted' )
    } else if ( navigator.mediaDevices ) {
        navigator.mediaDevices.enumerateDevices()
            .then( devices => {
                devices.forEach( device => {
                    // logger( 'ENUMERATE NAVIGATOR MEDIADEVICES' )
                    // logger( 'ENTRY', device )
                    // "videoinput" label should be safe here
                    // are "front camera" and "rear camera" device.kind types specific to iPhone?
                    if ( device.label === 'videoinput' ) {
                        return true
                    }
                    return false
                } )
            } )
    } else {
        // logger( 'ENUMERATE ELSE' )
        return true
    }
}

const dataURItoBlob = dataurl => {
    /* eslint-disable prefer-const */
    let arr = dataurl.split( ',' ),
        mime = arr[ 0 ].match( /:(.*?);/ )[ 1 ],
        bstr = atob( arr[ 1 ] ),
        n = bstr.length,
        u8arr = new Uint8Array( n )
    /* eslint-enable prefer-const */
    // eslint-disable-next-line no-plusplus
    while ( n-- ) {
        u8arr[ n ] = bstr.charCodeAt( n )
    }
    return new Blob( [ u8arr, ], { type: mime, } )
}

export const getS3Params = ( subjectCaptured, dataURI, request_id ) => {
    const folderURI = `${encodeURIComponent( process.env.REACT_APP_AWS_BUCKET_FOLDER )}/`
    const subject = subjectCaptured === 'passport' ? 'front' : subjectCaptured
    const typedSubject = `dl_${subject}`
    const fileName = `${request_id}_${typedSubject}.jpeg`
    const blob = dataURItoBlob( dataURI )

    return {
        Key: folderURI + fileName,
        Body: blob,
    }
}

export const updateStyles = styleSettings => {
    // logger( `%c [STYLE UPDATE SETTINGS] ${JSON.stringify( settings, null, 4 )}`, 'background: #3D4047; color: #05F0ED' );
    const root = document.documentElement
    if ( styleSettings ) {
        // primary button
        root?.style.setProperty( '--veratad-primary-bg', styleSettings.primary_back )
        root?.style.setProperty( '--veratad-primary-color', styleSettings.primary_text )
        // secondary button
        root?.style.setProperty( '--veratad-secondary-bg', styleSettings.secondary_back )
        root?.style.setProperty( '--veratad-secondary-color', styleSettings.secondary_text )
        // icons
        root?.style.setProperty( '--veratad-icon', styleSettings.icons )
    }
}


/** DEPRECATE? */
// export const close = ( history, settings ) => {
//     window.top.postMessage( 'CLOSE', '*' )

//     /*
//     const path = window.location.pathname;
//     const lastItem = path.substring(path.lastIndexOf('/') + 1)

//     if(lastItem !== "dcams_verificationprocessing"){
//         const close_reason_active = settings.close_reason_active;
//         if(close_reason_active){
//             history.push(`${process.env.PUBLIC_URL}/close`);
//         }else{
//             window.top.postMessage('CLOSE', '*');
//         }
//     }else{
//         window.top.postMessage('CLOSE', '*');
//     }
//     */
// }
/** ********************** End DCAMS *********************** */
