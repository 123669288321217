import {
    createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import { dataService } from '../../services/dataService'

import {
    setJourneyStatus
} from '../journey.slice'

import { bouncerMessages } from '../messages'
import { bouncerMatchFailure } from '../actions'

import {
    passSideEffectMessage,
} from '../../utilities/helpers'


const dcamsVerifyState = {
    isProcessing: true,
    error: false,
    isTryAgain: false, // retained from legacy for possible backend config

    status: null,
    enhanced_complete: '',
    enhanced_next_document: false,
    request_id: '',
    title: '',
    subtitle: '',
    icon: null,
    detail: '',
    action: '',

    header: null,
    sub: null,
    enhanced_template: null,
    check_time_selfie: 0,

    attempts: 0,
}

export const postVerifyCaptureScan = createAsyncThunk(
    'dcamsVerifyCapture/postVerifyCaptureScan',
    async ( _payload, thunkAPI ) => {
        // ---
        // see backend api->handleResponseDcamsPlus()
        const { attempts, } = thunkAPI.getState().dcamsVerifyCapture
        const _attempts = attempts + 1 // closure over value previous to thunk call

        const verifyPayload = {
            settings: thunkAPI.getState().init.clientSettings,
            ...thunkAPI.getState().init.clientData,
            attempts: _attempts,
        }

        // logJson( 'verify payload', verifyPayload )

        const response = await dataService.dcamsVerifyCaptureScan( verifyPayload )
        return response // response is currently not used. Instead the frontend subsequently polls with a status check.
    }
)

/** *************************************************************************************** */
/*
// the legacy analog of this thunk is verifyPostSelfie(); it was unused in legacy
// refactored here for future use (note the copying of the "template" key in legacy)
export const postVerifyCaptureSelfie = createAsyncThunk(
    'dcamsVerifyCapture/postVerifyCaptureSelfie',
    // eslint-disable-next-line no-unused-vars
    async ( _payload, thunkAPI ) => {
        // const verifyPayload = thunkAPI.getState().init.clientData
        const verifyPayload = {
            settings: thunkAPI.getState().init.clientSettings,
            ...thunkAPI.getState().init.clientData,
        }
        const response = await dataService.dcamsVerifyCaptureSelfie( verifyPayload )
        return response // response is currently not used. Instead the frontend subsequently polls with a status check.
    }
)
*/
/** *************************************************************************************** */

export const checkVerifyStatus = createAsyncThunk(
    'dcamsVerifyCapture/checkVerifyStatus',
    // eslint-disable-next-line no-unused-vars
    async ( _payload, thunkAPI ) => {
        const { serviceName, } = thunkAPI.getState().journey
        const remergedPayload = {
            settings: thunkAPI.getState().init.clientSettings,
            ...thunkAPI.getState().init.clientData,
        }
        const {
            statusPayload,
            attempts,
        } = _payload
        const statusCheckPayload = {
            // ..._payload,
            ...statusPayload,
            attempts,
            service_name: serviceName,
            data: remergedPayload,
        }

        // logger( '%c [STATUS CHECK]', 'background: #3D4047; color: #05F0ED' )
        // logJson( 'status check payload', statusCheckPayload )

        const statusResponse = await dataService.verificationStatusCheck( statusCheckPayload )

        // logJson( 'dcams statusResponse', statusResponse )

        if ( Object.keys( bouncerMessages ).includes( statusResponse?.detail )) {
            thunkAPI.dispatch( bouncerMatchFailure( { detail: statusResponse.detail, } ))
        }

        let status
        const message = {
            status: '',
            component: 'DCAMS',
            error: '',
            event: '',
        }
        // eslint-disable-next-line no-promise-executor-return
        // const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
        if ( statusResponse.status !== 'NEW' ) {
            if ( statusResponse.status !== 'PASS' ) {
                const { journey, } = thunkAPI.getState().journey.config
                // is this the last stop && is this 2nd attempt?
                if ( journey[ journey.length - 1 ].includes( 'dcams' ) && attempts > 1 ) {
                    status = 'FAIL' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
                    thunkAPI.dispatch( setJourneyStatus( status ))
                    message.status = status
                    passSideEffectMessage( message )
                    // await sleep( 1000 )
                    // See TODO at journey.slice fireJourneyCallback definition
                    // thunkAPI.dispatch( fireJourneyCallback( { status: 'FAIL', } ))
                } else {
                    status = 'PENDING' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
                    thunkAPI.dispatch( setJourneyStatus( status ))
                    message.status = status
                    passSideEffectMessage( message )
                    // await sleep( 1000 )
                    // See TODO at journey.slice fireJourneyCallback definition
                    // thunkAPI.dispatch( fireJourneyCallback( { status: 'PENDING', } ))
                }
            } else {
                status = 'PASS' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
                thunkAPI.dispatch( setJourneyStatus( status ))
                message.status = status
                passSideEffectMessage( message )
                // await sleep( 1000 )
                // See TODO at journey.slice fireJourneyCallback definition
                // thunkAPI.dispatch( fireJourneyCallback( { status: 'PASS', } ))
            }
        }

        return statusResponse
    }
)


const dcamsVerifySlice = createSlice( {
    name: 'dcamsVerifyCapture',
    initialState: dcamsVerifyState,

    reducers: {
        incrementDcamsAttempts: draftState => {
            draftState.attempts += 1
        },
        resetVerifyResultAction: draftState => {
            draftState.isProcessing = true
            draftState.status = null
            draftState.title = ''
            draftState.subtitle = ''
            draftState.icon = ''
            draftState.detail = ''
            draftState.action = ''
            draftState.header = null
            draftState.sub = null
        },
    },

    extraReducers: builder => {
        builder
            .addCase( checkVerifyStatus.fulfilled, ( draftState, action ) => {
                if ( action.payload.status === 'NEW' ) {
                    return {
                        ...draftState,
                        isProcessing: true,
                        action: null,
                    }
                }

                // logJson( 'dcams status', action.payload )
                console.log( `%c [DCAMS STATUS]`, 'background: #222; color: #7EDEE0' )
                console.log( `${JSON.stringify( action.payload, null, 4 )}`, 'background: #3D4047; color: #bada55' )
                return {
                    ...draftState,
                    isProcessing: false,

                    status: action.payload.status,
                    isTryAgain: action.payload?.isTryAgain || false,

                    enhanced_complete: action.payload?.enhanced_complete || '',
                    enhanced_next_document: action.payload?.enhanced_next_document || false,
                    request_id: action.payload?.request_id || '',
                    title: action.payload?.title || '',
                    subtitle: action.payload?.subtitle || '',
                    icon: action.payload?.icon || null,
                    detail: action.payload?.detail || '',
                    action: action.payload?.action || '',

                    header: action.payload?.title || '',
                    sub: action.payload?.subtitle || '',
                    // enhanced_template: action.payload.enhanced_next_document,
                }
            } )
            .addCase( checkVerifyStatus.rejected, draftState => {
                // action.error is a serialized error value
                return {
                    ...draftState,
                    error: true,
                }
            } )
    },
} )

export const {
    incrementDcamsAttempts,
    resetVerifyResultAction,
} = dcamsVerifySlice.actions

export default dcamsVerifySlice.reducer
