import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
} from 'react-router-dom'
import {
    useSelector
} from 'react-redux'

import {
    MantineProvider,
    Box,
    Container,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import loadable from '@loadable/component'

import {
    useJourneyUpdate, useGetSource
} from '../../hooks/journeyHooks'
import { useDeviceReporter } from '../../hooks/hooks'
import { useTheme } from '../../nativeThemeMapper'

const Header = loadable(() => import( /* webpackPreload: true */ `../components.generic/header` ))
const SsnVerify = loadable(( { source, } ) => import( /* webpackPreload: true */ `./${source}/screens/verify` ))
const SsnResult = loadable(( { source, } ) => import( /* webpackPreload: true */ `./${source}/screens/result` ))
const Dialogue = loadable(() => import( /* webpackPrefetch: true */ `../components.generic/dialogue` ))


export default function SsnManager() {
    const { journeyId, } = useSelector( state => state.journey )
    const location = useLocation()
    const { path, } = useRouteMatch()
    const { getNativeMappedTheme, } = useTheme()
    const { scroll, } = useSelector( state => state.ui )
    // const { logoName, } = useSelector( state => state.journey.config )

    // If this is a handoff, update journey stop and path root
    useJourneyUpdate( location )
    // Map component sources for the journey (custom vs. default)
    const { getSource, } = useGetSource()

    useDeviceReporter()

    const maxDeviceWidth1224 = useMediaQuery( '(max-device-width: 1224px)' )

    const gridTemplateRows = maxDeviceWidth1224
        ? '[manager-spacer] 70vh 2px [manager-foot] 1fr'
        : '[manager-spacer] 61vh 2px [manager-foot] 1fr'

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={ getNativeMappedTheme( journeyId ) } // TODO: Raise to App.Manager and make journey themes global
        >
            <Box
                className="manager-box"
                sx={ {
                    margin: 0,
                    padding: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: scroll ? 'scroll' : null,
                } }
            >

                <Header />

                <Container
                    fluid
                    // px={ isIframe ? 80 : 20 }
                    mx={ 0 }
                    px={ maxDeviceWidth1224 ? 20 : 80 }
                    // sx={ { height: '100%', } }
                >
                    <Box
                        className="manager-grid"
                        sx={ {
                            display: 'grid',
                            // gridTemplateRows: '[manager-spacer] 70vh 2px [manager-foot] 1fr',
                            gridTemplateRows,
                            gridTemplateColumns: '[manager-single-col] 100%',
                            gap: '0px 0px',
                        } }
                    >
                        <Switch>
                            <Route path={ `${path}/` } exact>
                                <SsnVerify source={ getSource( 'SsnVerify' ) } />
                                {/* <SsnResult source={ getSource( 'SsnResult' ) } /> */}
                            </Route>
                            <Route path={ `${path}/result` } exact>
                                <SsnResult source={ getSource( 'SsnResult' ) } />
                            </Route>
                        </Switch>
                    </Box>

                    <Dialogue exit />

                </Container>
            </Box>
        </MantineProvider>
    )
}
