import { createSlice } from '@reduxjs/toolkit'


const uiState = {
    inIframeContext: window.self !== window.top,
    modalHeightUpdated: false,
    headerHeight: 62,
    scroll: false,
    vertBreakPoint: 710, // veratad-modal-box kba height === 760. XS window.innerHeight === 637.
}


const uiSlice = createSlice( {
    name: 'ui',
    initialState: uiState,

    reducers: {
        // setIframeContext: ( draftState, action ) => {
        //     draftState.inIframeContext = action.payload
        // },
        setModalHeightUpdated: ( draftState, action ) => {
            draftState.modalHeightUpdated = action.payload
        },
        updateHeaderHeight: ( draftState, action ) => {
            draftState.headerHeight = action.payload
        },
        setScrollable: ( draftState, action ) => {
            draftState.scroll = action.payload
        },
    },
} )

export const {
    // setIframeContext,
    setModalHeightUpdated,
    updateHeaderHeight,
    setScrollable,
} = uiSlice.actions

export default uiSlice.reducer
