import { useSelector, } from 'react-redux'
import {
    browserName, browserVersion
} from 'react-device-detect'

export const useTheme = () => {
    const { journeyStop, } = useSelector( state => state.journey )
    const {
        // primaryColor = '',
        headingsFontFamily = '',
        headingsFontWeight = '',
        // h1FontFamily = '',
        h1FontSize = '',
        // h1FontWeight = '',
        h1LineHeight = '',
        // h1FontColor = '',
        // h2FontFamily = '',
        h2FontSize = '',
        // h2FontWeight = '',
        h2LineHeight = '',
        // h2FontColor = '',
        textNormalFontFamily = '',
        textNormalFontSize = '',
        textNormalFontWeight = '',
        textNormalLineHeight = '',
        textNormalColor = '',
        // buttonBackgroundColor = '',
        alertTextColor = '',
        alertBackgroundColor = '',
        inputTextFontFamily = '',
        inputTextFontSize = '',
        inputTextFontWeight = '',
        inputTextLineHeight = '',
        inputTextColor = '',
        inputLabelTextFontSize = '',
        inputLabelTextFontWeight = '',
        inputLabelTextLineHeight = '',
        inputLabelTextColor = '',
        inputRadius = '',
        inputHeight = '',
        radioButtonUncheckedBorderColor = '',
        radioButtonCheckedBackgroundImage = '',
        radioButtonCheckedBorderColor = '',

        // NOTE: Since we're using fallbacks for undefined values, any defined
        // values in the journey's theme object **must not be set equal to
        // null** as it is a defined value.
        //
        // TODO: parameterize?
        // state.journey.config.services[<derived service>].theme
        // With this approach, when a new manager loads we select that journey stop's
        // theme. The first VX client, Wawa, uses the same theme for both of its
        // services. This will be the common case, so a default theme in such
        // cases may be a better approach.
        //
        // Counterpoint: can we avoid scoping themes to journey stops? Currently
        // they are not and it would be the simpler design to remain that way.
        // { <journey_id>: { kba: {components: ...} } }
        //
        // TODO: Raise the theme insertion to App.Manager and make journey themes global?
    // } = useSelector( state => state.journey.config.services.kba.theme )
    } = useSelector( state => state.journey.config.services[ journeyStop.split( '_' )[ 0 ] ].theme )

    // this function is currently the only return value
    // it is used to inject the theme prop at the <MantineProvider> that wraps each journey stop
    const getNativeMappedTheme = name => themes[ name ]

    const flexGapPolyfill = ( browserName.toLowerCase().includes( 'safari' ) && parseInt( browserVersion, 10 ) <= 14 ) ? {
        Group: {
            styles: ( theme, { spacing, } ) => {
                return {
                    root: {
                        gap: 0,
                        marginRight: -theme.fn.size( {
                            size: spacing,
                            sizes: theme.spacing,
                        } ),
                        // marginBottom: -theme.fn.size( {
                        //     size: spacing,
                        //     sizes: theme.spacing,
                        // } ),
                        // '> *': {
                        '> *:not(:last-child)': {
                            marginRight: theme.fn.size( {
                                size: spacing,
                                sizes: theme.spacing,
                            } ),
                            // marginBottom: `${theme.fn.size( {
                            //     size: spacing,
                            //     sizes: theme.spacing,
                            // } )} !important`,
                        },
                    },
                }
            },
        },
        Stack: {
            styles: ( theme, { spacing, } ) => {
                return {
                    root: {
                        gap: 0,
                        marginBottom: -theme.fn.size( {
                            size: spacing,
                            sizes: theme.spacing,
                        } ),
                        '> *:not(:last-child)': {
                            marginBottom: `${theme.fn.size( {
                                size: spacing,
                                sizes: theme.spacing,
                            } )} !important`,
                        },
                    },
                }
            },
        },
    } : {}

    const rjBrands = {
        components: {
            ...flexGapPolyfill,
            Alert: {
                styles: {
                    root: {
                        backgroundColor: alertBackgroundColor,
                    },
                    message: {
                        fontFamily: textNormalFontFamily,
                        fontSize: textNormalFontSize,
                        fontWeight: textNormalFontWeight,
                        lineHeight: textNormalLineHeight,
                        color: alertTextColor,
                        textAlign: 'start',
                    },
                    icon: {
                        width: '24px',
                        margin: '4px 0px',
                        color: alertTextColor,
                        // '&:svg': {
                        //     color: '#007298',
                        // },
                    },
                },
            },
            Input: {
                styles: {
                    // wrapper: {
                    //     borderBottom: '1px solid black',
                    //     '*::placeholder': {
                    //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                    //     },
                    // },
                    error: {
                        fontFamily: inputTextFontFamily,
                        fontSize: '13px',
                        fontWeight: inputTextFontWeight,
                        lineHeight: '8px',
                        color: '#820a21',
                    },
                    invalid: {
                        color: '#820a21',
                    },
                    label: {
                        fontFamily: inputTextFontFamily,
                        fontSize: inputLabelTextFontSize,
                        fontWeight: inputLabelTextFontWeight,
                        lineHeight: inputLabelTextLineHeight,
                        color: inputLabelTextColor,
                    },
                    input: {
                        fontFamily: inputTextFontFamily,
                        fontSize: inputTextFontSize,
                        fontWeight: inputTextFontWeight,
                        lineHeight: inputTextLineHeight,
                        color: inputTextColor,
                        borderRadius: inputRadius,
                        height: inputHeight,
                    },
                    description: {
                        color: inputTextColor,
                    },
                },
            },
            InputWrapper: {
                styles: {
                    label: {
                        fontFamily: inputTextFontFamily,
                        fontSize: inputLabelTextFontSize,
                        fontWeight: inputLabelTextFontWeight,
                        lineHeight: inputLabelTextLineHeight,
                        color: inputLabelTextColor,
                    },
                },
            },
        },
        headings: {
            fontFamily: headingsFontFamily,
            fontWeight: headingsFontWeight,
            sizes: {
                h1: {
                    fontSize: h1FontSize,
                    lineHeight: h1LineHeight,
                },
                h2: {
                    fontSize: h2FontSize,
                    lineHeight: h2LineHeight,
                },
                h3: {
                    fontSize: 22,
                    lineHeight: 1.4,
                },
                h4: {
                    fontSize: 18,
                    lineHeight: 1.45,
                },
                h5: {
                    fontSize: 16,
                    lineHeight: 1.5,
                },
                h6: {
                    fontSize: 14,
                    lineHeight: 1.5,
                },
            },
        },
        // Non-input focus rings are disabled globally:
        // See the function VxGlobalStyles() in index.js.
        // The following approach doesn't work in iOS Safari but is worth
        // noting.
        // https://mantine.dev/theming/theme-object/
        // focusRingStyles: {
        //     // outline: 'none',

        //     styles: theme => ( {
        //         // outlineOffset: 2,
        //         // outline: `2px solid ${
        //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
        //         // }`,
        //         outline: '2px solid transparent',
        //     } ),
        // },
    }

    const themes = {
        /** ****************** RJ full journey */
        '21-073e63cd-6927-4ad4-a3fa-b041e3c8090a': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Wawa full journey */
        '1985-f5acc615-a06d-4d8a-9efe-b16f475c766e': {
            components: {
                ...flexGapPolyfill,
                Radio: {
                    styles: {
                        radio: {
                            cursor: 'pointer',
                        },
                        label: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: textNormalColor,
                            cursor: 'pointer',
                        },
                        inner: {
                            input: {
                                borderColor: radioButtonUncheckedBorderColor,
                            },
                            'input:checked': {
                                backgroundImage: radioButtonCheckedBackgroundImage,
                                borderColor: radioButtonCheckedBorderColor,
                            },
                        },
                        icon: {
                            color: 'transparent',
                        },
                    },
                },
                Footer: {
                    styles: {
                        root: {
                            borderTop: '0px solid transparent',
                        },
                    },
                },
                Alert: {
                    styles: {
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                            marginLeft: '.75rem',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        wrapper: {
                            borderBottom: '1px solid black',
                            '*::placeholder': {
                                color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                            },
                        },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputTextColor,
                        },
                    },
                },

                // TextInput: {
                //     styles: {
                //         wrapper: {
                //             borderBottom: '1px solid black',
                //             '*::placeholder': {
                //                 color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                //             },
                //         },
                //         error: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: '13px',
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: '8px',
                //             color: '#820a21',
                //         },
                //         invalid: {
                //             color: '#820a21',
                //         },
                //         label: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: inputLabelTextFontSize,
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: inputLabelTextLineHeight,
                //             color: inputTextColor,
                //         },
                //         input: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: inputTextFontSize,
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: inputTextLineHeight,
                //             color: inputTextColor,
                //         },
                //         description: {
                //             color: inputTextColor,
                //         },
                //     },
                // },
                // NumberInput: {
                //     styles: {
                //         wrapper: {
                //             borderBottom: '1px solid black',
                //             '*::placeholder': {
                //                 color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                //             },
                //         },
                //         error: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: '13px',
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: '8px',
                //             color: '#820a21',
                //         },
                //         invalid: {
                //             color: '#820a21',
                //         },
                //         label: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: inputLabelTextFontSize,
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: inputLabelTextLineHeight,
                //             color: inputTextColor,
                //         },
                //         input: {
                //             fontFamily: inputTextFontFamily,
                //             fontSize: inputTextFontSize,
                //             fontWeight: inputTextFontWeight,
                //             lineHeight: inputTextLineHeight,
                //             color: inputTextColor,
                //         },
                //         description: {
                //             color: inputTextColor,
                //         },
                //     },
                // },
                DatePicker: {
                    styles: {
                        wrapper: {
                            '*::placeholder': {
                                color: `${inputTextColor} !important`, // '#000', // '#000', // inputTextColor,
                            },
                        },
                        input: {
                            paddingLeft: '22px !important',
                        },
                        'input:focus': {
                            borderBottom: '1px solid black',
                        },
                        root: {
                            // borderBottom: '1px solid black',
                            paddingLeft: '0px',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputTextColor,
                        },
                        icon: {
                            width: '18px',
                        },
                        calendarHeaderLevel: {
                            fontFamily: textNormalFontFamily,
                        },
                        yearPickerControl: {
                            fontFamily: textNormalFontFamily,
                        },
                        monthPickerControl: {
                            fontFamily: textNormalFontFamily,
                        },
                        day: {
                            fontFamily: textNormalFontFamily,
                            // 'button[data-selected="true"]': {
                            //     // 'button[data-autofocus="true"][data-selected="true"]': {
                            //     backgroundColor: buttonBackgroundColor || primaryColor,
                            // },
                        },
                    },
                },
                Skeleton: {
                    styles: {
                        visible: {
                            '::after': {
                                background: '#D0D0D0',
                                animationDuration: '1200ms',
                            },
                        },
                    },
                },

                // Component Examples, CSS
                // Title: {
                //     styles: {
                //         root: {
                //             '&:is(h1)': { color: 'red' },
                //             '&:is(h3)': { color: 'blue' },
                //         },
                //     },
                // },
                // TypographyStylesProvider: {
                //     styles: {
                //         root: {
                //             '& h1': { color: 'red' },
                //             '& h3': { color: 'blue' },
                //         },
                //     },
                // },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // colors: {
            //     red: [ '#ffffff', '#f5ced5', '#f0b6c1', '#eb9dac', '#e68597', '#e06d82', '#db546d', '#d63c59', '#d12344', '#cc0b2f', ],
            // },
        },

        /** ****************** NRS full journey */
        '1990-97a5a0af-8384-46df-8bd3-d960a586e83f': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** NRS full journey 2 */
        '1990-778f519d-b0f3-4429-a837-81884c586eee': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) React Native mobile full journey */
        '21-4ea8651d-973a-4264-b692-751a97b98049': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) Web full journey */
        '21-93a7cbed-1ac4-4353-b33b-6497d5749599': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_reactnative_pre-production@rjrt.com */
        /** ****************** site_name 01c740cb-e216-4cd2-8167-7e6bef7a399d */
        '21-eb6f5e2d-11b1-4ac4-8c68-ed5ad667bbb2': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_web_pre-production@rjrt.com */
        /** ****************** site_name 96e69daa-9770-4334-bd37-12e7c087713e */
        '21-b7f1c68a-5817-45ba-a36c-466b1bca013e': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_reactnative_production@rjrt.com */
        /** ****************** site_name 1c5757d4-46e9-42a6-be06-e2828c6af560 */
        '21-c10bc86b-2e23-4d3e-9d81-5dff6b7b7dc5': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_web_production@rjrt.com */
        /** ****************** site_name 00b6fe60-e9b8-4282-bad6-99be65604af6 */
        '21-f517c5b2-bb80-4403-bf0e-e34c149b11d9': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** Fuel Cycle full journey */
        '1995-f7c838f8-88b8-4295-983f-dd77e7423609': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** GAVS_VX_MASTER_TESTING@pmi.com PMI full journey */
        '1956-468d85c8-4886-4496-ae96-3c82320f25b5': {
            components: {
                ...flexGapPolyfill,
                Alert: {
                    styles: {
                        root: {
                            backgroundColor: alertBackgroundColor,
                        },
                        message: {
                            fontFamily: textNormalFontFamily,
                            fontSize: textNormalFontSize,
                            fontWeight: textNormalFontWeight,
                            lineHeight: textNormalLineHeight,
                            color: alertTextColor,
                            textAlign: 'start',
                        },
                        icon: {
                            width: '24px',
                            margin: '4px 0px',
                            color: alertTextColor,
                            // '&:svg': {
                            //     color: '#007298',
                            // },
                        },
                    },
                },
                Input: {
                    styles: {
                        // wrapper: {
                        //     borderBottom: '1px solid black',
                        //     '*::placeholder': {
                        //         color: `${inputTextColor} !important`, // 'black !important', // '#000', // '#000', // inputTextColor,
                        //     },
                        // },
                        error: {
                            fontFamily: inputTextFontFamily,
                            fontSize: '13px',
                            fontWeight: inputTextFontWeight,
                            lineHeight: '8px',
                            color: '#820a21',
                        },
                        invalid: {
                            color: '#820a21',
                        },
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                        input: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputTextFontSize,
                            fontWeight: inputTextFontWeight,
                            lineHeight: inputTextLineHeight,
                            color: inputTextColor,
                            borderRadius: inputRadius,
                            height: inputHeight,
                        },
                        description: {
                            color: inputTextColor,
                        },
                    },
                },
                InputWrapper: {
                    styles: {
                        label: {
                            fontFamily: inputTextFontFamily,
                            fontSize: inputLabelTextFontSize,
                            fontWeight: inputLabelTextFontWeight,
                            lineHeight: inputLabelTextLineHeight,
                            color: inputLabelTextColor,
                        },
                    },
                },
            },
            headings: {
                fontFamily: headingsFontFamily,
                fontWeight: headingsFontWeight,
                sizes: {
                    h1: {
                        fontSize: h1FontSize,
                        lineHeight: h1LineHeight,
                    },
                    h2: {
                        fontSize: h2FontSize,
                        lineHeight: h2LineHeight,
                    },
                    h3: {
                        fontSize: 22,
                        lineHeight: 1.4,
                    },
                    h4: {
                        fontSize: 18,
                        lineHeight: 1.45,
                    },
                    h5: {
                        fontSize: 16,
                        lineHeight: 1.5,
                    },
                    h6: {
                        fontSize: 14,
                        lineHeight: 1.5,
                    },
                },
            },
            // Non-input focus rings are disabled globally:
            // See the function VxGlobalStyles() in index.js.
            // The following approach doesn't work in iOS Safari but is worth
            // noting.
            // https://mantine.dev/theming/theme-object/
            // focusRingStyles: {
            //     // outline: 'none',

            //     styles: theme => ( {
            //         // outlineOffset: 2,
            //         // outline: `2px solid ${
            //         //     theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5]
            //         // }`,
            //         outline: '2px solid transparent',
            //     } ),
            // },
        },

        /** ****************** RJ brands 10-31-2023 */
        /*
        ------- styles provided -------
        vx_camel_dev@rjrt.com
        vx_camel_qa@rjrt.com
        vx_camel_uat@rjrt.com
        vx_camel_production@rjrt.com
        camel_snus_vx_prod@rjrt.com
        camel_snus_vx_qa@rjrt.com

        cougar_vx_prod@rjrt.com
        cougar_vx_qa@rjrt.com

        vx_grizzly_preproduction@rjrt.com
        vx_grizzly_production@rjrt.com

        kodiak_vx_prod@rjrt.com
        kodiak_vx_qa@rjrt.com

        lucky_strike_vx_prod@rjrt.com
        lucky_strike_vx_qa@rjrt.com

        vx_newport_preproduction@rjrt.com
        vx_newport_production@rjrt.com

        vx_pallmall_preproduction@rjrt.com
        vx_pallmall_production@rjrt.com

        vx_velo_dev@rjrt.com
        vx_velo_production@rjrt.com
        vx_velo_production@rjrt.com

        vx_vuse_pre_production@rjrt.com
        vx_vuse_production@rjrt.com

        ------- "nas" is "North American Spirit" -------
        american_spirit_vx_prod@rjrt.com
        american_spirit_vx_qa@rjrt.com

        ------- no styles provided -------
        levi_garrett_vx_prod@rjrt.com
        levi_garrett_vx_qa@rjrt.com

        /* vx_camel_dev@rjrt.com, vx_camel_qa@rjrt.com, vx_camel_uat@rjrt.com, vx_camel_production@rjrt.com, camel_snus_vx_prod@rjrt.com, camel_snus_vx_qa@rjrt.com */
        '21-8dca8e1e-610f-438d-a1b0-4f50efe27765': rjBrands,
        /* cougar_vx_prod@rjrt.com, cougar_vx_qa@rjrt.com */
        '21-3bd0455f-e4af-4afe-a7d7-f7690282bbd6': rjBrands,
        /* vx_grizzly_preproduction@rjrt.com, vx_grizzly_production@rjrt.com */
        '21-8fd86aaf-847a-4bd2-9bf2-b0cef13eb233': rjBrands,
        /* kodiak_vx_prod@rjrt.com, kodiak_vx_qa@rjrt.com */
        '21-6e4364af-6e0e-479a-b319-e95fcc41d04f': rjBrands,
        /* lucky_strike_vx_prod@rjrt.com, lucky_strike_vx_qa@rjrt.com */
        '21-0ad07db5-ebff-4107-9571-9693873fccf2': rjBrands,
        /* "nas": american_spirit_vx_prod@rjrt.com, american_spirit_vx_qa@rjrt.com */
        '21-9561087f-94ce-44a1-b001-1ce7a1b86448': rjBrands,
        /* vx_newport_preproduction@rjrt.com, vx_newport_production@rjrt.com */
        '21-eb336d5c-696f-4353-922c-3eadfed54cc7': rjBrands,
        /* vx_pallmall_preproduction@rjrt.com, vx_pallmall_production@rjrt.com */
        '21-a0ce4000-05a2-4678-9847-985082362bc9': rjBrands,
        /* vx_velo_dev@rjrt.com, vx_velo_production@rjrt.com */
        '21-dcda86a9-5777-4cb8-afc0-37af797fc9de': rjBrands,
        /* vx_vuse_pre_production@rjrt.com, vx_vuse_production@rjrt.com */
        '21-fa10b10f-bd9e-4d7f-ba35-e8864865eb0b': rjBrands,
        /* levi_garrett_vx_prod@rjrt.com, levi_garrett_vx_qa@rjrt.com */
        '21-19e78102-e1c7-49c5-90c3-293730bc4c7a': rjBrands,
        /* sensavape_vx_prod@rjrt.com, sensavape_vx_qa@rjrt.com */
        '21-18ab475d-dfc7-4dc1-b787-5da94b54e1ed': rjBrands,
    }

    return { getNativeMappedTheme, }
}
