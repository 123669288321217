export const bouncerMessages = {
    'TARGET ATTEMPTS EXCEEDED LIMIT': {
        callbackEvent: 'VELOCITY',
        errorMessage: 'Too Many Attempts',
        errorDescription: 'You have exceeded the number of verification attempts. Please call Customer Service.',
    },
    'TARGET ALREADY VERIFIED': {
        callbackEvent: 'ALREADY VERIFIED',
        errorMessage: 'You Are Already Verified',
        errorDescription: 'You have already passed this process and are not able to upload a new document at this time. Please call Customer Service.',
    },
}

/**
 * Existing callback events:
 * 'ALREADY VERIFIED' onErrorAlreadyVerified
 * 'VELOCITY' onErrorVelocity
 * 'PASS' onSuccess
 * 'FAIL' onFailure
 * 'PENDING' onErrorPending
 * 'EMAIL' onEmail
 * 'QR' onQr
*/






