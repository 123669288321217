import React from 'react'
import { useSelector } from 'react-redux'
import {
    useLocation,
    useRouteMatch,
    Route,
    Switch,
} from 'react-router-dom'
import {
    MantineProvider,
    Box,
    Container,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import MediaQuery from 'react-responsive'
import loadable from '@loadable/component'

import {
    useJourneyUpdate, useGetSource
} from '../../hooks/journeyHooks'
import { useDeviceReporter } from '../../hooks/hooks'
import { useTheme } from '../../nativeThemeMapper'
// import { inIframe } from '../../../utilities/helpers'

import '../../css/dcamsGlobals.css'
import 'bootstrap/dist/css/bootstrap.min.css'

/** ************************************************************************************** */
import Error from '../../App.Error'

// import DcamsHeader from './default/components/dcamsHeader'
const DcamsHeader = loadable(( { source, } ) => import( `./${source}/components/dcamsHeader` ))

// import DcamsNotificationBar from './default/components/dcamsNotificationBar'
const DcamsNotificationBar = loadable(( { source, } ) => import( `./${source}/components/dcamsNotificationBar` ))

// import ScanIntroMobile from './default/screens/autoCapture/ScanIntroMobile'
const ScanIntroMobile = loadable(( { source, } ) => import( `./${source}/screens/autoCapture/ScanIntroMobile` ))
// import ScanIntroDesktop from './default/screens/desktop/ScanIntroDesktop'
const ScanIntroDesktop = loadable(( { source, } ) => import( `./${source}/screens/desktop/ScanIntroDesktop` ))

// paths pushed: Nav, ScanIntroDesktop, MethodSelect
// import QrIndex from './default/screens/qr/QrIndex'
const QrIndex = loadable(( { source, } ) => import( `./${source}/screens/qr/QrIndex` ))
// paths pushed: ScanIntroDesktop, MethodSelect, QrIndex
// import EmailSend from './default/screens/email/Send'
const EmailSend = loadable(( { source, } ) => import( `./${source}/screens/email/Send` ))

// import ScanIndex from './default/screens/autoCapture/ScanIndex'
const ScanIndex = loadable(( { source, } ) => import( `./${source}/screens/autoCapture/ScanIndex` ))
// import ScanSideStart from './default/screens/autoCapture/ScanSideStart'
const ScanSideStart = loadable(( { source, } ) => import( `./${source}/screens/autoCapture/ScanSideStart` ))

// import UploadIndex from './default/screens/manualUpload/UploadIndex'
const UploadIndex = loadable(( { source, } ) => import( `./${source}/screens/manualUpload/UploadIndex` ))

// import SelfieIndex from './default/screens/selfie/SelfieIndex'
const SelfieIndex = loadable(( { source, } ) => import( `./${source}/screens/selfie/SelfieIndex` ))
// import SelfieScan from './default/screens/selfie/SelfieScan'
const SelfieScan = loadable(( { source, } ) => import( `./${source}/screens/selfie/SelfieScan` ))

// import DcamsResult from './default/screens/dcamsResult'
const DcamsResult = loadable(( { source, } ) => import( `./${source}/screens/dcamsResult` ))

const DcamsReview = loadable(( { source, } ) => import( `./${source}/screens/review` ))

// import DocumentTypeIntro from './default/screens/documentType/DocumentTypeIntro'
const DocumentTypeIntro = loadable(( { source, } ) => import( `./${source}/screens/documentType/DocumentTypeIntro` ))
// import DocumentTypeMobileIntro from './default/screens/documentType/DocumentTypeMobileIntro'
const DocumentTypeMobileIntro = loadable(( { source, } ) => import( `./${source}/screens/documentType/DocumentTypeMobileIntro` ))
/** ************************************************************************************** */

// const DcamsLoading = loadable(( { source, } ) => import( `./${source}/screens/dcamsLoading` ))

export default function DcamsManager( ) {
    const { journeyId, } = useSelector( state => state.journey )
    // const { showNotification, } = useSelector( state => state.dcamsCapture )
    const { notify, } = useSelector( state => state.notify )
    const {
        scroll,
        inIframeContext,
    } = useSelector( state => state.ui )
    const { getNativeMappedTheme, } = useTheme()

    const location = useLocation()
    const { path, } = useRouteMatch()
    // const isIframe = inIframe()

    // If this manager was handed off by a previous journey stop,
    // start by updating the stop and the root path.
    useJourneyUpdate( location )

    useDeviceReporter()

    // Map component sources for the journey (custom vs. default)
    const { getSource, } = useGetSource()

    const maxDeviceWidth1224 = useMediaQuery( '(max-device-width: 1224px)' )

    // TODO: Due to time constraints, the DCAMS manager and it's child
    // components do not yet accommodate grid layout when rendered in the
    // veratad modal.
    // const vm = window.top.document.querySelector( '.veratad-modal' )
    const vm = inIframeContext

    return vm
        ? (
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={ getNativeMappedTheme( journeyId ) } // TODO: Raise to App.Manager and make journey themes global
            >
                <Box
                    className="dcams-box"
                    sx={ {
                        margin: 0,
                        padding: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: scroll ? 'scroll' : null,
                    } }
                >

                    {/* ************************************************************ */}
                    {/* TODO: Create re-usable manager frame with the above elements */}
                    {/* ************************************************************ */}

                    <DcamsHeader source={ getSource( 'DcamsHeader' ) } />
                    {/* <Route
                    render={ () => {
                        const lastItem = location.pathname.substring( location.pathname.lastIndexOf( '/' ) + 1 )
                        return lastItem === 'dcams_verificationprocessing' ? null : ( <DcamsHeader /> )
                    } }
                /> */}

                    {/* Banner */}
                    <Route render={ () => ( notify ? <DcamsNotificationBar source={ getSource( 'DcamsNotificationBar' ) } /> : null ) } />

                    <Container
                        className="dcams-container"
                        fluid
                        // px={ isIframe ? 80 : 20 }
                        mx={ 0 }
                        px={ maxDeviceWidth1224 ? 25 : 80 }
                        sx={ { height: '100%', } }
                    >

                        <Box
                            className="dcams-manager-grid"
                            sx={ {
                                display: 'grid',
                                gridTemplateRows: '[manager-spacer] 65.2vh [manager-foot] 1fr',
                                gridTemplateColumns: '[manager-single-col] 100%',
                                gap: '0px 0px',
                            } }
                        >
                            <Switch>
                                <Route path={ `${path}/` } exact>
                                    <MediaQuery maxDeviceWidth={ 1224 }>
                                        {matches => ( matches
                                            ? (
                                                <ScanIntroMobile source={ getSource( 'ScanIntroMobile' ) } />
                                            )
                                            : (
                                                <ScanIntroDesktop source={ getSource( 'ScanIntroDesktop' ) } />
                                                // <DcamsResult source={ getSource( 'DcamsResult' ) } />
                                                // <DcamsLoading source={ getSource( 'DcamsLoading' ) } />
                                            ))}
                                    </MediaQuery>
                                </Route>

                                <Route path={ `${path}/qr` }>
                                    <QrIndex source={ getSource( 'QrIndex' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/email/send` }
                                >
                                    <EmailSend source={ getSource( 'EmailSend' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/scan/:type/:side` }
                                >
                                    <ScanIndex source={ getSource( 'ScanIndex' ) } />
                                    {/* <ScanIndexTest /> */}
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/scan/scansidestart/:type/:side` }
                                >
                                    <ScanSideStart source={ getSource( 'ScanSideStart' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/upload/:type` }
                                >
                                    <UploadIndex source={ getSource( 'UploadIndex' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/selfie` }
                                >
                                    <SelfieIndex source={ getSource( 'SelfieIndex' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/selfie/scan/:side` }
                                >
                                    <SelfieScan source={ getSource( 'SelfieScan' ) } />
                                </Route>

                                <Route path={ `${path}/dcams_verificationprocessing` }>
                                    <DcamsResult source={ getSource( 'DcamsResult' ) } />
                                </Route>

                                <Route path={ `${path}/review` }>
                                    <DcamsReview source={ getSource( 'DcamsReview' ) } />
                                </Route>

                                <Route path={ `${path}/error` }>
                                    <Error
                                        message="Something Went Wrong"
                                        description="This was a system error. Please contact customer service."
                                    />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/documentTypeIntro` }
                                >
                                    <DocumentTypeIntro source={ getSource( 'DocumentTypeIntro' ) } />
                                </Route>

                                <Route
                                    exact
                                    path={ `${path}/dcams/documentTypeIntro/mobile` }
                                >
                                    <DocumentTypeMobileIntro source={ getSource( 'DocumentTypeMobileIntro' ) } />
                                </Route>
                            </Switch>
                        </Box>
                    </Container>
                </Box>
            </MantineProvider>
        ) : (
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={ getNativeMappedTheme( journeyId ) } // TODO: Raise to App.Manager and make journey themes global
            >
                <Box
                    sx={ {
                        margin: 0,
                        padding: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    } }
                >

                    {/* ************************************************************ */}
                    {/* TODO: Create re-usable manager frame with the above elements */}
                    {/* ************************************************************ */}

                    <DcamsHeader source={ getSource( 'DcamsHeader' ) } />
                    {/* <Route
                    render={ () => {
                        const lastItem = location.pathname.substring( location.pathname.lastIndexOf( '/' ) + 1 )
                        return lastItem === 'dcams_verificationprocessing' ? null : ( <DcamsHeader /> )
                    } }
                /> */}

                    {/* Banner */}
                    <Route render={ () => ( notify ? <DcamsNotificationBar source={ getSource( 'DcamsNotificationBar' ) } /> : null ) } />

                    <Container
                        fluid
                        // px={ isIframe ? 80 : 20 }
                        mx={ 0 }
                        px={ maxDeviceWidth1224 ? 25 : 80 }
                        sx={ { height: '100%', } }
                    >

                        <Switch>
                            <Route path={ `${path}/` } exact>
                                <MediaQuery maxDeviceWidth={ 1224 }>
                                    {matches => ( matches
                                        ? (
                                            <ScanIntroMobile source={ getSource( 'ScanIntroMobile' ) } />
                                            // <DcamsReview source={ getSource( 'DcamsReview' ) } />
                                        )
                                        : (
                                            <ScanIntroDesktop source={ getSource( 'ScanIntroDesktop' ) } />
                                        ))}
                                </MediaQuery>
                            </Route>

                            <Route path={ `${path}/qr` }>
                                <QrIndex source={ getSource( 'QrIndex' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/email/send` }
                            >
                                <EmailSend source={ getSource( 'EmailSend' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/scan/:type/:side` }
                            >
                                <ScanIndex source={ getSource( 'ScanIndex' ) } />
                                {/* <ScanIndexTest /> */}
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/scan/scansidestart/:type/:side` }
                            >
                                <ScanSideStart source={ getSource( 'ScanSideStart' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/upload/:type` }
                            >
                                <UploadIndex source={ getSource( 'UploadIndex' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/selfie` }
                            >
                                <SelfieIndex source={ getSource( 'SelfieIndex' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/selfie/scan/:side` }
                            >
                                <SelfieScan source={ getSource( 'SelfieScan' ) } />
                            </Route>

                            <Route path={ `${path}/dcams_verificationprocessing` }>
                                <DcamsResult source={ getSource( 'DcamsResult' ) } />
                            </Route>

                            <Route path={ `${path}/review` }>
                                <DcamsReview source={ getSource( 'DcamsReview' ) } />
                            </Route>

                            <Route path={ `${path}/error` }>
                                <Error
                                    message="Something Went Wrong"
                                    description="This was a system error. Please contact customer service."
                                />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/documentTypeIntro` }
                            >
                                <DocumentTypeIntro source={ getSource( 'DocumentTypeIntro' ) } />
                            </Route>

                            <Route
                                exact
                                path={ `${path}/dcams/documentTypeIntro/mobile` }
                            >
                                <DocumentTypeMobileIntro source={ getSource( 'DocumentTypeMobileIntro' ) } />
                            </Route>
                        </Switch>
                    </Container>
                </Box>
            </MantineProvider>
        )
}
