// TODO: Break hooks out into separate modules

import { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery, useElementSize } from '@mantine/hooks'
import { useDeviceData } from 'react-device-detect'

import {
    setClientDataRequestId,
    setClientDataCountry,
    setClientDataFrontExt,
    setClientDataBackExt,
} from '../state/init.slice'
import { postVerifyCaptureScan } from '../state/dcams/dcamsVerify.slice'
import { reportDeviceType } from '../state/deviceDetect.slice'

// import {
//     logger, logJson
// } from '../utilities/helpers'
import { isObject } from '../utilities/helpers'

export const useInterval = (callback, delay) => {
    const savedCallback = useRef()
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            // eslint-disable-next-line prefer-const
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

export const useDcamsVerifyHandler = () => {
    /**
     * Refactor the match.params.side parameter slug: factor out
     * the /dcams_verificationprocessing subpath to its own route.
     * scanSideStartPath should never hold this value 'dcams_verificationprocessing'
     * see dcams/scan/ScanIndex
     */
    const { front_ext } = useSelector(state => state.init.clientData)
    const { request_id, country } = useSelector(state => state.init.clientSettings)
    const { pathRoot } = useSelector(state => state.journey)
    const dispatch = useDispatch()

    const handleVerifyAction = history => {
        history.push(`${pathRoot}/dcams_verificationprocessing`)

        dispatch(setClientDataRequestId(request_id))
        dispatch(setClientDataCountry(country))

        if (!front_ext) {
            dispatch(setClientDataFrontExt('jpeg'))
            dispatch(setClientDataBackExt('jpeg'))
        }
        dispatch(postVerifyCaptureScan())
    }

    return { handleVerifyAction }
}

export const useAnimation = fileName => {
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`../assets/animations/${fileName}`)
                setImage(response.default)
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }

        fetchImage()
    }, [fileName])

    // return {
    //     loading,
    //     error,
    //     image,
    // }
    return image
}

/**
 * Send postMessage events intended for end-user consumption.
 *
 * For bouncer match journey interruptions and the triggered callback events,
 * see:
 * state/messages bouncerMessages
 * state/actions bouncerMatchFailure
 * state/init reducer case bouncerMatchFailure and its usages
 */
export const useMessagePasser = () => {
    const {
        journeyStop,
        status: statusState,
        config: { messageHandler, usePostMessage, useEventCallbacks },
    } = useSelector(state => state.journey)
    const mh = {
        ReactNativeWebView: window.ReactNativeWebView,
        window_top: window.top,
        // mockPostMessage: window.top.mockPostMessage,
    }

    const passMessage = ({ status, component, event, error, callbackEvent }) => {
        // --------
        // logger.log( '[PASS MESSAGE:]', JSON.stringify( {
        //     status,
        //     component,
        //     event,
        //     error,
        //     callbackEvent,
        // }, null, 4 ))

        // NOTE: callbackEvent messages are used by clients that require
        // pre-defined callbacks to fire within an iFrame context.
        // Non-callbackEvent messages are used by clients to trigger their own
        // internal callback or equivalent.

        // TODO: no reason we can't handle both callbackEvent and
        // non-callbackEvent in the same call
        if (!callbackEvent && messageHandler && usePostMessage) {
            const message = {
                status: status || statusState,
                component: component || journeyStop.split('_')[0].toUpperCase(),
                event,
            }
            if (error) {
                message['error'] = error
            }
            // TODO: the ad-hoc changes for clients on short notice have made
            // this a mess. Obviously, clean this up. For one thing, just
            // decision on whether we're sending a string or an object.
            if (messageHandler === 'window_top' || messageHandler === 'mockPostMessage') {
                if (isObject(message)) {
                    mh[messageHandler].postMessage(JSON.stringify(message), '*')
                } else {
                    mh[messageHandler].postMessage(message, '*')
                }
            } else {
                mh[messageHandler].postMessage(JSON.stringify(message))
            }
            // mh[ messageHandler ].postMessage( JSON.stringify( message ))
        }

        if (
            callbackEvent &&
            // && messageHandler
            useEventCallbacks // for generic components that will always send a callbackEvent
        ) {
            // logger.log( '[PASS MESSAGE CALLBACK EVENT]', callbackEvent )
            window.top.postMessage(callbackEvent, '*')
        }
    }

    return { passMessage }
}

/**
 * Send postMessage events that invoke Modal.prototype functions defined in
 * vxle-static/js/initialize.js
 *
 * Keep separate for future divergence of interfaces (private messages vs
 * end-user messages)
 */
const passModalMessage = (func, val) => {
    // { navigate: <href string> }
    // { adjustGrid: <gridTemplateRows string> }
    // { setStyle: { <attribute>: <value> } }
    // window.parent.postMessage( {
    window.top.postMessage(
        {
            func,
            val,
        },
        '*',
    )
    // window.top.postMessage( JSON.stringify( {
    //     func,
    //     val,
    // } ))
}

export const useModalMessagePasser = () => {
    return { passModalMessage }
}

/**
 * @param {Int} height Set the modal height
 * @param {Int} width (future) Set the modal width
 *
 * The current architecture renders nested frames and DOMs such that style
 * computations for each layer are isolated: <top level document> --> modal -->
 * iframe --> embedded #document This architecture calls for refactoring.
 * Attempting to pull in CSS from cross-origin (via JS) is not reliable and
 * creates a security vulnerability.
 *
 * [------- NOTE: Modal rendering should be in-housed to the React application. -------]
 *
 * 590px is hard-coded as a min height to match the client frame CSS veratad-modal class.
 */
export const useSetModal = ({ height }) => {
    const { inIframeContext } = useSelector(state => state.ui)
    const maxDeviceWidth1224 = useMediaQuery('(max-device-width: 1224px)')
    const { logoName } = useSelector(state => state.journey.config)
    useEffect(() => {
        const modalMinHeight = 590
        const vm = inIframeContext
        if (vm && !maxDeviceWidth1224) {
            // logger.log( '[PASSING MODAL MESSAGE]', 'adjustGrid', `1fr ${height}px 1fr` )
            passModalMessage(
                'adjustGrid',
                `1fr ${logoName === 'none' || logoName === 'transparent' ? height : height + 50}px 1fr`,
            )
        }
        return () => {
            if (vm && !maxDeviceWidth1224) {
                passModalMessage('adjustGrid', `1fr ${modalMinHeight}px 1fr`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
/**
 * DEPRECATED VERSION
 * Leaving this version in place for KBA for now.
 * -------> TODO: refactor KBA to useSetModal.
 *
 * @param {Int} extend Extend the modal height by <extend>px if non-zero,
 * otherwise base the height on the ref element.
 *
 * The current architecture renders nested frames and DOMs such that style
 * computations for each layer are isolated: <top level document> --> modal -->
 * iframe --> embedded #document This architecture calls for refactoring.
 * Attempting to pull in CSS from cross-origin (via JS) is not reliable and
 * creates a security vulnerability.
 *
 * [------- NOTE: Modal rendering should be in-housed to the React application. -------]
 *
 * 590px is hard-coded as a min height to match the client frame CSS veratad-modal class.
 */
let maxHeight = 0
export const useSetModalHeight = ({ extend }) => {
    const { inIframeContext } = useSelector(state => state.ui)
    const { ref, height } = useElementSize()

    const [modalRowHeight, setModalRowHeight] = useState(0)
    const [defaultGridTemplateRows, setDefaultGridTemplateRows] = useState(0)

    const maxDeviceWidth1224 = useMediaQuery('(max-device-width: 1224px)')
    const { headerHeight } = useSelector(state => state.ui)
    const spacer = extend || 64 // 94
    // const vm = window.top.document.querySelector( '.veratad-modal' )
    const vm = inIframeContext

    // TODO: refactor to useState & test
    maxHeight = maxHeight < height ? height : maxHeight

    useLayoutEffect(() => {
        /** TODO: resolve */
        console.log('!maxDeviceWidth1224', !maxDeviceWidth1224)
        console.log('vm', vm)
        // const __vm = document.querySelector( '.veratad-modal' )
        // console.log( 'window.getComputedStyle( __vm ).gridTemplateRows', window.getComputedStyle( __vm ).gridTemplateRows )
        if (!maxDeviceWidth1224 && vm) {
            //
            // const _vm = document.querySelector( '.veratad-modal' )
            // console.log( 'window.getComputedStyle( _vm ).gridTemplateRows', window.getComputedStyle( _vm ).gridTemplateRows )
            //
            if (modalRowHeight === 0) {
                const _vm = document.querySelector('.veratad-modal')
                // const gtr = window.getComputedStyle( vm ).gridTemplateRows
                const gtr = window.getComputedStyle(_vm).gridTemplateRows
                setDefaultGridTemplateRows(gtr)
                if (extend) {
                    const mrh = parseInt(gtr.split(' ')[1], 10)
                    setModalRowHeight(mrh < 590 ? 590 : mrh)
                }
            }

            // logger.log( extend ? `[SETTING modalRowHeight +] ${`1fr ${modalRowHeight + headerHeight + spacer}px 1fr`}` : `[SETTING maxHeight +]' ${`1fr ${maxHeight + headerHeight + spacer}px 1fr`}` )
            // logger.log( 'modal row height', modalRowHeight )
            // logger.log( 'extend', extend )

            const gridTemplateRows = extend
                ? `1fr ${modalRowHeight + headerHeight + spacer}px 1fr`
                : `1fr ${maxHeight + headerHeight + spacer}px 1fr`
            passModalMessage('adjustGrid', gridTemplateRows)
        }

        return () => {
            if (!maxDeviceWidth1224 && vm && (modalRowHeight !== 0 || maxHeight !== 0)) {
                passModalMessage('adjustGrid', defaultGridTemplateRows)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalRowHeight, maxHeight])

    return extend ? null : ref
}

export const useClientRect = () => {
    const [rect, setRect] = useState(null)
    const ref = useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect())
        }
    }, [])
    return [rect, ref]
}

export const useDeviceReporter = () => {
    const { request_id } = useSelector(state => state.init.clientData)
    const { ipDataLoaded, mobileCarrier, reportRun, fingerprint } = useSelector(state => state.deviceDetect)
    const {
        config: { journey },
        journeyStop,
    } = useSelector(state => state.journey)
    const dispatch = useDispatch()
    const maxDeviceWidth1224 = useMediaQuery('(max-device-width: 1224px)')
    const deviceData = useDeviceData()

    const firstStop = journey[0] === journeyStop

    useEffect(() => {
        if (request_id && firstStop && ipDataLoaded && !reportRun) {
            dispatch(
                reportDeviceType({
                    request_id,
                    maxDeviceWidth1224,
                    deviceData,
                    mobileCarrier,
                    fingerprint,
                }),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request_id, ipDataLoaded, reportRun])
}

/**  Passing callback as a dep */
// const useCustomHook = callback => {
//     useEffect(() => { /* do something */ }, [ callback, ] )
// }
/** then: */
// useCustomHook(
//     useCallback(() => { /* do something */ }, [ a, b, c, ] )
// )

export const useStyleOverride = styleOverride => {
    const [classes, setCss] = useState({})

    useEffect(() => {
        const loadCss = async () => {
            if (styleOverride && styleOverride.length) {
                const _classes = await import(`../css/overrides/${styleOverride}.module.css`)
                setCss(_classes.default)
            }
        }

        loadCss()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return classes
}
