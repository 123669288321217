import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
} from 'react-router-dom'
import {
    useSelector, useDispatch
} from 'react-redux'

import {
    MantineProvider,
    Box,
    Container,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import loadable from '@loadable/component'

import {
    kbaLoading,
    kbaGetQuestions
} from '../../state/kba.slice'

import {
    useJourneyUpdate, useGetSource
} from '../../hooks/journeyHooks'
import { useDeviceReporter } from '../../hooks/hooks'
import { useTheme } from '../../nativeThemeMapper'
// import { inIframe } from '../../../utilities/helpers'

// import ModalMockBox from './1985/components/modalMockBox'

const KbaHeader = loadable(( { source, } ) => import( /* webpackPreload: true */ `./${source}/components/kbaHeader` ))
const KbaIntro = loadable(( { source, } ) => import( `./${source}/screens/kbaIntro` ))
const KbaMoreDetails = loadable(( { source, } ) => import( /* webpackPreload: true */ `./${source}/screens/kbaMoreDetails` ))
const KbaTryAgain = loadable(( { source, } ) => import( /* webpackPreload: true */ `./${source}/screens/kbaTryAgain` ))
const KbaQuestions = loadable(( { source, } ) => import( `./${source}/screens/kbaQuestions` ))
const KbaResult = loadable(( { source, } ) => import( `./${source}/screens/kbaResult` ))


export default function KbaManager() {
    const { missingParams, } = useSelector( state => state.init )
    const { request_id, } = useSelector( state => state.init.clientSettings )
    const { journeyId, } = useSelector( state => state.journey )
    const { scroll, } = useSelector( state => state.kba.layout )
    const dispatch = useDispatch()
    const location = useLocation()
    const { path, } = useRouteMatch()
    const { getNativeMappedTheme, } = useTheme()
    // const isIframe = inIframe()

    // If this is a handoff, update journey stop and path root
    useJourneyUpdate( location )

    useDeviceReporter()

    // Map component sources for the journey (custom vs. default)
    const { getSource, } = useGetSource()

    const maxDeviceWidth1224 = useMediaQuery( '(max-device-width: 1224px)' )

    useEffect(() => {
        if ( !missingParams.length && request_id ) {
            dispatch( kbaLoading( {
                state: true,
                message: 'Verification in progress',
            } ))
            dispatch( kbaGetQuestions( { request_id, } ))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ request_id, ] )

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={ getNativeMappedTheme( journeyId ) } // TODO: Raise to App.Manager and make journey themes global
        >

            {/* <ModalMockBox
                active={ !isMobile }
            > */}

            <Box
                sx={ {
                    margin: 0,
                    padding: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: scroll ? 'scroll' : null,
                } }
            >

                {/* ************************************************************ */}
                {/* TODO: Create re-usable manager frame with the above elements */}
                {/* ************************************************************ */}

                <KbaHeader source={ getSource( 'KbaHeader' ) } />

                <Container
                    fluid
                    // px={ isIframe ? 80 : 20 }
                    mx={ 0 }
                    px={ maxDeviceWidth1224 ? 20 : 80 }
                    sx={ { height: '100%', } }
                >

                    <Switch>
                        <Route path={ `${path}/` } exact><KbaIntro source={ getSource( 'KbaIntro' ) } /></Route>
                        <Route path={ `${path}/more-details` }><KbaMoreDetails source={ getSource( 'KbaMoreDetails' ) } /></Route>
                        <Route path={ `${path}/try-again` }><KbaTryAgain source={ getSource( 'KbaTryAgain' ) } /></Route>
                        <Route path={ `${path}/questions` }><KbaQuestions source={ getSource( 'KbaQuestions' ) } /></Route>
                        <Route path={ `${path}/result` }><KbaResult source={ getSource( 'KbaResult' ) } /></Route>
                    </Switch>

                </Container>

            </Box>

            {/* </ModalMockBox> */}

        </MantineProvider>
    )
}

