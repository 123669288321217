/* eslint-disable destructuring-newline/object-property-newline */
/* eslint-disable object-property-newline */
/* eslint-disable prefer-destructuring */
import {
    createAsyncThunk,
    createSlice, // createAction
} from '@reduxjs/toolkit'
// eslint-disable-next-line no-unused-vars
import { dataService } from '../services/dataService'

// import { logJson } from '../utilities/helpers'

const journeyState = {
    journeyId: '',
    companyId: '',

    journeyStop: '', // default is falsy for initial journey stop
    pathRoot: '',
    serviceName: '',

    config: {},

    callbackResponse: {},

    journeyLoaded: false,
    journeyLoading: true,
    journeyError: false,
    errorMessage: '',
    errorDescription: '',
    rejectionError: '',

    status: '',
}

export const fetchJourneyConfig = createAsyncThunk(
    'journey/fetchJourneyConfig',
    // eslint-disable-next-line no-unused-vars
    async (journeyIdPayload, thunkAPI) => {
        const { journey_id, request_id, carrier } = journeyIdPayload

        let ab

        // if (carrier !== 'NONE') {
        // DEVELOPMENT ONLY
        if (carrier !== 'NONE' || journey_id === '21-8dca8e1e-610f-438d-a1b0-4f50efe27765') {
            const abPayload = {
                journey_id,
                request_id,
                carrier,
            }
            ab = await dataService.getABStatus(abPayload)
            console.log('ab', ab)
        }

        if (journey_id && Object.prototype.hasOwnProperty.call(journeys, journey_id)) {
            const journeyConfig = journeys[journey_id]

            if (ab && ab.journey_order) {
                journeyConfig.journey = ab.journey_order
            }

            return {
                journey_id,
                config: journeyConfig,
            }
        }

        return {
            journey_id: 'default',
            config: journeys['default'],
        }
    },
)

// TODO: this mechanism should be moved to the VX backend. Status derivation
// logic (source of truth) is duplicated in frontend (see fireJourneyCallbback
// usage in kba.slice) and backend (see python backend kba._get_status_by_action).
// See also php web/v3/stable/callback/journey_cb.php.
// SUMMARY: the python backend should initiate these callbacks, at the point
// where it derives the status.
export const fireJourneyCallback = createAsyncThunk('journey/fireCallback', async (_payload, thunkAPI) => {
    const { token, request_id, fn, ln, addr, city, state, zip, dob, phone, email, reference } =
        thunkAPI.getState().init.clientData
    const { status } = _payload
    const payload = {
        token,
        request_id,
        fn,
        ln,
        addr,
        city,
        state,
        zip,
        dob,
        phone,
        email,
        status,
        reference,
    }
    // logJson( 'firing callback :: payload', payload )
    const response = await dataService.fireCallback(payload)
    return response
})

const journeySlice = createSlice({
    name: 'journey',
    initialState: journeyState,

    reducers: {
        journeyLoadError: draftState => {
            return {
                ...draftState,
                journeyLoading: false,
                journeyError: true,
                errorMessage: 'Failed to load journey.',
                errorDescription: 'A bad navigation occurred.',
            }
        },
        // Should run for all noninitial journey stops
        // Initial stop is set by fetchJourneyConfig.fulfilled
        updateJourney: (draftState, action) => {
            const key = action.payload.split('_')[0]
            draftState.serviceName = draftState.config.services[key].service
            draftState.journeyStop = action.payload
            draftState.pathRoot = `/${action.payload.replace('_', '/')}`
        },
        setJourneyStatus: (draftState, action) => {
            draftState.status = action.payload
        },
    },

    extraReducers: builder => {
        builder
            // eslint-disable-next-line consistent-return
            .addCase(fetchJourneyConfig.fulfilled, (draftState, action) => {
                if (action.payload.error) {
                    return {
                        ...draftState,
                        journeyLoading: false,
                        journeyError: action.payload.error,
                        errorMessage: action.payload.error.message,
                        errorDescription: action.payload.error.description,
                    }
                }

                draftState.journeyId = action.payload.journey_id
                draftState.companyId = action.payload.journey_id.split('-')[0]

                // initialize the current (first) journey stop if it has not been set by a desktop handoff
                if (!draftState.journeyStop) {
                    const stop = action.payload.config.journey[0]

                    const key = stop.split('_')[0]
                    draftState.serviceName = action.payload.config.services[key].service

                    // draftState.journeyStop = action.payload.config.journey[ 0 ]
                    draftState.journeyStop = stop

                    // draftState.pathRoot = `/${action.payload.config.journey[ 0 ].replace( '_', '/' )}`
                    draftState.pathRoot = `/${stop.replace('_', '/')}`
                }

                draftState.config = action.payload.config

                draftState.journeyLoaded = true
                draftState.journeyLoading = false
            })
            .addCase(fetchJourneyConfig.rejected, (draftState, action) => {
                // (handle promise rejection)
                // action.error is a serialized error value
                return {
                    ...draftState,
                    journeyLoading: false,
                    journeyError: true,
                    errorMessage: 'Something Went Wrong',
                    errorDescription: 'This was a system error. Please contact customer service.',
                    // rejectionError: JSON.parse( action.error ),
                    rejectionError: action.error,
                }
            })

            .addCase(fireJourneyCallback.fulfilled, (draftState, action) => {
                draftState.callbackResponse = action.payload
            })
            .addCase(fireJourneyCallback.rejected, (draftState, action) => {
                draftState.rejectionError = action.error
            })
    },
})

export const {
    // setJourneyStop,
    journeyLoadError,
    updateJourney,
    setJourneyStatus,
} = journeySlice.actions

export default journeySlice.reducer

/**
 * The journey_id under which a config is stored looks like the one used here:
 * <company_id>-<uuid4>.
 *
 * Journey components can be ordered arbitrarily in the journey list.
 * One journey component can hand off to any other journey component.
 *
 * In the journey list below, "kba_1985" becomes the root url path that
 * renders a journey-component tree and its sub-routes:
 * "kba/:company_id/"
 * This path is mapped to either the default tree (most cases) or to a
 * *partial* tree with bespoke UI layouts that sources the default where
 * possible.
 *
 * Both default and bespoke are themed by the theme object contained in the config.
 * I think that themeing should be raised to journey level to the extent that it can be.
 * (The nature of the first dev arc meant I started with it at the component level).
 */
const journeys = {
    /** ****************** Wawa full journey */
    '1985-f5acc615-a06d-4d8a-9efe-b16f475c766e': {
        journey: ['kba_1985', 'dcams_1985'],
        // TODO: Migrate Mitek manualHints and autoHints to journey config?
        notificationType: 'notificationBar', // 'inlineFlash',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: false,
        messageHandler: window.self !== window.top ? 'window_top' : 'ReactNativeWebView',

        displayResultFooter: true,

        contactCustomerServiceUrl: '',
        logoName: 'none',

        services: {
            kba: {
                service: 'AgeMatch5.0.KBA.Wawa.v1',
                rules: {
                    ruleset: 'AgeMatch5_0_KBA_RuleSet',
                },
                // TODO: add this property for other services?
                displayLastStopResultButtons: true,
                text: {
                    resultActionButton: {
                        pass: 'Continue',
                        fail: "Let's Try Photo ID Scan",
                        failLastStop: 'Close',
                    },
                },
                theme: {
                    primaryColor: '#CE0E2D',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#CE0E2D',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Asap',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.125rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Lato',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Fira Sans',
                    textSubFontSize: '13px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '20px',
                    textSubcolor: '#4C4C4C',
                    textDangerColor: '#CE0E2D',
                    notificationBarFontWeight: 600, // bannerText inherits textSub properties
                    boldSpans: true, // emphasized help text
                    buttonTextFontFamily: 'Asap',
                    buttonTextFontSize: '17px',
                    buttonTextFontWeight: 600,
                    buttonTextLineHeight: '20px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '#F21740', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // '#F21740', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // '#CE0E2D82', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonRadius: 'xl', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    buttonHeight: '44px',
                    radioButtonUncheckedBorderColor: '#C0C0C0',
                    radioButtonCheckedBackgroundImage: `url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='13' fill='%2374AA50'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M13.046 16.34l-1.92 1.86-1.92-1.86-3.14-3.045 1.92-1.86 3.14 3.043L18.015 7.8l1.918 1.86-6.887 6.68z' clip-rule='evenodd'/%3E%3C/svg%3E" )`,
                    radioButtonCheckedBorderColor: '#75AA4F',
                    alertTextColor: '#007298',
                    alertBackgroundColor: '#0072981a',
                    anchorTextFontFamily: 'Asap',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '16px',
                    anchorTextColor: '', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    inputTextFontFamily: 'Asap',
                    inputTextFontSize: '16px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '22px',
                    inputTextColor: '#4C4C4C', // '#000', // #4A4A4A // defaults to primaryColor
                    inputLabelTextFontSize: '12px', // inputLabel shares inputText family, weight, & color
                    inputLabelTextLineHeight: '14px', // inputLabel shares inputText family, weight, & color
                    dialogueHeadingFontFamily: 'Asap',
                    dialogueHeadingFontWeight: 600,
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Asap',
                    dialogueTextFontSize: '14px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '10px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You have not been age verified yet and will not have access to tobacco offers.',
                    exitDialogWidth: '343px',
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                service: 'DCAMSPLUS5.0.Enhanced',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: false,
                captureTimeout: 20,
                introNotification: true,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: 'Click here.',
                    },
                },

                theme: {
                    primaryColor: '#CE0E2D',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#CE0E2D',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Asap',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.125rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Lato',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Fira Sans',
                    textSubFontSize: '13px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '20px',
                    textSubcolor: '#4C4C4C',
                    textDangerColor: '#CE0E2D',
                    notificationBarFontWeight: 600, // bannerText inherits textSub properties
                    boldSpans: true, // emphasized help text
                    buttonTextFontFamily: 'Asap',
                    buttonTextFontSize: '17px',
                    buttonTextFontWeight: 600,
                    buttonTextLineHeight: '20px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '#F21740', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // '#F21740', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // '#CE0E2D82', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonRadius: 'xl', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    buttonHeight: '44px',
                    radioButtonUncheckedBorderColor: '#C0C0C0',
                    radioButtonCheckedBackgroundImage: `url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='13' fill='%2374AA50'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M13.046 16.34l-1.92 1.86-1.92-1.86-3.14-3.045 1.92-1.86 3.14 3.043L18.015 7.8l1.918 1.86-6.887 6.68z' clip-rule='evenodd'/%3E%3C/svg%3E" )`,
                    radioButtonCheckedBorderColor: '#75AA4F',
                    alertTextColor: '#007298',
                    alertBackgroundColor: '#0072981a',
                    anchorTextFontFamily: 'Asap',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '16px',
                    anchorTextColor: '', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    inputTextFontFamily: 'Asap',
                    inputTextFontSize: '16px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '22px',
                    inputTextColor: '#4C4C4C', // '#000', // #4A4A4A // defaults to primaryColor
                    inputLabelTextFontSize: '12px', // inputLabel shares inputText family, weight, & color
                    inputLabelTextLineHeight: '14px', // inputLabel shares inputText family, weight, & color
                    dialogueHeadingFontFamily: 'Asap',
                    dialogueHeadingFontWeight: 600,
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Asap',
                    dialogueTextFontSize: '14px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '10px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure?',
                    exitDialogMessage:
                        'If you do not verify your age now then you will not receive age-restricted promotions. You can always complete age verification in your profile later on.',
                    exitDialogWidth: '343px',
                },
            },
        },
    },

    /** ****************** RJ full journey */
    '21-073e63cd-6927-4ad4-a3fa-b041e3c8090a': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // TEMP DEV ONLY REVERT TO ABOVE
        // journey: ['phonematch_21', 'dcams_21'],

        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: 'https://www.camel.com/footer-links/contact-us',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        // retryTitlePartial: "Let's try again",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        // retrySubTitlePartial: 'We almost have the information we need. Please ...',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** NRS full journey 1 (no longer in use) */
    '1990-97a5a0af-8384-46df-8bd3-d960a586e83f': {
        journey: ['kba_1990'],
        // journey: [ 'phonematch_21', ],
        // TODO: Migrate Mitek manualHints and autoHints to journey config?
        notificationType: 'notificationBar', // 'inlineFlash',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: false,
        messageHandler: 'ReactNativeWebView',

        displayResultFooter: true,

        contactCustomerServiceUrl: '',
        logoName: 'nrs',

        services: {
            kba: {
                service: 'AgeMatch5.0.KBA',
                rules: {
                    // currently some services may specify multiple rulesets
                    // the default is the "ruleset" key. See dcams below.
                    ruleset: 'AgeMatch5_0_KBA_RuleSet',
                },
                // TODO: add this property for other services?
                displayLastStopResultButtons: true,
                text: {
                    resultActionButton: {
                        pass: 'Continue',
                        fail: "Let's Try Photo ID Scan",
                        failLastStop: 'Close',
                    },
                },
                theme: {
                    primaryColor: '#E3222F',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#E3222F',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Asap',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.125rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Lato',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Fira Sans',
                    textSubFontSize: '13px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '20px',
                    textSubcolor: '#4C4C4C',
                    textDangerColor: '#CE0E2D',
                    notificationBarFontWeight: 600, // bannerText inherits textSub properties
                    boldSpans: true, // emphasized help text
                    buttonTextFontFamily: 'Asap',
                    buttonTextFontSize: '17px',
                    buttonTextFontWeight: 600,
                    buttonTextLineHeight: '20px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '#E3222F', // defaults to primaryColor
                    buttonBackgroundActiveColor: '#E3222F', // '#F21740', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonRadius: 'xl', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    buttonHeight: '44px',
                    radioButtonUncheckedBorderColor: '#C0C0C0',
                    radioButtonCheckedBackgroundImage: `url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 26 26'%3E%3Ccircle cx='13' cy='13' r='13' fill='%2374AA50'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M13.046 16.34l-1.92 1.86-1.92-1.86-3.14-3.045 1.92-1.86 3.14 3.043L18.015 7.8l1.918 1.86-6.887 6.68z' clip-rule='evenodd'/%3E%3C/svg%3E" )`,
                    radioButtonCheckedBorderColor: '#75AA4F',
                    alertTextColor: '#007298',
                    alertBackgroundColor: '#0072981a',
                    anchorTextFontFamily: 'Asap',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '16px',
                    anchorTextColor: '', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    inputTextFontFamily: 'Asap',
                    inputTextFontSize: '16px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '22px',
                    inputTextColor: '#4C4C4C', // '#000', // #4A4A4A // defaults to primaryColor
                    inputLabelTextFontSize: '12px', // inputLabel shares inputText family, weight, & color
                    inputLabelTextLineHeight: '14px', // inputLabel shares inputText family, weight, & color
                    dialogueHeadingFontFamily: 'Asap',
                    dialogueHeadingFontWeight: 600,
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Asap',
                    dialogueTextFontSize: '14px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You have not been age verified yet and will not have access to tobacco offers.',
                    exitDialogWidth: '343px',
                },
            },
        },
    },

    /** ****************** NRS full journey 2 CURRENT for ws@idt.com and ws_testing@idt.com */
    '1990-778f519d-b0f3-4429-a837-81884c586eee': {
        // journey: [ 'kba_1990', ],
        journey: ['phonematch_21'],
        // TODO: Migrate Mitek manualHints and autoHints to journey config?
        notificationType: 'notificationBar', // 'inlineFlash',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: false,
        messageHandler: 'ReactNativeWebView',

        displayResultFooter: true,

        contactCustomerServiceUrl: '',
        logoName: 'nrs',

        services: {
            phonematch: {
                services: {
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: true,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Close',
                        fail: 'Close',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to National Retail Solutions and/or its affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) React Native mobile full journey */
    '21-4ea8651d-973a-4264-b692-751a97b98049': {
        // TODO: Migrate Mitek manualHints and autoHints to journey config
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'ReactNativeWebView',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: 'https://www.camel.com/footer-links/contact-us',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) Web full journey */
    '21-93a7cbed-1ac4-4353-b33b-6497d5749599': {
        // TODO: Migrate Mitek manualHints and autoHints to journey config
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'window_top',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: 'https://www.camel.com/footer-links/contact-us',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                checkParams: false,
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_reactnative_pre-production@rjrt.com */
    /** ****************** site_name 01c740cb-e216-4cd2-8167-7e6bef7a399d */
    '21-eb6f5e2d-11b1-4ac4-8c68-ed5ad667bbb2': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'ReactNativeWebView',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                checkParams: false,
                text: {
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_web_pre-production@rjrt.com */
    /** ****************** site_name 96e69daa-9770-4334-bd37-12e7c087713e */
    '21-b7f1c68a-5817-45ba-a36c-466b1bca013e': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'window_top',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                checkParams: false,
                text: {
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_reactnative_production@rjrt.com */
    /** ****************** site_name 1c5757d4-46e9-42a6-be06-e2828c6af560 */
    '21-c10bc86b-2e23-4d3e-9d81-5dff6b7b7dc5': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'ReactNativeWebView',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                checkParams: false,
                text: {
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Alto (RJ) 2024-02-27 alto_activation_vx_web_production@rjrt.com */
    /** ****************** site_name 00b6fe60-e9b8-4282-bad6-99be65604af6 */
    '21-f517c5b2-bb80-4403-bf0e-e34c149b11d9': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: true,
        messageHandler: 'window_top',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                checkParams: false,
                text: {
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // Still required for status polling
                // TODO: move service name retrieval to backend status poller
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** Fuel Cycle full journey */
    '1995-f7c838f8-88b8-4295-983f-dd77e7423609': {
        journey: ['phonematch_1995'],
        // TODO: Migrate Mitek manualHints and autoHints to journey config?
        notificationType: 'notificationBar', // 'inlineFlash',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: true,
        useEventCallbacks: false,
        messageHandler: 'ReactNativeWebView',

        displayResultFooter: true,

        contactCustomerServiceUrl: '',
        logoName: 'fuelcycle',

        services: {
            phonematch: {
                services: {
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: true,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Close',
                        fail: 'Close',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to National Retail Solutions and/or its affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ****************** GAVS_VX_MASTER_TESTING@pmi.com PMI full journey */
    '1956-468d85c8-4886-4496-ae96-3c82320f25b5': {
        journey: ['phonematch_1956', 'ssn_1956', 'dcams_1956'],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: false,
        displayResultFooter: false,

        contactCustomerServiceUrl: 'https://www.camel.com/footer-links/contact-us',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    carrierVerify:
                        process.env.NODE_ENV === 'production'
                            ? 'PhoneMatch5.0.Smart2FA.Auth'
                            : 'PhoneMatch5.0.Smart2FA.Auth',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsTitle: 'Terms and Conditions',
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to Phillip Morris International for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            ssn: {
                service: 'AgeMatch5.0',

                ssnOptional: true,
                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: false,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                service: 'DCAMSPLUS5.0.Enhanced.OCR',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    primaryColor: '#238D80',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */

    /** ****************** RJ brands 09-22-2023 */
    /** See /srv/vx/deployment/vxle-react.refactor.2022-11-15/src/state/_rj_customs_OLD.js */
    /*
    american_spirit_vx_prod
    american_spirit_vx_qa
    camel_snus_vx_prod
    camel_snus_vx_qa
    cougar_vx_prod
    cougar_vx_qa
    kodiak_vx_prod
    kodiak_vx_qa
    lucky_strike_vx_prod
    lucky_strike_vx_qa
    levi_garrett_vx_prod
    levi_garrett_vx_qa
    */

    /** ****************** RJ brands 10-31-2023 */
    /*
    ------- styles provided -------
    vx_camel_dev@rjrt.com
    vx_camel_qa@rjrt.com
    vx_camel_uat@rjrt.com
    vx_camel_production@rjrt.com
    camel_snus_vx_prod@rjrt.com
    camel_snus_vx_qa@rjrt.com

    cougar_vx_prod@rjrt.com
    cougar_vx_qa@rjrt.com

    vx_grizzly_preproduction@rjrt.com
    vx_grizzly_production@rjrt.com

    kodiak_vx_prod@rjrt.com
    kodiak_vx_qa@rjrt.com

    lucky_strike_vx_prod@rjrt.com
    lucky_strike_vx_qa@rjrt.com

    vx_newport_preproduction@rjrt.com
    vx_newport_production@rjrt.com

    vx_pallmall_preproduction@rjrt.com
    vx_pallmall_production@rjrt.com

    vx_velo_dev@rjrt.com
    vx_velo_production@rjrt.com
    vx_velo_production@rjrt.com

    vx_vuse_pre_production@rjrt.com
    vx_vuse_production@rjrt.com

    ------- "nas" is "North American Spirit" -------
    american_spirit_vx_prod@rjrt.com
    american_spirit_vx_qa@rjrt.com

    ------- no styles provided -------
    levi_garrett_vx_prod@rjrt.com
    levi_garrett_vx_qa@rjrt.com

    // =================================================================================
    /* These branded configs are copies of the RJ full journey with style overrides added for each brand */
    // =================================================================================

    /* vx_camel_dev@rjrt.com, vx_camel_qa@rjrt.com, vx_camel_uat@rjrt.com, vx_camel_production@rjrt.com, camel_snus_vx_prod@rjrt.com, camel_snus_vx_qa@rjrt.com */
    '21-8dca8e1e-610f-438d-a1b0-4f50efe27765': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        abTitle: "Oops! Let's try another way",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        abSubTitle: "We were unable to verify you based on your SSN. Let's try using your phone number",
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        abTitle: "Oops! Let's try another way",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        abSubTitle: "We were unable to verify you based on your SSN. Let's try using your phone number",
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'camel',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        abTitle: "Let's verify your identity",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        abSubTitle: 'Please enter or edit the last 4 of your SSN to start the verification process',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'camel',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'camel',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* cougar_vx_prod@rjrt.com, cougar_vx_qa@rjrt.com */
    '21-3bd0455f-e4af-4afe-a7d7-f7690282bbd6': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'cougar',
                    primaryColor: '#bf4514',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'cougar',
                    primaryColor: '#bf4514',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'cougar',
                    primaryColor: '#bf4514',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* vx_grizzly_preproduction@rjrt.com, vx_grizzly_production@rjrt.com */
    '21-8fd86aaf-847a-4bd2-9bf2-b0cef13eb233': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'grizzly',
                    primaryColor: '#be2625',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'grizzly',
                    primaryColor: '#be2625',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'grizzly',
                    primaryColor: '#be2625',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* kodiak_vx_prod@rjrt.com, kodiak_vx_qa@rjrt.com */
    '21-6e4364af-6e0e-479a-b319-e95fcc41d04f': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'kodiak',
                    primaryColor: '#343434',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'kodiak',
                    primaryColor: '#343434',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'kodiak',
                    primaryColor: '#343434',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* lucky_strike_vx_prod@rjrt.com, lucky_strike_vx_qa@rjrt.com */
    '21-0ad07db5-ebff-4107-9571-9693873fccf2': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'luckystrike',
                    primaryColor: '#d01c32',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'luckystrike',
                    primaryColor: '#d01c32',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'luckystrike',
                    primaryColor: '#d01c32',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* "nas": american_spirit_vx_prod@rjrt.com, american_spirit_vx_qa@rjrt.com */
    '21-9561087f-94ce-44a1-b001-1ce7a1b86448': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'nas',
                    primaryColor: '#00a6c1',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'nas',
                    primaryColor: '#00a6c1',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'nas',
                    primaryColor: '#00a6c1',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* vx_newport_preproduction@rjrt.com, vx_newport_production@rjrt.com */
    '21-eb336d5c-696f-4353-922c-3eadfed54cc7': {
        // journey: ['phonematch_21', 'ssn_21', 'dcams_21'],

        // -------------------------------------
        journey: ['phonematch_21', 'dcams_21'],
        // -------------------------------------

        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        abTitle: "Oops! Let's try another way",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        abSubTitle: "We were unable to verify you based on your SSN. Let's try using your phone number",
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        abTitle: "Oops! Let's try another way",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        abSubTitle: "We were unable to verify you based on your SSN. Let's try using your phone number",
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'newport',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        abTitle: "Let's verify your identity",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        abSubTitle: 'Please enter or edit the last 4 of your SSN to start the verification process',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'newport',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'newport',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* vx_pallmall_preproduction@rjrt.com, vx_pallmall_production@rjrt.com */
    '21-a0ce4000-05a2-4678-9847-985082362bc9': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'pallmall',
                    primaryColor: '#ae2129',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'pallmall',
                    primaryColor: '#ae2129',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'pallmall',
                    primaryColor: '#ae2129',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* vx_velo_dev@rjrt.com, vx_velo_production@rjrt.com */
    '21-dcda86a9-5777-4cb8-afc0-37af797fc9de': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'velo',
                    primaryColor: '#6ea952',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'velo',
                    primaryColor: '#6ea952',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'velo',
                    primaryColor: '#6ea952',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* vx_vuse_pre_production@rjrt.com, vx_vuse_production@rjrt.com */
    '21-fa10b10f-bd9e-4d7f-ba35-e8864865eb0b': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'vuse',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'vuse',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'vuse',
                    primaryColor: '#000000',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /* levi_garrett_vx_prod@rjrt.com, levi_garrett_vx_qa@rjrt.com */
    '21-19e78102-e1c7-49c5-90c3-293730bc4c7a': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: '',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'levigarrett',
                    primaryColor: '#007224',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'levigarrett',
                    primaryColor: '#007224',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'levigarrett',
                    primaryColor: '#007224',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */

    /** ****************** RJ brands 04-02-2024 */

    /*
    ------- no styles provided -------
    ------- update 2024-06-13: styles are provided -------
    sensavape_vx_prod@rjrt.com
    sensavape_vx_qa@rjrt.com
    */

    /* sensavape_vx_prod@rjrt.com, sensavape_vx_qa@rjrt.com */
    '21-18ab475d-dfc7-4dc1-b787-5da94b54e1ed': {
        journey: ['phonematch_21', 'ssn_21', 'dcams_21'],
        // journey: [ 'ssn_21', 'dcams_21', ],
        // journey: [ 'dcams_21', ],

        notificationType: 'notificationBar',
        notifications: {
            noMatch: "Looks like we couldn't find a match.",
        },
        usePostMessage: false,
        useEventCallbacks: true,
        messageHandler: 'mockPostMessage',

        useOtpBailoutLink: true,
        displayResultFooter: false,

        contactCustomerServiceUrl: 'https://www.camel.com/footer-links/contact-us',
        // logoName: 'rj',
        logoName: 'none',

        services: {
            phonematch: {
                services: {
                    // carrierVerify: 'PhoneMatch5.0.VFP.Test',
                    // TODO: translate this decision to backend hydration of journeys
                    // carrierVerify: process.env.NODE_ENV === 'production'
                    //     ? 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN'
                    //     : 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.Test',
                    carrierVerify: 'PhoneMatch5.0.Smart2FA.Auth.RJRT.CUSTOM.BOUNCER.VPIN',

                    // otp: 'PhoneMatch5.0.Smart2FA.SMS',
                    otp: 'PhoneMatch5.0.Smart2FA.SMS.RJRT.CUSTOM.BOUNCER.VPIN',
                },
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },
                checkParams: false,
                text: {
                    // TODO: Intro screen decision has been changed from original spec.
                    // "desktopVerify", "desktopTitle" etc. is no longer semantically
                    // correct. Perhaps "vfpVerify" and "otpVerify", etc, is
                    // appropriate.
                    mobileVerify: {
                        title: "Let's verify your identity",
                        subTitle: 'Please enter or edit your phone number to start the verification process',
                        explanation: 'We will attempt to verify:',
                        explanation_1: 'Your possesion of the phone either automatically or with a text',
                        explanation_2: 'The number provided matches your identity records',
                        actionButton: 'Verify My Identity',
                    },
                    desktopVerify: {
                        title: "Let's verify your identity",
                        subTitle:
                            'In order to verify your identity please enter your phone number. We will then send you a one time code via SMS.',
                        inputLabel: 'Phone Number for One Time Code & Verification',
                        actionButton: 'Send Me a Code',
                    },
                    otp: {
                        title: 'Enter Code',
                        subTitle: 'Please enter the one time code that was sent to',
                        inputLabel: 'One Time Code',
                        anchorButtonInitial: 'Edit Number / Resend Code',
                        anchorButtonReattempt: 'Did not receive code',
                        actionButton: 'Verify My Identity',
                    },
                    resendOtp: {
                        title: 'Resend Code',
                        subTitle:
                            'Please verify your phone number below is correct and make any edits before clicking send me a new code.',
                        inputLabel: 'Phone Number',
                        actionButton: 'Send Me a New Code',
                    },
                    // desktopOtp: {
                    //     title: 'Enter Code',
                    //     subTitle: 'Please enter the one time code that was sent to',
                    //     inputLabel: 'One Time Code',
                    //     actionButton: 'Verify My Identity',
                    // },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                    otpTitle: 'Texting you a code', // this seems to fit better for both 1st send (where user may not know what "the code" is, and resend (where user understands))
                },
                theme: {
                    styleOverride: 'sensavape',
                    primaryColor: '#3f2b64',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.625rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#54C4C4C4',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 500,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',

                    termsDialogWidth: '348px',
                    termsTitle: 'Terms and Conditions',
                    inlineTerms: true,
                    otpAgreement: true,
                    termsMessage:
                        'You authorize your wireless carrier to use or disclose information about your account and your wireless device, if available, to R.J. Reynolds Tobacco Co., R.J. Reynolds Vapor Company, RJR Vapor Co., LLC, American Snuff Co., Modoral Brands Inc., Santa Fe Natural Tobacco Co. and/or their affiliates or its service provider for the duration of your business relationship, solely to help them identify you or your wireless device and to prevent fraud. ',
                    otpAgreementMessage:
                        'I agree to receive an automated, one-time SMS message to this phone from Reynolds for age verification purposes. Data and message rates may apply. Consent not condition for purchase. Reply Stop to end.',
                },
            },

            ssn: {
                service: 'IDMatch5.0.RJRT.CUSTOM.BOUNCER.VPIN',
                // rules: {
                //     // currently some services may specify multiple rulesets
                //     // the default is the "ruleset" key. See dcams below.
                //     ruleset: '',
                // },

                text: {
                    verify: {
                        title: "Oops! Let's try another way",
                        // retryTitlePartial: "Let's try again",
                        subTitle:
                            'We were unable to verify you based on your phone number. Let’s try using the last 4 digits of your SSN.',
                        // retrySubTitlePartial: 'We almost have the information we need. Please ...',
                        alert: 'Using your legal name and accurate details gives you the best chance for success',
                        actionButton: 'Verify Me',
                    },
                    resultActionButton: {
                        pass: 'Go to Login',
                        fail: 'FAIL PLACEHOLDER',
                    },
                    footerLink: {
                        message: 'I would rather verify with an ID Document.',
                        link: 'Click here.',
                    },
                    verifyingTitle: 'Verification in progress',
                },
                theme: {
                    styleOverride: 'sensavape',
                    primaryColor: '#3f2b64',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 600, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    // actionButtonFullWidth: false,
                    actionButtonFullWidth: true,
                    buttonWidth: '95%', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    // TODO: move to text key
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },

            dcams: {
                // The service and rules keys are not used here. They are not
                // part of the dcamsVerifyCaptureScan payload.
                // service: 'DCAMSPLUS5.0.Enhanced',
                // service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM',
                service: 'DCAMSPLUS5.0.Enhanced.RJRT.CUSTOM.VPIN',
                rules: {
                    ruleset: 'DCAMS5_0_RuleSet_NAME_DOB',
                    dcams_manual_rules: 'DCAMS5_0_Manual_Review_RuleSet_NAME_DOB',
                },
                // TODO: add dcams_settings.dcams_review value to client settings
                reviewEnabled: true,
                captureTimeout: 20,
                introNotification: false,

                text: {
                    mobileIntro: {
                        title: 'More Details Required',
                        subTitle:
                            'So far, we are unable to verify your identity. However, there is one more option which is to provide a valid government issued ID.',
                        actionButton: 'Scan my License or ID',
                        secondaryButton: 'Scan my Passport',
                    },
                    desktopIntro: {
                        title: 'Choose a Photo ID Scan Method',
                        subTitle: 'This process is much easier on a mobile device',
                        explanation:
                            'If you select the phone option, you will continue this process on your phone. Or you can upload an image file from this device.',
                        actionButton: 'Use My Phone',
                        secondaryButton: 'Upload a File',
                    },
                    underReview: {
                        title: 'Account Under Review',
                        subTitle:
                            'We were unable to verify your identity through our automated processes. One of our agents is currently reviewing your submitted ID document.',
                        explanation: 'What you should know:',
                        explanation_1: 'The review process can take up to 10 minutes.',
                        explanation_2: 'You will receive an email once the review process is completed.',
                        explanation_3: 'You can contact Consumer Relations at any time for help.',
                        actionButton: 'Contact Consumer Relations',
                        secondaryButton: 'Close',
                    },
                    footerLink: {
                        message: '',
                        link: '',
                    },
                },

                theme: {
                    styleOverride: 'sensavape',
                    primaryColor: '#3f2b64',
                    primaryBackground: 'white',
                    secondaryColor: 'white',
                    secondaryBackground: '#238D80',
                    // at present this requires woff2 resources for each desired family and weight; see customFonts.js
                    headingsFontFamily: 'Poppins',
                    headingsFontWeight: 700,
                    h1FontSize: '1.5rem',
                    h1LineHeight: '2.5rem',
                    h1FontColor: '#212721',
                    h2FontSize: '1.25rem',
                    h2LineHeight: '1.625rem',
                    h2FontColor: '#212721',
                    textNormalFontFamily: 'Poppins',
                    textNormalFontSize: '14px',
                    textNormalFontWeight: 400,
                    textNormalLineHeight: '20px',
                    textNormalColor: '#212721',
                    textSubFontFamily: 'Poppins',
                    textSubFontSize: '10px',
                    textSubFontWeight: 400,
                    textSubLineHeight: '25px',
                    textSubcolor: '#616060',
                    textDangerColor: '#CC0B2F',
                    notificationBarFontWeight: 700, // notification bar text inherits textSub properties
                    boldSpans: false, // emphasized help text
                    buttonTextFontFamily: 'Poppins',
                    buttonTextFontSize: '14px',
                    buttonTextFontWeight: 700,
                    buttonTextLineHeight: '21px',
                    buttonBackgroundColor: '', // defaults to primaryColor
                    buttonBackgroundHoverColor: '', // defaults to primaryColor
                    buttonBackgroundActiveColor: '', // defaults to primaryColor
                    buttonBackgroundDisabledColor: '#D0D0D0', // defaults to primaryColor
                    actionButtonFullWidth: true,
                    buttonWidth: '', // exlusive of actionButtonFullWidth
                    buttonHeight: '49px',
                    buttonRadius: '10px', // number | "xs" | "sm" | "md" | "lg" | "xl"
                    alertTextColor: '#212721',
                    alertBackgroundColor: '#c4c4c454',
                    anchorTextFontFamily: 'Poppins',
                    anchorTextFontSize: '14px',
                    anchorTextFontWeight: 400,
                    anchorTextLineHeight: '25px',
                    anchorTextColor: '#CAC7C7', // defaults to primaryColor
                    anchorTextAttentionColor: '#D22147',
                    footerLinkTextFontFamily: 'Poppins',
                    footerLinkTextFontSize: '13px',
                    footerLinkTextFontWeight: 600,
                    footerLinkTextLineHeight: '20px',
                    footerLinkTextColor: '#939191',
                    inputTextFontFamily: 'Poppins',
                    inputTextFontSize: '14px',
                    inputTextFontWeight: 400,
                    inputTextLineHeight: '21px',
                    inputTextColor: '#828282',
                    inputHeight: '50px',
                    inputLabelTextFontSize: '14px',
                    inputLabelTextFontWeight: 300,
                    inputLabelTextLineHeight: '21px',
                    inputLabelTextColor: '#333333',
                    inputRadius: '8px',
                    dialogueHeadingFontFamily: 'Poppins',
                    dialogueHeadingFontWeight: 700,
                    dialogueHeadingFontSize: '15px',
                    dialogueHeadingFontColor: '#212721',
                    dialogueTextFontFamily: 'Poppins',
                    dialogueTextFontSize: '12px',
                    dialogueTextFontWeight: 300,
                    dialogueTextLineHeight: '25px',
                    dialogueTextColor: '#4C4C4C',
                    dialogueBorderRadius: '2px',
                    dialogueTitle: '',
                    dialogueWidth: '348px',
                    exitDialogTitle: 'Are you sure you want to leave?',
                    exitDialogMessage: 'You must complete this process for verification purposes.',
                    exitDialogWidth: '343px',
                    termsDialogWidth: '348px',
                    inlineTerms: true,
                },
            },
        },
    },

    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */
    /** ********************************************************************************************* */

    default: {
        journey: ['dcams_1'],
        services: {
            kba: {
                data: {}, // placeholder
                theme: {},
            },
        },
    },
}
