/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

import { logger, logJson, logJsonProduction, passSideEffectMessage } from '../utilities/helpers'

const pMessage = {
    status: '',
    component: '',
    error: '',
    event: '',
}
/* pMessage options
{
    "status": "PASS | PENDING | FAIL",
    "component": "KBA | DCAMS | PHONEMATCH | AGEMATCH | IDMATCH | INIT", 
    "error": "ERROR | VELOCITY | BAD TOKEN | ALREADY VERIFIED | PENDING",
    "event": "REATTEMPT | NEXT | CLOSE | EMAIL | QR"
}
*/

const blockedErrorState = {
    errorResponse: {
        component: '',
        blockedError: {
            errorDetail: '',
            errorMessage: '',
            // errorDescription: '',
        },
    },
}

const blockedErrorSlice = createSlice({
    name: 'error',
    initialState: blockedErrorState,

    reducers: {},

    extraReducers: builder => {
        builder
            .addCase('setBlockedError', (state, action) => {
                passSideEffectMessage({
                    ...pMessage,
                    status: 'FAIL',
                    error: 'ERROR',
                    component: action.payload.component,
                })

                state.errorResponse = action.payload
            })
            .addCase('clearBlockedError', (state, action) => {
                state.errorResponse = {
                    errorDetail: '',
                    errorMessage: '',
                    // errorDescription: '',
                }
            })
    },
})

export default blockedErrorSlice.reducer
