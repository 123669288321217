// Add components here that have custom versions under journeyStops/<stop>/<company_id/*/*/*
// NOTE: active companies must have an entry here with at least an empty array
// TODO: handle missing entries

export const componentMap = {
    21: [],
    1985: [ 'ScanIntroMobile', 'ScanIntroDesktop', 'DcamsFooter', ],
    1990: [],
    1995: [],
    1956: [],
}
