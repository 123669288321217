/* eslint-disable no-unused-vars */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { isIOS, browserName } from 'react-device-detect'

import { dataService } from '../services/dataService'
import { storeInitialPayload, getInitialPayload } from './initialPayload'
import { kbaUpdatePayloadRequestIds } from './kba.slice'

import { bouncerMessages } from './messages'
import { bouncerMatchFailure } from './actions'

import { updateStyles } from '../utilities/styles'

import { logger, logJson, logJsonProduction, passSideEffectMessage } from '../utilities/helpers'

const initState = {
    clientData: {},
    clientSettings: {},

    missingParams: [],

    initialized: false,
    initLoading: true,
    initError: false,
    errorDetail: '',
    errorMessage: '',
    errorDescription: '',
}

export const blockCheck = createAsyncThunk('init/blockCheck', async (payload, thunkAPI) => {
    try {
        const response = await dataService.blockCheck(payload)

        // if (response.action === 'ERROR') {
        //     return thunkAPI.rejectWithValue(response)
        // } else {
        //     return response
        // }

        return response
    } catch (error) {
        return thunkAPI.rejectWithValue({
            action: 'ERROR',
            detail: 'UNKNOWN',
            errorMessage: 'Unknown error occurred',
            errorDescription: 'An unexpected error occurred. Please try again later.',
        })
    }
})

export const fetchInitialState = createAsyncThunk(
    'init/fetchInitialState', // action type primitive used to generate new action type constants (needn't be named the same as the thunk action creator)
    // eslint-disable-next-line no-unused-vars
    async (requestIdPayload, thunkAPI) => {
        //
        // thunkAPI: https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
        //
        const _payload = {
            ...requestIdPayload,
            log_data: isIOS || browserName.toLowerCase().includes('safari') ? 'ios_safari' : 'not_ios_safari',
        }

        try {
            const response = await dataService.fetchInitial(_payload)

            if (response.action === 'ERROR') {
                thunkAPI.dispatch(registerFailure(response))
                // console.log('Rejecting with value:', response)
                return thunkAPI.rejectWithValue(response)
            } else {
                thunkAPI.dispatch(kbaUpdatePayloadRequestIds(response.data.request_id))
                return response
            }
        } catch (error) {
            // console.error('fetchInitialState error:', error)
            return thunkAPI.rejectWithValue({
                action: 'ERROR',
                detail: 'UNKNOWN',
                errorMessage: 'Unknown error occurred',
                errorDescription: 'An unexpected error occurred. Please try again later.',
            })
        }

        // // thunkAPI.dispatch( kbaUpdateTryAgainRequestId( response.data.request_id ))
        // thunkAPI.dispatch(kbaUpdatePayloadRequestIds(response.data.request_id))
        // return response
    },
)
/*
createAsyncThunk returns four generated functions:
fetchInitialState: thunk action creator that kicks off the async payload callback
fetchInitialState.pending: action creator that dispatches the 'init/fetchInitialState/pending' action
fetchInitialState.fulfilled, action creator that dispatches the 'init/fetchInitialState/fulfilled' action
fetchInitialState.rejected, action creator that dispatches the 'init/fetchInitialState/rejected' action
*/

// TODO: move to actions module and test
// "Redux action types are not meant to be exclusive to a single slice"
// https://redux-toolkit.js.org/usage/usage-guide#exporting-and-using-slices
// https://medium.com/visual-development/how-to-fix-nasty-circular-dependency-issues-once-and-for-all-in-javascript-typescript-a04c987cf0de
export const badNavigation = createAction('navigate/badNavigation')
export const registerFailure = createAction('register/failure')

export const setClientDataType = createAction('setData/clientDataType')
export const setClientDataDocType = createAction('setData/clientDataDocType')
export const setClientDataRequestId = createAction('setData/clientDataRequestId')
export const setClientDataCountry = createAction('setData/clientDataCountry')
export const setClientDataFrontExt = createAction('setData/clientDataFrontExt')
export const setClientDataBackExt = createAction('setData/clientDataBackExt')
export const setClientDataDocumentTypeSeen = createAction('setData/clientDataDocumentTypeSeen')

// define missing required params
export const updateMissingParams = createAction('updateMissingParams')

// collect missing data
export const updateClientDataPii = createAction('setData/updateClientDataPii')

// add a &journey_stop= param for email handoff
export const setJourneyParamsForEmail = createAction('setJourneyParamsForEmail')

export const resetWithInitialPayload = createAction('resetWithInitialPayload')

const pMessage = {
    status: '',
    component: '',
    error: '',
    event: '',
}

const initSlice = createSlice({
    name: 'init',
    initialState: initState,

    reducers: {},

    extraReducers: builder => {
        // Add reducers for additional action types, handle loading state as needed

        // store updating is a wrapped immer, so we're working on a *draft* state.
        // immer will tranlate any state mutation to immutable-safe state updates.
        // only rule: either mutate draftState or return a new state, not both.
        builder

            .addCase(badNavigation, draftState => {
                passSideEffectMessage({
                    ...pMessage,
                    error: 'ERROR',
                    component: 'INIT',
                })

                return {
                    ...draftState,
                    initLoading: false,
                    initError: true,
                    errorMessage: 'You Do Not Have Access',
                    errorDescription: 'You have navigated to an unkown page. Please contact customer service.',
                }
            })
            .addCase(registerFailure, (draftState, action) => {
                const { detail, errorMessage, errorDescription } = action.payload
                passSideEffectMessage({
                    ...pMessage,
                    error: detail,
                    component: 'INIT',
                })

                return {
                    ...draftState,
                    initLoading: false,
                    initError: true,
                    errorDetail: detail,
                    errorMessage,
                    errorDescription,
                }
            })
            .addCase(bouncerMatchFailure, (draftState, action) => {
                const { detail } = action.payload

                logger('[DETAIL]', detail)
                logJson('bouncer message at detail', bouncerMessages[detail])

                const { callbackEvent } = bouncerMessages[detail]
                passSideEffectMessage({ callbackEvent })
                return {
                    ...draftState,
                    initLoading: false,
                    initError: true,
                    errorDetail: detail,
                    errorMessage: bouncerMessages[detail].errorMessage,
                    errorDescription: bouncerMessages[detail].errorDescription,
                }
            })
            .addCase(blockCheck.fulfilled, (draftState, action) => {
                if (action.payload.action === 'ERROR') {
                    const { detail, errorMessage, errorDescription } = action.payload
                    passSideEffectMessage({
                        ...pMessage,
                        error: detail,
                        component: 'INIT',
                    })

                    return {
                        ...draftState,
                        initLoading: false,
                        initError: true,
                        errorDetail: detail,
                        errorMessage,
                        errorDescription,
                    }
                }
            })
            .addCase(blockCheck.rejected, (draftState, action) => {
                if (action.payload) {
                    return {
                        ...draftState,
                        initLoading: false,
                        initError: true,
                        errorMessage: action.payload.errorMessage || 'Something Went Wrong',
                        errorDescription:
                            action.payload.errorDescription ||
                            'There was a system error. Please contact customer service.',
                    }
                } else {
                    draftState.errorMessage = 'Something Went Wrong'
                    draftState.errorDescription = 'There was a system error. Please contact customer service.'
                }
            })
            .addCase(fetchInitialState.fulfilled, (draftState, action) => {
                const { is_expended_id } = action.payload
                if (is_expended_id) {
                    passSideEffectMessage({
                        ...pMessage,
                        error: 'ERROR',
                        component: 'INIT',
                    })

                    return {
                        ...draftState,
                        initLoading: false,
                        initError: true,
                        errorMessage: 'You have already used this link.',
                        errorDescription: 'Please generate a new link or contact customer service.',
                    }
                }

                storeInitialPayload(action.payload.data)

                const { settings, ...restData } = action.payload.data

                updateStyles(settings?.style[0].settings)

                // const _logJson = ( description, json ) => console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #bada55' )
                // _logJson( 'client data', restData )
                // _logJson( 'client settings', settings )

                logJson('client settings', settings)

                return {
                    ...draftState,
                    initialized: true,

                    clientData: restData,
                    clientSettings: settings,

                    initLoading: false,
                    initError: false,
                }
            })
            .addCase(fetchInitialState.rejected, (draftState, action) => {
                if (action.payload) {
                    return {
                        ...draftState,
                        initLoading: false,
                        initError: true,
                        errorMessage: action.payload.errorMessage || 'Something Went Wrong',
                        errorDescription:
                            action.payload.errorDescription ||
                            'There was a system error. Please contact customer service.',
                    }
                } else {
                    draftState.errorMessage = 'Something Went Wrong'
                    draftState.errorDescription = 'There was a system error. Please contact customer service.'
                }

                // return {
                //     ...draftState,
                //     initLoading: false,
                //     initError: true,
                //     errorMessage: 'Something Went Wrong',
                //     errorDescription: 'There was a system error. Please contact customer service.',
                // }
            })
            .addCase(setClientDataType, (draftState, action) => {
                draftState.clientData.type = action.payload
            })
            .addCase(setClientDataRequestId, (draftState, action) => {
                draftState.clientData.request_id = action.payload
            })
            .addCase(setClientDataCountry, (draftState, action) => {
                draftState.clientData.country = action.payload
            })
            .addCase(setClientDataFrontExt, (draftState, action) => {
                draftState.clientData.front_ext = action.payload
            })
            .addCase(setClientDataBackExt, (draftState, action) => {
                draftState.clientData.back_ext = action.payload
            })
            .addCase(setClientDataDocumentTypeSeen, (draftState, action) => {
                draftState.clientData.isDocumentTypeSeen = action.payload
            })
            // See functions.php in the backend
            .addCase(setClientDataDocType, (draftState, action) => {
                draftState.clientData.doc_type = action.payload
            })

            .addCase(updateMissingParams, (draftState, action) => {
                draftState.missingParams = action.payload
            })

            .addCase(updateClientDataPii, (draftState, action) => {
                return {
                    ...draftState,
                    clientData: {
                        ...draftState.clientData,
                        ...action.payload,
                    },
                }
            })

            .addCase(setJourneyParamsForEmail, (draftState, action) => {
                draftState.clientSettings.journey_id = action.payload.journeyId
                draftState.clientSettings.device_handoff = action.payload.deviceHandoff
            })

            // not in use but available
            // storeInitialPayload() is called in fetchInitialState.fulfilled
            .addCase(resetWithInitialPayload, draftState => {
                const payload = getInitialPayload()
                const { settings, ...restData } = payload
                return {
                    ...draftState,
                    initLoading: false,
                    initError: false,
                    clientData: restData,
                    clientSettings: settings,
                }
            })

            /*  // debugging
            .addCase( 'persist/REHYDRATE', ( draftState, action ) => {
                logger( 'PERSIST::DRAFTSTATE', draftState )
                logger( 'PERSIST::ACTION', action )
                logger( 'CAUGHT REHYDRATE' )
            } )
        */

            /*
            // You can chain calls or add multiple `builder.addCase()` calls
            .addCase()
            // You can apply a "matcher function" to incoming actions
            .addMatcher( isActionWithNumberPayload, ( state, action ) => {} )
            // and provide a default case if no other handlers matched
            */
            .addDefaultCase(draftState => {
                return { ...draftState }
            })
    },
})

export default initSlice.reducer
