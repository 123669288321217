import { createSlice } from '@reduxjs/toolkit'

/** ***************************************************************** */
// TODO: migrate and generalize this state to notifySlice. These legacy
// error states are limited to dcams upload/Error, upload/Header,
// and upload/UploadIndex.
/** ***************************************************************** */

const errorState = {
    errorCount: 0,
    error: false,
    errorTitle: 'Error',
    errorSubTitle: 'Something went wrong. Please try again.',
}


const errorSlice = createSlice( {
    name: 'error',
    initialState: errorState,

    reducers: {
        updateErrorState: ( draftState, action ) => {
            return {
                ...draftState,
                ...action.payload,
            }
        },
        incrementErrorCount: ( draftState, action ) => {
            draftState.errorCount += action.payload
        },
    },
} )

export const {
    updateErrorState,
    incrementErrorCount,
} = errorSlice.actions

export default errorSlice.reducer

