import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
    persistReducer,
    persistStore,
    // non-serializable data and redux-persist:
    // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
// import expireReducer from 'redux-persist-expire'

import initReducer from './state/init.slice'
import journeyReducer from './state/journey.slice'
import uiReducer from './state/ui.slice'
import notifyReducer from './state/notify.slice'
import navigationReducer from './state/navigation.slice'

import kbaReducer from './state/kba.slice'
import phonematchReducer from './state/phonematch.slice'
import ssnReducer from './state/ssn.slice'

import emailSenderReducer from './state/dcams/emailSender.slice'
import dcamsCaptureReducer from './state/dcams/dcamsCapture.slice'
import dcamsVerifyCaptureReducer from './state/dcams/dcamsVerify.slice'
import errorReducer from './state/dcams/error.slice'
import closeReducer from './state/dcams/close.slice'

import deviceDetectReducer from './state/deviceDetect.slice'

import blockedErrorReducer from './state/blockedError.slice'

const reducers = {
    init: initReducer,
    journey: journeyReducer,
    ui: uiReducer,
    notify: notifyReducer,
    navigation: navigationReducer,

    kba: kbaReducer,
    phonematch: phonematchReducer,
    ssn: ssnReducer,

    // TODO: some dcams state references should be renamed
    emailSender: emailSenderReducer,
    dcamsCapture: dcamsCaptureReducer,
    dcamsVerifyCapture: dcamsVerifyCaptureReducer,
    error: errorReducer,
    close: closeReducer,

    deviceDetect: deviceDetectReducer,

    blockedError: blockedErrorReducer,
}

const persistConfig = {
    key: 'root',
    // storage,
    storage: storageSession,
    stateReconciler: hardSet,
    // transforms: [
    //     expireReducer( 'init', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'journey', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'kba', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'emailSender', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'dcamsCapture', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'dcamsVerifyCapture', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'error', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    //     expireReducer( 'close', {
    //         expireSeconds: 30,
    //         autoExpire: true,
    //         expiredState: {},
    //     } ),
    // ],
}

const rootReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),

    // devTools: process.env.NODE_ENV !== 'production',
    devTools: true,

    // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
})
export const persistor = persistStore(store)

/**
 * TODO: onClose(), onSuccess(), onLeave(), etc
 * Additionally, you can purge any persisted state by adding an extra reducer to
 * the specific slice that you would like to clear when calling
 * persistor.purge(). This is especially helpful when you are looking to clear
 * persisted state on a dispatched logout action.
 *
 * import { PURGE } from "redux-persist";
 *
 * ...
 *
 * extraReducers: (builder) => {
 *     builder.addCase(PURGE, (state) => {
 *         customEntityAdapter.removeAll(state);
 *     });
 * }
 */

/**
 * see also https://redux-toolkit.js.org/api/configureStore#parameters
 *
 * Default middleware:
 *  - Development:
 *     - immutability check
 *       https://redux-toolkit.js.org/api/immutabilityMiddleware
 *     - serializability check
 *       https://redux-toolkit.js.org/api/serializabilityMiddleware
 *     - redux-thunk
 *       https://github.com/reduxjs/redux-thunk
 *  - Production
 *     - redux-thunk
 *
 * Default enhancers:
 *   - middleware
 *   - devtools
 */

/** Concatenating additional middleware modules:
 *
 * Add all default middleware to store, _plus_ the logger middleware:
 *
 * middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
 *
 * It is preferable to use the chainable .concat(...) and .prepend(...) methods
 * of the returned MiddlewareArray instead of the array spread operator, as the
 * latter can lose valuable type information under some circumstances.
 */
