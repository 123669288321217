import {
    useEffect
} from 'react'
import {
    useSelector, useDispatch
} from 'react-redux'

import { updateJourney } from '../state/journey.slice'

import { componentMap } from '../componentMap'

// import { logger } from '../utilities/helpers'


export const useGetHandoff = () => {
    const { journey, } = useSelector( state => state.journey.config )

    const getHandoff = journeyStop => {
        // logger( 'HANDOFF FROM JOURNEY STOP', journeyStop )
        if ( journeyStop ) {
            const idx = journey.indexOf( journeyStop ) + 1
            return journey[ idx ]
        }
        return journey[ 0 ]
    }

    return { getHandoff, }
}

export const useJourneyUpdate = location => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Run for noninitial journey stops
        if ( location.state?.handoff !== undefined ) {
            dispatch( updateJourney( location.state.handoff ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )
}

/**
 * If the componentMap includes non-default components for this company and
 * journey, use the appropriate import paths. This approach depends on
 * './<companyID>/' and './default' directories being at the same level.
 *
 * TODO: test prefetching for select components:
 * https://loadable-components.com/docs/prefetching/
 * https://webpack.js.org/guides/code-splitting/#prefetchingpreloading-modules
 */
export const useGetSource = () => {
    const { companyId, } = useSelector( state => state.journey )
    const getSource = name => ( componentMap[ companyId ].includes( name ) ? companyId : 'default' )
    return { getSource, }
}
