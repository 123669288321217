/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice, // createAction,
} from '@reduxjs/toolkit'
import { dataService } from '../services/dataService'

import { fireJourneyCallback, setJourneyStatus } from './journey.slice'

import { bouncerMessages } from './messages'
import { bouncerMatchFailure } from './actions'

import { logJson, passSideEffectMessage } from '../utilities/helpers'

const logJsonError = (description, json) =>
    console.error(
        `%c [${description.toUpperCase()}] ${JSON.stringify(json, null, 4)}`,
        'background: #3D4047; color: #05F0ED',
    )

const kbaState = {
    // last: false,

    questions: {},
    questionsResult: {
        action: null,
    },

    answersTemplate: {},
    answersResult: {
        action: null,
    },
    url: '',

    moreDetails: {
        payload: {
            request_id: '',
            target: {},
        },
    },
    moreDetailsResult: {
        action: null,
    },

    tryAgain: {
        tries: 0,
        payload: {
            request_id: '',
            target: {},
        },
    },
    tryAgainResult: {
        action: null,
    },

    navigation: {
        questionsLoading: false,
        checkingAnswers: false,
        moreDetailsLoading: false,
        tryAgainLoading: false,
        loadingMessage: 'Verification in progress',

        viewingQuestions: false,
        viewingResult: false,
    },

    fetchQuestionsError: false,
    checkAnswersError: false,
    moreDetailsError: false,
    tryAgainError: false,
    errorCode: null,
    errorMessage: '',
    errorDescription: '',
    questionsTimeout: false,
    rejectionError: {},

    // TODO: move to global ui.slice
    layout: {
        scroll: false,
    },
    constants: {
        vertBreakPoint: 710, // veratad-modal-box kba height === 760. XS window.innerHeight === 637.
    },
}

export const kbaGetQuestions = createAsyncThunk('kba/kbaGetQuestions', async (payload, thunkAPI) => {
    payload.service = thunkAPI.getState().journey.config.services.kba.service
    payload.ruleset = thunkAPI.getState().journey.config.services.kba.rules.ruleset
    console.log('KBA GQ payload', payload)
    const response = await dataService.getKbaQuestions(payload)

    if (Object.keys(bouncerMessages).includes(response?.result?.detail)) {
        thunkAPI.dispatch(bouncerMatchFailure({ detail: response.result.detail }))
    }

    return response

    /** **************************** */
    // eslint-disable-next-line no-promise-executor-return
    // const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
    // await sleep( 500 )
    // return qFailed // <--------------------------------------------
    // return qFulfilled // <--------------------------------------------
    /** **************************** */
})
export const kbaCheckAnswers = createAsyncThunk(
    'kba/kbaCheckAnswers',
    // eslint-disable-next-line no-unused-vars
    async (_payload, thunkAPI) => {
        const answersPayload = thunkAPI.getState().kba.answersTemplate
        const response = await dataService.checkKbaAnswers(answersPayload)

        // logJson( 'checkanswer response', response )

        let status
        const message = {
            status: '',
            component: 'KBA',
            error: '',
            event: '',
        }
        // eslint-disable-next-line no-promise-executor-return
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        if ((response?.result?.action && response?.result?.action !== 'PASS') || response?.error) {
            const { journey } = thunkAPI.getState().journey.config

            // Remove to avoid Wawa tripping on an error value for now
            // if ( response?.error?.code === 'QRY6' ) {
            //     message.error = 'BAD TOKEN'
            // }

            if (journey[journey.length - 1].includes('kba')) {
                // is last stop
                status = 'FAIL' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
                thunkAPI.dispatch(setJourneyStatus(status))
                message.status = status
                // TODO: should this event be added?
                // message.event = 'CLOSE' // TODO: generalize event derivation in the message passer
                passSideEffectMessage(message)
                await sleep(1000)
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch(fireJourneyCallback({ status: 'FAIL' }))
            } else {
                // not last stop
                status = 'PENDING' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
                thunkAPI.dispatch(setJourneyStatus(status))
                message.status = status
                message.event = 'NEXT' // TODO: generalize event derivation in the message passer
                passSideEffectMessage(message) // TODO: we need an event here don't we? i.e. 'NEXT' or ...
                await sleep(1000)
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch(fireJourneyCallback({ status: 'PENDING' }))
            }
        } else {
            status = 'PASS' // TODO: use vx_api _get_status_by_action() as single source (send status in response)
            thunkAPI.dispatch(setJourneyStatus(status))
            message.status = status
            passSideEffectMessage(message)
            await sleep(1000)
            // See TODO at journey.slice fireJourneyCallback definition
            thunkAPI.dispatch(fireJourneyCallback({ status: 'PASS' }))
        }
        return response

        /** **************************** */
        // eslint-disable-next-line no-promise-executor-return
        // const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
        // await sleep( 1000 )
        // return aFailed // <--------------------------------------------
        // return aFulfilled // <--------------------------------------------
        /** **************************** */
    },
)
export const kbaProvideDetails = createAsyncThunk(
    'kba/kbaProvideDetails',
    // eslint-disable-next-line no-unused-vars
    async (_payload, thunkAPI) => {
        const { payload } = thunkAPI.getState().kba.moreDetails
        const extendPayload = {
            ...payload,
            service: thunkAPI.getState().journey.config.services.kba.service,
            ruleset: thunkAPI.getState().journey.config.services.kba.rules.ruleset,
        }

        // logJson( 'extend payload', extendPayload )

        const response = await dataService.getKbaQuestions(extendPayload)
        return response

        /** **************************** */
        // eslint-disable-next-line no-promise-executor-return
        // const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
        // await sleep( 500 )
        // we are expecting the same full PENDING questions response here
        // return qFulfilled // <--------------------------------------------
        // return qFailed // <--------------------------------------------

        /** **************************** */
    },
) // TODO: combine above & below?
export const kbaTryAgain = createAsyncThunk(
    'kba/kbaTryAgain',
    // eslint-disable-next-line no-unused-vars
    async (_payload, thunkAPI) => {
        const { payload } = thunkAPI.getState().kba.tryAgain
        const extendPayload = {
            ...payload,
            service: thunkAPI.getState().journey.config.services.kba.service,
            ruleset: thunkAPI.getState().journey.config.services.kba.rules.ruleset,
        }
        // payload.service = thunkAPI.getState().journey.config.services.kba.service
        // payload.ruleset = thunkAPI.getState().journey.config.services.kba.rules.ruleset
        console.log('TA payload', extendPayload)
        const response = await dataService.getKbaQuestions(extendPayload)
        return response

        /** **************************** */
        // eslint-disable-next-line no-promise-executor-return
        // const sleep = ms => new Promise( resolve => setTimeout( resolve, ms ))
        // await sleep( 500 )
        // we are expecting the same full PENDING questions response here
        // return qFulfilled // <--------------------------------------------
        // return qFailed // <--------------------------------------------

        /** **************************** */
    },
)

const kbaSlice = createSlice({
    name: 'kba',
    initialState: kbaState,

    reducers: {
        kbaLoading: (draftState, action) => {
            draftState.navigation.questionsLoading = action.payload.state
            draftState.navigation.loadingMessage = action.payload.message
        },
        kbaViewingQuestions: (draftState, action) => {
            draftState.navigation.viewingQuestions = action.payload
        },
        kbaViewingResult: (draftState, action) => {
            draftState.viewingResult = action.payload
        },
        kbaAddAnswer: (draftState, action) => {
            draftState.answersTemplate.answers[action.payload.id] = [action.payload.value]
        },
        kbaCheckingAnswers: (draftState, action) => {
            draftState.navigation.checkingAnswers = action.payload.state
            draftState.navigation.loadingMessage = action.payload.message
        },
        kbaMoreDetailsLoading: (draftState, action) => {
            draftState.navigation.moreDetailsLoading = action.payload.state
            draftState.navigation.loadingMessage = action.payload.message
        },
        kbaTryAgainLoading: (draftState, action) => {
            draftState.navigation.tryAgainLoading = action.payload.state
            draftState.navigation.loadingMessage = action.payload.message
        },
        kbaUpdatePayloadRequestIds: (draftState, action) => {
            draftState.tryAgain.payload.request_id = action.payload
            draftState.moreDetails.payload.request_id = action.payload
        },
        kbaUpdateMoreDetailsPayload: (draftState, action) => {
            draftState.moreDetails.payload.target = action.payload
        },
        kbaUpdateTryAgainPayload: (draftState, action) => {
            draftState.tryAgain.payload.target = action.payload
        },
        kbaIncrementTries: draftState => {
            draftState.tryAgain.tries += 1
        },
        // TODO: move to global ui slice
        kbaSetScrollable: (draftState, action) => {
            draftState.layout.scroll = action.payload
        },
    },

    extraReducers: builder => {
        builder
            /** *****************       QUESTIONS       ******************* */
            .addCase(kbaGetQuestions.fulfilled, (draftState, action) => {
                // TODO: refactor to switch statement
                if ('result' in action.payload) {
                    // logJson( 'questions result', action.payload )

                    // if ( action.payload.result.action === 'FAIL' ) {}
                    if (action.payload.result.action === 'PENDING') {
                        draftState.questions = action.payload.output.questions.questions
                        draftState.answersTemplate = action.payload.continuations.questions.template
                        draftState.url = action.payload.continuations.questions.url
                    }
                    draftState.questionsResult.action = action.payload.result.action
                    draftState.navigation.questionsLoading = false
                }
                if ('error' in action.payload) {
                    draftState.fetchQuestionsError = true
                    draftState.errorCode = action.payload.error?.code
                    draftState.errorMessage = action.payload.error.message
                    draftState.errorDescription = action.payload.error.detail
                }
            })
            .addCase(kbaGetQuestions.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('get questions rejected', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                }
                draftState.rejectionError = action.error
                draftState.navigation.questionsLoading = false
            })

            /** ******************       ANSWERS       ******************** */
            .addCase(kbaCheckAnswers.fulfilled, (draftState, action) => {
                if ('result' in action.payload) {
                    // if ( action.payload.result.action === 'REVIEW' ) {}
                    // if ( action.payload.result.action === 'PASS' ) {}
                    draftState.answersResult.action = action.payload.result.action
                    draftState.navigation.checkingAnswers = false
                }
                if ('error' in action.payload) {
                    draftState.navigation.checkingAnswers = false
                    draftState.checkAnswersError = true
                    draftState.errorCode = action.payload.error?.code
                    draftState.errorMessage = action.payload.error.message
                    draftState.errorDescription = action.payload.error.detail
                    // For expired continuations, IDR5 returns a 400 with code
                    // 'QRY6' and message 'Token Bad or Expired'. This includes
                    // a questions timeout.
                    if (action.payload.error?.code === 'QRY6') {
                        draftState.questionsTimeout = true
                    }
                }
            })
            .addCase(kbaCheckAnswers.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('check answers rejected', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                }
                draftState.rejectionError = action.error
                draftState.navigation.checkingAnswers = false
            })

            // kbaProvideDetails and kbaTryAgain are obviously similar at
            // present, but for now we avoid a tight coupling to accommodate
            // future divergent usages of these result states.
            /** ****************       MORE DETAILS       ****************** */
            .addCase(kbaProvideDetails.fulfilled, (draftState, action) => {
                if ('result' in action.payload) {
                    // if ( action.payload.result.action === 'FAIL' ) {}
                    if (action.payload.result.action === 'PENDING') {
                        draftState.questions = action.payload.output.questions.questions
                        draftState.answersTemplate = action.payload.continuations.questions.template
                        draftState.url = action.payload.continuations.questions.url
                    }
                    draftState.moreDetailsResult.action = action.payload.result.action
                    // update questions from FAIL to PENDING
                    draftState.questionsResult.action = action.payload.result.action
                    draftState.navigation.moreDetailsLoading = false
                }
                if ('error' in action.payload) {
                    draftState.moreDetailsError = true
                    draftState.errorCode = action.payload.error?.code
                    draftState.errorMessage = action.payload.error.message
                    draftState.errorDescription = action.payload.error.detail
                }
            })
            .addCase(kbaProvideDetails.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('provide details rejected', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                }
                draftState.rejectionError = action.error
                draftState.navigation.moreDetailsLoading = false
            })

            /** ***************       TRY AGAIN       ***************** */
            .addCase(kbaTryAgain.fulfilled, (draftState, action) => {
                // TODO: refactor to switch statement
                if ('result' in action.payload) {
                    // if ( action.payload.result.action === 'FAIL' ) {}
                    if (action.payload.result.action === 'PENDING') {
                        draftState.questions = action.payload.output.questions.questions
                        draftState.answersTemplate = action.payload.continuations.questions.template
                        draftState.url = action.payload.continuations.questions.url
                    }
                    draftState.tryAgainResult.action = action.payload.result.action
                    // update questions from FAIL to PENDING
                    draftState.questionsResult.action = action.payload.result.action
                    draftState.navigation.tryAgainLoading = false
                }
                if ('error' in action.payload) {
                    draftState.tryAgainError = true
                    draftState.errorCode = action.payload.error?.code
                    draftState.errorMessage = action.payload.error.message
                    draftState.errorDescription = action.payload.error.detail
                }
            })
            .addCase(kbaTryAgain.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('try again rejected', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                }
                draftState.rejectionError = action.error
                draftState.navigation.tryAgainLoading = false
            })

            .addDefaultCase(draftState => {
                return { ...draftState }
            })
    },
})

export const {
    kbaLoading,
    kbaCheckingAnswers,
    kbaMoreDetailsLoading,
    kbaTryAgainLoading,
    kbaViewingQuestions,
    kbaViewingResult,
    kbaAddAnswer,
    kbaUpdateMoreDetailsPayload,
    kbaUpdateTryAgainPayload,
    kbaUpdatePayloadRequestIds,
    kbaIncrementTries,
    kbaSetScrollable,
} = kbaSlice.actions

export default kbaSlice.reducer

/** ************************************************************************ */
// CHECK ANSWERS EXAMPLE RESPONSE
// logJson( 'response', response )
// {
//   "final": {
//     "template": {
//       "token": "sdvf6chcd4tdrnf2e48hxow4wfozwnx0wvi5dgd0"
//     },
//     "url": "/process/status"
//   },
//   "meta": {
//     "company": "Veratad Technologies, LLC",
//     "confirmation": 140844210,
//     "reference": "c0e4991b-1418-428c-978e-897c338e2b48",
//     "service": "AgeMatch5.0.KBA",
//     "timestamp": "2022-08-23 16:03:38",
//     "user": "vx_veratad_user_dcamsenhanced_2@veratad.com"
//   },
//   "output": {
//     "answers": {
//       "asked": 4,
//       "correct": 3,
//       "elapsed": 37
//     }
//   },
//   "result": {
//     "action": "PASS",
//     "detail": "ALL CHECKS PASSED",
//     "issues": []
//   }
// }
/** ************************************************************************ */

/** ************************************************************************ */
// MOCK RESPONSEs

// eslint-disable-next-line no-unused-vars
const qFailed = {
    output: {
        questions: {},
    },
    meta: {
        confirmation: 138993820,
        reference: 'VX iFrame dev',
        service: 'AgeMatch5.0.KBA',
        timestamp: '2022-07-26 14:55:46',
        company: 'Veratad Technologies, LLC',
        user: 'afields@veratad.com',
    },
    result: {
        action: 'FAIL',
        detail: 'NO MATCH',
        issues: [],
    },
}

// eslint-disable-next-line no-unused-vars
const qFulfilled = {
    meta: {
        confirmation: 139845551,
        timestamp: '2022-08-07 18:29:14',
        reference: 'vx_iframe_dev_1659911354.5700607',
        user: 'vx_api_test_001@veratad.com',
        company: 'Veratad Technologies, LLC',
        service: 'AgeMatch5.0.KBA',
    },
    output: {
        questions: {
            questions: [
                {
                    type: 'address',
                    prompt: 'Which of the following addresses are in ELLSWORTH AFB?',
                    id: 'address2',
                    answers: [
                        {
                            text: '2650 SPAATZ DR # 1251',
                        },
                        {
                            text: '209 BEECH RD',
                        },
                        {
                            text: '742 HARVEST LN',
                        },
                        {
                            text: '191 PINEHURST DRIVE',
                        },
                        {
                            text: 'None of the above',
                        },
                    ],
                },
                {
                    type: 'county',
                    prompt: 'With which of the following counties are you or have you been associated?',
                    id: 'county3',
                    answers: [
                        {
                            text: 'FORREST',
                        },
                        {
                            text: 'LIPSCOMB',
                        },
                        {
                            text: 'JEFFERSON DAVIS',
                        },
                        {
                            text: 'RUSSELL',
                        },
                        {
                            text: 'None of the above',
                        },
                    ],
                },
                {
                    type: 'phone',
                    prompt: 'With which of the following phone numbers are you or have you been associated?',
                    id: 'phone0',
                    answers: [
                        {
                            text: '(314) 802-8058',
                        },
                        {
                            text: '(505) 468-4493',
                        },
                        {
                            text: '(505) 309-5933',
                        },
                        {
                            text: '(931) 225-3452',
                        },
                        {
                            text: 'None of the above',
                        },
                    ],
                },
                {
                    type: 'zip5',
                    prompt: 'What is the zip code of BROOKLYN?',
                    id: 'zip51',
                    answers: [
                        {
                            text: '11267',
                        },
                        {
                            text: '11847',
                        },
                        {
                            text: '11480',
                        },
                        {
                            text: '11222',
                        },
                        {
                            text: 'None of the above',
                        },
                    ],
                },
            ],
        },
    },
    result: {
        action: 'PENDING',
        detail: 'MORE INFORMATION IS REQUIRED',
        issues: [],
    },
    continuations: {
        questions: {
            template: {
                answers: {
                    address2: [],
                    county3: [],
                    phone0: [],
                    zip51: [],
                },
                token: 'zefnjm74mn2oi9tl560cfhzd8swyteyg7qgibhxq',
            },
            instructions:
                'Add the text of the answers into the empty list for the appropriate question (case sensitive).',
            url: '/process/continue',
        },
    },
}

// eslint-disable-next-line no-unused-vars
const aFailed = {
    final: {
        template: {
            token: 'azsx8njjf7e94nleao00kismcge557rxwoqwnidt',
        },
        url: '/process/status',
    },
    meta: {
        company: 'Veratad Technologies, LLC',
        confirmation: 138994315,
        reference: 'VX iFrame dev',
        service: 'AgeMatch5.0.KBA',
        timestamp: '2022-07-26 15:03:08',
        user: 'vx_api_test_001@veratad.com',
    },
    output: {
        answers: {
            asked: 4,
            correct: 1,
            elapsed: 31,
        },
    },
    result: {
        action: 'REVIEW',
        detail: 'TRANSACTION REQUIRES FURTHER ATTENTION',
        issues: ['QUESTION CHECK FAILED'],
    },
}

// eslint-disable-next-line no-unused-vars
const aFulfilled = {
    final: {
        template: {
            token: '83cqa666ek8wi5a8zla5lizequmj9ehy',
        },
        url: '/process/status',
    },
    meta: {
        company: 'Veratad Technologies, LLC',
        confirmation: 138579788,
        reference: 'VX iFrame dev',
        service: 'AgeMatch5.0.KBA',
        timestamp: '2022-07-21 12:46:24',
        user: 'afields@veratad.com',
    },
    output: {
        answers: {
            asked: 4,
            correct: 3,
            elapsed: 133,
        },
    },
    result: {
        action: 'PASS',
        detail: 'ALL CHECKS PASSED',
        issues: [],
    },
}
/** ************************************************************************ */
