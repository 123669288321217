import React from 'react'
import {
    Space,
    Stack,
    Title, // Center,
} from '@mantine/core'
import { useSelector } from 'react-redux'
import { useViewportSize } from '@mantine/hooks'
import { BarLoader } from 'react-spinners'

// import KbaTitle from './journeyStops/kba/1985/components/kbaTitle'
// import KbaDialogue from './journeyStops/kba/1985/components/kbaDialogue'

// import Logo from './utilities/logoFactory'

// import { useTheme } from './nativeThemeMapper'

const brandColors = {
    '1985-f5acc615-a06d-4d8a-9efe-b16f475c766e': '#CC0B2F',
    '21-f6494710-0c63-45a7-bab0-61fbcd66722': '#0070A2',
    '21-ad05c09a-3f78-4bf7-aaca-bcd7a5c4e64b': '#0070A2',
    '21-073e63cd-6927-4ad4-a3fa-b041e3c8090a': '#238D80',

    // camel
    '21-8dca8e1e-610f-438d-a1b0-4f50efe27765': '#000000',
    // cougar
    '21-3bd0455f-e4af-4afe-a7d7-f7690282bbd6': '#bf4514',
    // grizzly
    '21-8fd86aaf-847a-4bd2-9bf2-b0cef13eb233': '#be2625',
    // kodiak
    '21-6e4364af-6e0e-479a-b319-e95fcc41d04f': '#343434',
    // lucky strike
    '21-0ad07db5-ebff-4107-9571-9693873fccf2': '#d01c32',
    // nas
    '21-9561087f-94ce-44a1-b001-1ce7a1b86448': '#00a6c1',
    // newport
    '21-eb336d5c-696f-4353-922c-3eadfed54cc7': '#000000',
    // pall mall
    '21-a0ce4000-05a2-4678-9847-985082362bc9': '#ae2129',
    // velo
    '21-dcda86a9-5777-4cb8-afc0-37af797fc9de': '#6ea952',
    // vuse
    '21-fa10b10f-bd9e-4d7f-ba35-e8864865eb0b': '#000000',
    // levi garrett
    '21-19e78102-e1c7-49c5-90c3-293730bc4c7a': '#007224',
    // sensavape
    '21-18ab475d-dfc7-4dc1-b787-5da94b54e1ed': '#007224',
}

// eslint-disable-next-line no-unused-vars
const brandTitles = {
    '1985-f5acc615-a06d-4d8a-9efe-b16f475c766e': '',
    '21-f6494710-0c63-45a7-bab0-61fbcd66722': '',
}

function AppTitle({ children, ...p }) {
    const colors = {
        1: '#212721',
        2: '#212721',
        3: 'black',
        4: 'black',
        5: 'black',
        6: 'black',
    }

    return (
        <Title
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...p}
            sx={{
                color: colors[p?.order || 1],
            }}
        >
            {children}
        </Title>
    )
}

/** ************************************************************** */
// TODO: this is a kludge for Wawa. Create Generalized app loader.
/** ************************************************************** */

// // export default function AppLoading( { journeyId, } ) {
// export default function AppLoading() {
//     // const { getNativeMappedTheme, } = useTheme()
//     // const journeyTheme = getNativeMappedTheme( journeyId )

//     const primaryColor = '#238D80'
//     const loadingMessage = 'Verification in progress'
//     const {
//         height: viewportHeight,
//         width: vpWidth,
//     } = useViewportSize()

//     return (
//         <Center
//             style={ {
//                 width: vpWidth,
//                 height: viewportHeight,
//             } }
//         >
//             <Stack
//                 spacing="6rem" // small mobile such as iPHone SE
//                 mx={ 5 }
//                 align="center"
//             >

//                 <AppTitle>
//                     {loadingMessage}
//                 </AppTitle>

//                 <BarLoader
//                     color={ primaryColor }
//                     height="6px"
//                     width="8em"
//                     speedMultiplier={ 1 }
//                     cssOverride={ {
//                         borderRadius: '10px',
//                         backgroundColor: '#D9D9D6',
//                     } }
//                 />

//             </Stack>
//         </Center>
//     )
// }

export default function AppLoading({ journeyId, loadingMessage }) {
    // const {
    //     primaryColor,
    //     logoName,
    // } = useSelector( state => state.journey.config.services.kba.theme )
    // const { loadingMessage, } = useSelector( state => state.kba.navigation )
    // const loadingMessage = 'Verification in progress'
    // const logoName = 'veratad'

    // const primaryColor = '#238D80'

    const { vertBreakPoint } = useSelector(state => state.kba.constants)
    const { height: viewportHeight } = useViewportSize()

    return (
        <>
            <Space h={62} />

            <Stack spacing={viewportHeight > vertBreakPoint ? '12em' : '8em'} mx={5} mt='6.5em' align='center'>
                <AppTitle>
                    {' '}
                    {/* {loadingMessage} */}
                    {loadingMessage && loadingMessage}
                </AppTitle>

                <BarLoader
                    // color={ primaryColor }
                    color={brandColors[journeyId] || '#238D80'}
                    height='6px'
                    width='8em'
                    speedMultiplier={1}
                    cssOverride={{
                        borderRadius: '10px',
                        backgroundColor: '#D9D9D6',
                    }}
                />

                {/* <Logo
                    mt="5em"
                    name={ logoName }
                /> */}
            </Stack>

            {/* <KbaDialogue /> */}
        </>
    )
}
