import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dataService } from '../services/dataService'

import { carrierWhitelist } from './carriers'
import { logger, logJson } from '../utilities/helpers'

const logJsonError = (description, json) =>
    console.error(
        `%c [${description.toUpperCase()}] ${JSON.stringify(json, null, 4)}`,
        'background: #3D4047; color: #05F0ED',
    )

const deviceDetectState = {
    reportSaved: false,
    report: {},
    ipAddress: '',
    mobileCarrier: {},
    isMobileCarrier: false,
    isWhitelistedCarrier: false,
    ipDataLoaded: false,
    fingerprint: {},
}

export const getDeviceIpData = createAsyncThunk('deviceDetect/getDeviceIpData', async () => {
    const response = await dataService.getIpData()
    return response
})

export const reportDeviceType = createAsyncThunk(
    'deviceDetect/reportDeviceType',
    // eslint-disable-next-line no-unused-vars
    async (_payload, thunkAPI) => {
        // const { fingerprint } = thunkAPI.getState().deviceDetect
        const {
            request_id,
            maxDeviceWidth1224: is_max_device_width_1224,
            deviceData,
            mobileCarrier,
            fingerprint,
        } = _payload

        const is_desktop = !deviceData?.ua.includes('Mobi')

        const payload = {
            request_id,
            deviceData,
            mobileCarrier,
            is_max_device_width_1224,
            is_desktop,
            fingerprint,
        }

        const response = await dataService.reportDevice(payload)
        // logJson( 'device report sent', payload )
        logger('[REPORT SENT]')
        return response
    },
)

// export const getABParticipant = createAsyncThunk('deviceDetect/getABParticipant', async (payload, thunkAPI) => {
//     // const {carrier, journey_id, request_id} = _payload
//     const response = await dataService.getABParticipant(payload)
//     return response
// })

const deviceDetectSlice = createSlice({
    name: 'deviceDetect',
    initialState: deviceDetectState,

    reducers: {
        setFingerprint: (draftState, action) => {
            draftState.fingerprint = action.payload
        },
    },

    extraReducers: builder => {
        builder
            .addCase(getDeviceIpData.fulfilled, (draftState, action) => {
                logger('[getDeviceIpData received]')
                logger(action.payload.ip)
                logJson('device ip data full response', action.payload)
                draftState.ipAddress = action.payload.ip
                draftState.mobileCarrier = action.payload.mobileCarrier
                draftState.isMobileCarrier = !!action.payload.mobileCarrier?.name
                draftState.isWhitelistedCarrier = carrierWhitelist.includes(action.payload.mobileCarrier?.name)
                draftState.ipDataLoaded = true
            })
            .addCase(getDeviceIpData.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.promiseRejection = action.error
                }
            })

            .addCase(reportDeviceType.fulfilled, (draftState, action) => {
                draftState.reportRun = true
                draftState.reportSaved = action.payload.record_saved
                draftState.report = action.payload.record
            })
            .addCase(reportDeviceType.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.reportRun = true
                    draftState.promiseRejection = action.error
                    draftState.ssnResult = 'FAIL'
                }
            })

        // .addCase(getABParticipant.fulfilled, (draftState, action) => {
        //     // {participant: 1, variant: 'a' }
        //     // START: MOVE TO NEW SLICE
        //     draftState.abParticipant = action.payload
        // })
        // .addCase(getABParticipant.rejected, (draftState, action) => {
        //     // action.error is a serialized error value
        //     try {
        //         logJsonError('Error', action.error)
        //     } catch (e) {
        //         console.error(e)
        //     } finally {
        //         console.log(action.error)
        //         draftState.promiseRejection = action.error
        //     }
        // })
    },
})

export const { setFingerprint } = deviceDetectSlice.actions

export default deviceDetectSlice.reducer
