import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dataService } from '../services/dataService'
import { fireJourneyCallback, setJourneyStatus } from './journey.slice'

import { bouncerMessages } from './messages'
import { bouncerMatchFailure } from './actions'

import {
    logger, //  logJson
} from '../utilities/helpers'
import { Component } from 'react'

const logJsonError = (description, json) =>
    console.error(
        `%c [${description.toUpperCase()}] ${JSON.stringify(json, null, 4)}`,
        'background: #3D4047; color: #05F0ED',
    )
const logJson = (description, json) => {
    console.log(`%c [${description}]`, 'background: #222; color: #7EDEE0')
    console.log(`%c ${JSON.stringify(json, null, 4)}`, 'background: #3D4047; color: #bada55')
}

const phonematchState = {
    verificationInProgress: false,
    sendingOtp: false,
    submittingOtp: false,
    viewingResult: false,

    resendAttempts: 0,

    carrierVerifyInitiateTemplate: {
        service: '',
        target: {
            request_id: '',
            ip_address: '',
            phone: '',
        },
    },

    carrierVerifyRedirectUrl: '',
    carrierVerifyContinueTemplate: {
        codematch: {
            vfp: '',
            request_id: '',
        },
        token: '',
    },
    //    "continuations":{
    //       "prove":{
    //          "template":{
    //             "codematch":{
    //                "vfp":"",
    //                "vx_request_id": "<inserted vx session request id>"
    //             },
    //             "token":"0posez1xnjncdnj5m193byayt9gokro6e8loluvg"
    //          },
    //          "redirect_url":"https://auth.svcs.verizon.com:22790/vzconnect/cdi/v1/authorize?auth_request=eyJraWQiOiI4YzBjYmE0Mi0xMDZiLTRhYmQtOGU5Ni04YzIwYjMzNjRhOTEiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiIzZDdiMWIyMC00NDc5LTQzM2YtODUyZC01NWVjYWQ3ZWExN2YiLCJhdWQiOiJodHRwczovL2F1dGguc3Zjcy52ZXJpem9uLmNvbToyMjc5MC92emNvbm5lY3QiLCJjbGllbnRfaWQiOiIzZDdiMWIyMC00NDc5LTQzM2YtODUyZC01NWVjYWQ3ZWExN2YiLCJyZWRpcmVjdF91cmkiOiJodHRwczovL2RldmljZS5zdGFnaW5nLnBheWZvbmUuY29tOjQ0NDMvbW9iaWxlYXV0aC8yMDE0LzA3LzAxL2RldmljZUNvbnRpbnVlIiwic2NvcGUiOiJvcGVuaWQiLCJyZXNwb25zZV90eXBlIjoiaWRfdG9rZW4iLCJleHAiOjE2NjkxMzY4ODcsInNpZCI6IjYwMmNjZGFlLTJlZmYtNGQ4OC1hOTJjLWVjOWM4MTJiZGI5YyIsInN0YXRlIjoiYjY2NWIxZjNjMmI5NGY1NjhiZDBkMWZkM2ZjMWM4ZTIiLCJraWQiOiI4YzBjYmE0Mi0xMDZiLTRhYmQtOGU5Ni04YzIwYjMzNjRhOTEiLCJjZGkiOnsibWVyY2hhbnRpZCI6IjEyMzQ1Njk3NTIiLCJtZXJjaGFudG5hbWUiOiJWRVIiLCJpbV91c2VjYXNlIjoiQXV0aHdDbnN0IiwiYWdncmVnYXRvcmlkIjoiODEwNjA3IiwicG9zdHBhaWRfdmFsaWRhdGlvbiI6InllcyJ9LCJjcHViIjp7InZmcCI6IjRmNTQ2YzZiNGQ1NDU1Nzc0ZTdhNjM3NDRkNDQ2Mzc3NGY0MzMwMzA1YTU0NjczMzRjNTc0OTM0NGQ2YTY3NzQ0ZTZhNTEzMjVhNmE0NTdhNTk1NzU5Nzk0ZTdhNjMzNDY2NDQ0MjM4NTI0ODc3NzgzYWZhOWYyODQzMGQ3OGYzMTc2MzM3OTgyNzJmZjczMzM5NTExZjQxOGM2NmEzOWEwYjQ5MDA4Y2JhZTYzNjg1YWEifX0.jzjfq4Tq4DWJdoTtz5hJOHiBX7zptHM02qslHkrcQ4f1RSJcmNb7cADZp0zT-BB4EQM6yTEdf2HLroz6DUAvt_XF9d-LQh950CEv0otcs0vpAPBnOYiuilr1Ys_2WdZTAq7dodhsOfZ-kCkABHOFJ5AtM9NYuedaw03POdBZNQAue3xTpOervQOTN9Cjp_OXkahE4SOdK9oqqWB1NP3hJe-H7pKSwd4qsbLHc8nfp7jUlyHbg6EuDpNkB4iph_2ZnFktu33-iQ09LlwtdN5Gz5hlHSHyafx1OEd9ptb3snUbnjCwZwMoxsjDzS5Z2p9shU5742tbkOVn4Qro1Mf2-A",
    //          "final_url":"https://tampa-2.idresponse.com/process/continue/phoneauth?request_id=f7413a2a-6a86-11ed-be44-a9f2f6a15d3f",
    //          "expiry":"2022-11-22 12:01:02",
    //          "instructions":"Please follow redirect_url before the expiry timestamp. A vfp token will be appended to the final_url. Extract the vfp token along with the request_id and submit with template."
    //       }
    //     }

    otpInitiateTemplate: {
        request_id: '',
        service: '',
        target: {
            phone: '',
        },
    },
    // {
    //     "request_id": "<VX SESSION REQUEST ID>", # (popped)
    //     "service": "<SERVICE>",
    //     "target": {
    //         "phone": "<PHONE NUMBER>",
    //     }
    // }
    otpContinueTemplate: {
        request_id: '',
        token: '',
        codematch: {
            key: '',
        },
        // draftState.otpContinueTemplate = action.payload.continuations.outofband.template
        // received:
        // "template": {
        //     "request_id": "c89fe5be-5b8f-11ed-be44-a9f2f6a15d3f",
        //     "token": "qli6f738k0ls9te8kmy26xqix64a72ts",
        //     "codematch": {
        //         "key": null
        //     }
        // },
    },

    carrierVerifyInitiateResult: '',
    carrierVerifyRedirectResult: '',
    carrierVerifyContinueResult: '',
    otpInitiateResult: '',
    otpContinueResult: '',
    phonematchResult: '',

    errorResponse: {},
    promiseRejection: {},
}

// export const phonematchGetIp = createAsyncThunk(
//     'phonematch/phonematchGetIp',
//     async () => {
//         // const response = await dataService.getIp()
//         const response = await dataService.getIpData()
//         logJson( 'ipdata', response )
//         return response
//     }
// )

export const initiateCarrierVerify = createAsyncThunk(
    'phonematch/phonematchInitiateCarrierVerify',
    async (_payload, thunkAPI) => {
        const template = thunkAPI.getState().phonematch.carrierVerifyInitiateTemplate
        const { additionalData } = thunkAPI.getState().init.clientData
        const payload = {
            ...template,
            target: {
                ...template.target,
                ip_address: thunkAPI.getState().deviceDetect.ipAddress,
                request_id: thunkAPI.getState().init.clientData.request_id,
                phone: thunkAPI.getState().init.clientData.phone,

                // added with NRS phonematchMoreDetails
                fn: thunkAPI.getState().init.clientData.fn,
                ln: thunkAPI.getState().init.clientData.ln,
                addr: thunkAPI.getState().init.clientData.addr,
                city: thunkAPI.getState().init.clientData.city,
                state: thunkAPI.getState().init.clientData.state,
                zip: thunkAPI.getState().init.clientData.zip,
                dob: thunkAPI.getState().init.clientData.dob,
            },
            service: thunkAPI.getState().journey.config.services.phonematch.services.carrierVerify,
            // request_id: thunkAPI.getState().init.clientData.request_id,
            additionalData,
        }
        // payload.target.request_id = thunkAPI.getState().init.clientData.request_id
        // payload.target.phone = thunkAPI.getState().init.clientData.phone

        // logger( '[initiateCarrierVerify payload]', payload )

        const response = await dataService.carrierVerify(payload)

        if (Object.keys(bouncerMessages).includes(response?.result?.detail)) {
            logger('[CARRIER VERIFY BOUNCER MESSAGE MATCH:]', response.result.detail)
            thunkAPI.dispatch(bouncerMatchFailure({ detail: response.result.detail }))
        }

        // Also add this to other thunks here (PHONEMATCH) and thunks in KBA, AGEMATCH, DCAMS
        if ('blockedError' in response) {
            thunkAPI.dispatch(setBlockedError({ response, component: 'PHONEMATCH' }))
            return
        }

        const status = response?.status
        if (status) {
            thunkAPI.dispatch(setJourneyStatus(status))
        }

        // logJson( 'initiateCarrierVerify sent', payload )
        return response
    },
)

// export const getCarrierVerifyRedirect = createAsyncThunk(
//     'phonematch/phonematchGetCarrierVerifyRedirect',
//     async ( _payload, thunkAPI ) => {
//         const url = thunkAPI.getState().phonematch.carrierVerifyRedirectUrl
//         const response = await dataService.getExternal( url )
//         logger( '[running getCarrierVerifyRedirect... URL:]', url )
//         // logger( '[getCarrierVerifyRedirect Received Response:]', response )
//         return response
//     }
// )

export const continueCarrierVerify = createAsyncThunk(
    'phonematch/phonematchContinueCarrierVerify',
    async (_payload, thunkAPI) => {
        const payload = thunkAPI.getState().phonematch.carrierVerifyContinueTemplate
        if (!payload.codematch.request_id) {
            const { request_id } = thunkAPI.getState().init.clientData
            payload['codematch']['request_id'] = request_id
        }

        logger('[continueCarrierVerify payload]', payload)

        const response = await dataService.carrierVerifyContinue(payload)

        if (Object.keys(bouncerMessages).includes(response?.result?.detail)) {
            thunkAPI.dispatch(bouncerMatchFailure({ detail: response.result.detail }))
        }

        const status = response?.status
        if (status) {
            thunkAPI.dispatch(setJourneyStatus(status))
        }

        /** *************************************************************************
         * added with NRS phonematchMoreDetails
         ************************************************************************* */
        // eslint-disable-next-line no-promise-executor-return
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        if ((response?.result?.action && response?.result?.action !== 'PASS') || response?.error) {
            const { journey } = thunkAPI.getState().journey.config
            // if ( response?.error?.code === 'QRY6' ) {
            //     const message = {
            //         status: '',
            //         component: 'PHONEMATCH',
            //         error: 'BAD TOKEN',
            //         event: '',
            //     }
            // }
            if (journey[journey.length - 1].includes('phonematch')) {
                // is last stop
                await sleep(1000)
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch(fireJourneyCallback({ status: 'FAIL' }))
            } else {
                // not last stop
                // See TODO at journey.slice fireJourneyCallback definition
                thunkAPI.dispatch(fireJourneyCallback({ status: 'PENDING' }))
            }
        } else {
            await sleep(1000)
            // See TODO at journey.slice fireJourneyCallback definition
            thunkAPI.dispatch(fireJourneyCallback({ status: 'PASS' }))
        }
        /** *********************************************************************** */

        // logJson( 'continueCarrierVerify sent', payload )
        return response
    },
)

export const initiateOtp = createAsyncThunk('phonematch/phonematchInitiateOtp', async (_payload, thunkAPI) => {
    const _template = thunkAPI.getState().phonematch.otpInitiateTemplate
    const template = {
        ..._template,
        target: {
            ..._template.target,
            phone: thunkAPI.getState().init.clientData.phone,

            // added with NRS phonematchMoreDetails
            fn: thunkAPI.getState().init.clientData.fn,
            ln: thunkAPI.getState().init.clientData.ln,
            addr: thunkAPI.getState().init.clientData.addr,
            city: thunkAPI.getState().init.clientData.city,
            state: thunkAPI.getState().init.clientData.state,
            zip: thunkAPI.getState().init.clientData.zip,
            dob: thunkAPI.getState().init.clientData.dob,
        },
    }
    // template.target.phone = thunkAPI.getState().init.clientData.phone

    const { additionalData } = thunkAPI.getState().init.clientData

    // logJson( 'initiateOtp additionalData', additionalData )

    const payload = {
        ...template,
        request_id: thunkAPI.getState().init.clientData.request_id,
        service: thunkAPI.getState().journey.config.services.phonematch.services.otp,
        additionalData,
    }

    // logger( '[initiateOtp payload]', payload )

    const response = await dataService.otpVerify(payload)

    if (Object.keys(bouncerMessages).includes(response?.result?.detail)) {
        thunkAPI.dispatch(bouncerMatchFailure({ detail: response.result.detail }))
    }

    const status = response?.status
    if (status) {
        thunkAPI.dispatch(setJourneyStatus(status))
    }

    // logJson( 'initiateOtp sent', payload )
    return response
})

export const continueOtp = createAsyncThunk('phonematch/phonematchContinueOtp', async (_payload, thunkAPI) => {
    const payload = thunkAPI.getState().phonematch.otpContinueTemplate

    logger('[continueOtp payload]', payload)

    const response = await dataService.otpVerifyContinue(payload)

    if (Object.keys(bouncerMessages).includes(response?.result?.detail)) {
        thunkAPI.dispatch(bouncerMatchFailure({ detail: response.result.detail }))
    }

    const status = response?.status
    if (status) {
        thunkAPI.dispatch(setJourneyStatus(status))
    }

    /** *************************************************************************
     * added with NRS phonematchMoreDetails
     ************************************************************************* */
    // eslint-disable-next-line no-promise-executor-return
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    if ((response?.result?.action && response?.result?.action !== 'PASS') || response?.error) {
        const { journey } = thunkAPI.getState().journey.config
        // if ( response?.error?.code === 'QRY6' ) {
        //     const message = {
        //         status: '',
        //         component: 'PHONEMATCH',
        //         error: 'BAD TOKEN',
        //         event: '',
        //     }
        // }
        if (journey[journey.length - 1].includes('phonematch')) {
            // is last stop
            await sleep(1000)
            // See TODO at journey.slice fireJourneyCallback definition
            thunkAPI.dispatch(fireJourneyCallback({ status: 'FAIL' }))
        } else {
            // not last stop
            // See TODO at journey.slice fireJourneyCallback definition
            thunkAPI.dispatch(fireJourneyCallback({ status: 'PENDING' }))
        }
    } else {
        await sleep(1000)
        // See TODO at journey.slice fireJourneyCallback definition
        thunkAPI.dispatch(fireJourneyCallback({ status: 'PASS' }))
    }
    /** *********************************************************************** */

    // logJson( 'continueOtp sent', payload )
    return response
})

const phonematchSlice = createSlice({
    name: 'phonematch',
    initialState: phonematchState,

    reducers: {
        phonematchVerificationInProgress: (draftState, action) => {
            draftState.verificationInProgress = action.payload
        },
        updateCarrierVerifyContinueTemplate: (draftState, action) => {
            logger('[UPDATING CONTINUATION TEMPLATE]')
            draftState.carrierVerifyContinueTemplate.codematch.vfp = action.payload.vfp
            if (action.payload.request_id) {
                draftState.carrierVerifyContinueTemplate.codematch.request_id = action.payload.request_id
            }
            draftState.carrierVerifyRedirectResult = 'COMPLETE'
        },
        updatePhonematchOtp: (draftState, action) => {
            draftState.otpContinueTemplate.codematch.key = action.payload.key
        },
        phonematchSendingOtp: (draftState, action) => {
            draftState.sendingOtp = action.payload
        },
        phonematchIncrementResendAttempts: draftState => {
            draftState.resendAttempts += 1
        },
        phonematchSubmittingOtp: (draftState, action) => {
            draftState.submittingOtp = action.payload
        },
        phonematchViewingResult: (draftState, action) => {
            draftState.viewingResult = action.payload
        },
    },

    extraReducers: builder => {
        builder
            // .addCase( phonematchGetIp.fulfilled, ( draftState, action ) => {
            //     logger( '[phonematchGetIp received]' )
            //     logger( action.payload.ip )
            //     draftState.carrierVerifyInitiateTemplate.target.ip_address = action.payload.ip
            // } )
            // .addCase( phonematchGetIp.rejected, ( draftState, action ) => {
            //     // action.error is a serialized error value
            //     try {
            //         logJsonError( 'Error', action.error )
            //     } catch ( e ) {
            //         console.error( e )
            //     } finally {
            //         console.log( action.error )
            //         draftState.promiseRejection = action.error
            //     }
            // } )

            .addCase(initiateCarrierVerify.fulfilled, (draftState, action) => {
                // logJson( 'initiateCarrierVerify received', action.payload )
                // const _logJson = ( description, json ) => console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #bada55' )
                // _logJson( '[initiateCarrierVerify received]', action.payload )
                if (action.payload?.result) {
                    if (!action.payload?.template) {
                        // no continuation
                        // logJsonError( '[CONTINUATION ERROR]', { idr5Error: 'No continuation template received', } )
                        draftState.errorResponse = { idr5Error: 'No continuation template received' }
                        draftState.carrierVerifyInitiateResult = 'FAIL'
                    } else {
                        // got a continuation
                        draftState.carrierVerifyInitiateResult = action.payload.result.action
                        if (action.payload.result.action === 'PENDING') {
                            draftState.carrierVerifyContinueTemplate = action.payload.template
                            draftState.carrierVerifyRedirectUrl = action.payload.redirect_url
                        }
                    }
                }
                if (action.payload?.error) {
                    draftState.errorResponse = action.payload.error
                    draftState.carrierVerifyInitiateResult = 'FAIL'
                }
            })
            .addCase(initiateCarrierVerify.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.promiseRejection = action.error
                    draftState.carrierVerifyInitiateResult = 'FAIL'
                }
            })

            // .addCase( getCarrierVerifyRedirect.fulfilled, ( draftState, action ) => {
            // // parse out the request_id and vfp params
            //     logger( '[getCarrierVerifyRedirect received response:]', action.payload )

            //     const urlParams = new URLSearchParams( action.payload )
            //     if ( urlParams.has( 'vfp' ) && urlParams.has( 'request_id' )) {
            //         logger( '[FOUND VFP AND RID PARAMS:]', urlParams.get( 'vfp' ), urlParams.get( 'request_id' ))
            //         draftState.carrierVerifyContinueTemplate.codematch.vfp = urlParams.get( 'vfp' )
            //         draftState.carrierVerifyContinueTemplate.codematch.request_id = urlParams.get( 'request_id' )
            //         draftState.carrierVerifyRedirectResult = 'COMPLETE'
            //     } else {
            //         logger( '[VFP AND RID PARAMAS NOT FOUND. Entries:]', urlParams.entries())
            //         draftState.carrierVerifyRedirectResult = 'INCOMPLETE'
            //     }
            // } )
            // .addCase( getCarrierVerifyRedirect.rejected, ( draftState, action ) => {
            //     // action.error is a serialized error value
            //     try {
            //         logJsonError( 'Error', action.error )
            //     } catch ( e ) {
            //         console.error( e )
            //     } finally {
            //         console.log( action.error )
            //         draftState.promiseRejection = action.error
            //         draftState.carrierVerifyRedirectResult = 'INCOMPLETE'
            //     }
            // } )

            .addCase(continueCarrierVerify.fulfilled, (draftState, action) => {
                // logJson( 'continueCarrierVerify received', action.payload )
                // const _logJson = ( description, json ) => console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #3D4047; color: #bada55' )
                // _logJson( 'continueCarrierVerify received', action.payload )

                draftState.carrierVerifyContinueResult = action.payload?.result?.action
                if (action.payload?.result?.action === 'PASS') {
                    draftState.phonematchResult = 'PASS'
                    draftState.verificationInProgress = false
                }
                if (action.payload?.error) {
                    draftState.errorResponse = action.payload
                    draftState.carrierVerifyContinueResult = 'FAIL'
                    draftState.verificationInProgress = false
                }
            })
            .addCase(continueCarrierVerify.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.promiseRejection = action.error
                    draftState.carrierVerifyContinueResult = 'FAIL'
                    draftState.verificationInProgress = false
                }
            })

            .addCase(initiateOtp.fulfilled, (draftState, action) => {
                logJson('initiateOtp received', action.payload)
                if (action.payload?.result) {
                    draftState.otpInitiateResult = action.payload.result.action
                    if (action.payload.result.action === 'PENDING') {
                        const _template = action.payload.continuations.outofband.template
                        const template = {
                            ..._template,
                            codematch: {
                                ..._template.codmatch,
                                key: '',
                            },
                        }
                        draftState.otpContinueTemplate = template
                    }
                }
                if (action.payload?.error) {
                    draftState.errorResponse = action.payload.error
                    draftState.otpInitiateResult = 'FAIL'
                }
                draftState.sendingOtp = false
            })
            .addCase(initiateOtp.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.promiseRejection = action.error
                    draftState.otpInitiateResult = 'FAIL'
                }
            })

            .addCase(continueOtp.fulfilled, (draftState, action) => {
                logJson('continueOtp received', action.payload)
                if (!action.payload?.result?.action) {
                    draftState.errorResponse = action.payload
                    draftState.otpContinueResult = 'FAIL'
                    draftState.submittingOtp = false
                    return
                }
                draftState.otpContinueResult = action.payload.result.action
                if (action.payload.result.action === 'PASS') {
                    draftState.phonematchResult = 'PASS'
                }
                if (action.payload?.error) {
                    draftState.errorResponse = action.payload.error
                    draftState.otpContinueResult = 'FAIL'
                }
                draftState.submittingOtp = false
            })
            .addCase(continueOtp.rejected, (draftState, action) => {
                // action.error is a serialized error value
                try {
                    logJsonError('Error', action.error)
                } catch (e) {
                    console.error(e)
                } finally {
                    console.log(action.error)
                    draftState.promiseRejection = action.error
                    draftState.otpContinueResult = 'FAIL'
                }
            })

            .addDefaultCase(draftState => {
                return { ...draftState }
            })
    },
})

export const {
    phonematchVerificationInProgress,
    updateCarrierVerifyContinueTemplate,
    updatePhonematchOtp,
    phonematchSendingOtp,
    phonematchSubmittingOtp,
    phonematchIncrementResendAttempts,
    phonematchViewingResult,
} = phonematchSlice.actions

export default phonematchSlice.reducer
