import React from 'react'
import { Global } from '@mantine/core'
// import loadable from '@loadable/component'

// import bold from './GreycliffCF-Bold.woff2'
// import heavy from './GreycliffCF-Heavy.woff2'
import poppins700 from '../assets/fonts/poppins-v20-latin-700.woff2'
import poppins600 from '../assets/fonts/poppins-v20-latin-600.woff2'
import poppins500 from '../assets/fonts/poppins-v20-latin-500.woff2'
import poppinsRegular from '../assets/fonts/poppins-v20-latin-regular.woff2'
import poppins300 from '../assets/fonts/poppins-v20-latin-300.woff2'
import asap700 from '../assets/fonts/asap-v24-latin-700.woff2'
import asap600 from '../assets/fonts/asap-v24-latin-600.woff2'
import asap400 from '../assets/fonts/asap-v24-latin-regular.woff2'
import lato400 from '../assets/fonts/lato-v23-latin-regular.woff2'
import lato700 from '../assets/fonts/lato-v23-latin-700.woff2'
import fira400 from '../assets/fonts/fira-sans-v16-latin-regular.woff2'

import unitedsans400 from '../assets/fonts/united-sans-400.woff2'
import roboto700 from '../assets/fonts/roboto-v30-latin-700.woff2'
import helvetica400 from '../assets/fonts/helvetica-400.woff2'
import bebasneue400 from '../assets/fonts/bebas-neue-v14-latin-regular.woff2'
import gothambook400 from '../assets/fonts/gotham-book-400.woff2'
import anybodycondensed400 from '../assets/fonts/Anybody-CondensedRegular.woff2'
import modestotext400 from '../assets/fonts/modesto-text-medium.woff2'
import tradegothiccondensed700 from '../assets/fonts/trade-gothic-w05-bold-condensed.woff2'
import lato900 from '../assets/fonts/lato-v24-latin-900.woff2'
import caladea700 from '../assets/fonts/caladea-v7-latin-700.woff2'
import tomatogrotesk700 from '../assets/fonts/tomato-grotesk-700.otf'

// const poppins700 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/poppins-v20-latin-700.woff2' ))
// const poppins600 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/poppins-v20-latin-600.woff2' ))
// const poppins500 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/poppins-v20-latin-500.woff2' ))
// const poppinsRegular = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/poppins-v20-latin-regular.woff2' ))
// const poppins300 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/poppins-v20-latin-300.woff2' ))
// const asap700 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/asap-v24-latin-700.woff2' ))
// const asap600 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/asap-v24-latin-600.woff2' ))
// const asap400 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/asap-v24-latin-regular.woff2' ))
// const lato400 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/lato-v23-latin-regular.woff2' ))
// const lato700 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/lato-v23-latin-700.woff2' ))
// const fira400 = loadable(() => import( /* webpackPreload: true */ '../assets/fonts/fira-sans-v16-latin-regular.woff2' ))


export default function CustomFonts() {
    return (
        <Global
            styles={[
                {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        // src: `url('https://fonts.googleapis.com/css?family=Poppins:400,300,700,600|Roboto:600')`,
                        src: `url('${poppins700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },

                {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        // src: `url('https://fonts.googleapis.com/css?family=Poppins:400,300,700,600|Roboto:600')`,
                        src: `url('${poppins600}') format("woff2")`,
                        fontWeight: 600,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        // src: `url('https://fonts.googleapis.com/css?family=Poppins:400,300,700,600|Roboto:600')`,
                        src: `url('${poppins500}') format("woff2")`,
                        fontWeight: 500,
                        fontStyle: 'normal',
                    },
                },

                {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        // src: `url('https://fonts.googleapis.com/css?family=Poppins:400,300,700,600|Roboto:600')`,
                        src: `url('${poppinsRegular}') format("woff2")`,
                        // fontWeight: 'normal',
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },

                {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        // src: `url('https://fonts.googleapis.com/css?family=Poppins:400,300,700,600|Roboto:600')`,
                        src: `url('${poppins300}') format("woff2")`,
                        fontWeight: 300,
                        fontStyle: 'normal',
                    },
                },

                {
                    '@font-face': {
                        fontFamily: 'Asap',
                        src: `url('${asap700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Asap',
                        src: `url('${asap600}') format("woff2")`,
                        fontWeight: 600,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Asap',
                        src: `url('${asap400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },

                {
                    '@font-face': {
                        fontFamily: 'Lato',
                        src: `url('${lato400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Lato',
                        src: `url('${lato700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Fira Sans',
                        src: `url('${fira400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },

                // === RJ brands ===

                // Unknown: Cougar, Kodiak, Lucky Strike, NAS

                // Camel, Camel Snus
                {
                    '@font-face': {
                        fontFamily: 'United Sans',
                        src: `url('${unitedsans400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Grizzly
                {
                    '@font-face': {
                        fontFamily: 'Roboto',
                        src: `url('${roboto700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
                // Newport, Velo
                {
                    '@font-face': {
                        fontFamily: 'Helvetica',
                        src: `url('${helvetica400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Pall Mall
                {
                    '@font-face': {
                        fontFamily: 'Bebas Neue',
                        src: `url('${bebasneue400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Vuse
                {
                    '@font-face': {
                        fontFamily: 'Gotham Book',
                        src: `url('${gothambook400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Vuse
                {
                    '@font-face': {
                        fontFamily: 'Anybody Condensed',
                        src: `url('${anybodycondensed400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Kodiak
                {
                    '@font-face': {
                        fontFamily: 'Modesto Text',
                        src: `url('${modestotext400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                // Lucky Strike
                {
                    '@font-face': {
                        fontFamily: 'Trade Gothic Bold Condensed',
                        src: `url('${tradegothiccondensed700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
                // NAS
                {
                    '@font-face': {
                        fontFamily: 'Lato',
                        src: `url('${lato900}') format("woff2")`,
                        fontWeight: 900,
                        fontStyle: 'normal',
                    },
                },
                // Levi Garrett
                {
                    '@font-face': {
                        fontFamily: 'Caladea',
                        src: `url('${caladea700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
                // Sensavape
                // {
                //     '@font-face': {
                //         fontFamily: 'Tomato Grotesk',
                //         src: `url('${tomatogrotesk700}') format("woff2")`,
                //         fontWeight: 700,
                //         fontStyle: 'normal',
                //     },
                // },
                {
                    '@font-face': {
                        fontFamily: 'Tomato Grotesk',
                        src: `url('${tomatogrotesk700}') format("otf")`,
                        fontWeight: 700,
                        fontStyle: 'normal',
                    },
                },
            ]}
        />
    )
}
