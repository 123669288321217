export const updateStyles = styleSettings => {
    // console.log( `%c [STYLE UPDATE SETTINGS] ${JSON.stringify( settings, null, 4 )}`, 'background: #3D4047; color: #05F0ED' );
    const root = document.documentElement
    if ( !root ) { console.log( '[NO ROOT DOCUMENT ELEMENT]' ) }

    if ( styleSettings ) {
        // primary button
        root?.style.setProperty( '--veratad-primary-bg', styleSettings.primary_back )
        root?.style.setProperty( '--veratad-primary-color', styleSettings.primary_text )
        // secondary button
        root?.style.setProperty( '--veratad-secondary-bg', styleSettings.secondary_back )
        root?.style.setProperty( '--veratad-secondary-color', styleSettings.secondary_text )
        // icons
        root?.style.setProperty( '--veratad-icon', styleSettings.icons )
    } else {
        // console.log( '[NO STYLE SETTINGS PRESENT]' )
    }
}
