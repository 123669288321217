import React, { useEffect } from 'react' // , { useEffect }
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import {
    useSelector, // , useDispatch
} from 'react-redux'
import { MantineProvider, Box, Container } from '@mantine/core'
import {
    // eslint-disable-next-line no-unused-vars
    useViewportSize,
    useMediaQuery,
} from '@mantine/hooks'
import { isMobileOnly } from 'react-device-detect'
import loadable from '@loadable/component'

// import { phonematchGetIp } from '../../state/phonematch.slice'

import { useJourneyUpdate, useGetSource } from '../../hooks/journeyHooks'
import { useDeviceReporter } from '../../hooks/hooks'
import { useTheme } from '../../nativeThemeMapper'

// import { store } from '../../store'

const Header = loadable(() => import(/* webpackPreload: true */ `../components.generic/header`))
const PhonematchVerify = loadable(({ source }) => import(/* webpackPreload: true */ `./${source}/screens/verify`))
const PhonematchMoreDetails = loadable(({ source }) =>
    import(/* webpackPreload: true */ `./${source}/screens/phonematchMoreDetails`),
)
const PhonematchOtp = loadable(({ source }) => import(/* webpackPreload: true */ `./${source}/screens/otp`))
const PhonematchResend = loadable(({ source }) => import(/* webpackPreload: true */ `./${source}/screens/resend`))
const PhonematchResult = loadable(({ source }) => import(/* webpackPreload: true */ `./${source}/screens/result`))
const Loading = loadable(() => import(/* webpackPrefetch: true */ `../components.generic/loading`))
const AuthLoadingRedirect = loadable(({ source }) =>
    import(/* webpackPrefetch: true */ `./${source}/screens/authLoadingRedirect`),
)
const Dialogue = loadable(() => import(/* webpackPrefetch: true */ `../components.generic/dialogue`))

export default function PhonematchManager() {
    // const logJson = ( description, json ) => console.log( `%c [${description.toUpperCase()}] ${JSON.stringify( json, null, 4 )}`, 'background: #222; color: #bada55' ) // 'background: #3D4047; color: #05F0ED'
    // logJson( 'store', store.getState())

    const { journeyId } = useSelector(state => state.journey)
    const {
        // inIframeContext,
        scroll,
    } = useSelector(state => state.ui)
    // const dispatch = useDispatch()
    const location = useLocation()
    const { path } = useRouteMatch()
    const { height: viewportHeight } = useViewportSize()
    const { getNativeMappedTheme } = useTheme()
    // const { logoName, } = useSelector( state => state.journey.config )

    // If this is a handoff, update journey stop and path root
    useJourneyUpdate(location)
    // Map component sources for the journey (custom vs. default)
    const { getSource } = useGetSource()

    // const { reportRun } = useSelector(state => state.deviceDetect)
    // useEffect(() => {
    //     console.log('reportRun', reportRun)
    // }, [reportRun])

    useDeviceReporter()

    const maxDeviceWidth1224 = useMediaQuery('(max-device-width: 1224px)')

    // useEffect(() => {
    //     // TODO: raise this to a hook, possibly combine with useDeviceReporter()
    //     // for new hook, useDeviceDetection() that reports device data to be
    //     // stored and also saves device data required for decisioning to
    //     // appropriate slice. This would likely raise phonematchGetIp out of
    //     // phonematch.slice to deviceDetect.slice
    //     dispatch( phonematchGetIp())
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [] )

    const gridTemplateRows = maxDeviceWidth1224
        ? // ? `[phm-manager-spacer] ${viewportHeight < 680 && isMobileOnly && inIframeContext ? 80 : 70}vh 2px [phm-manager-foot] 1fr` // 80
          `[phm-manager-spacer] ${viewportHeight < 680 && isMobileOnly ? 80 : 70}vh 2px [phm-manager-foot] 1fr` // 80
        : '[phm-manager-spacer] 61vh 2px [phm-manager-foot] 1fr'

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={getNativeMappedTheme(journeyId)} // TODO: Raise to App.Manager and make journey themes global
        >
            <Box
                className='phm-manager-box'
                sx={{
                    margin: 0,
                    padding: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: scroll ? 'scroll' : null,
                }}
            >
                <Header />

                <Container
                    className='phm-manager-container'
                    fluid
                    // px={ isIframe ? 80 : 20 }
                    mx={0}
                    px={maxDeviceWidth1224 ? 20 : 80}
                    // sx={ { height: '100%', } }
                >
                    <Box
                        className='phm-manager-grid'
                        sx={{
                            display: 'grid',
                            gridTemplateRows,
                            gridTemplateColumns: '[phm-manager-single-col] 100%',
                            gap: '0px 0px',
                        }}
                    >
                        <Switch>
                            {/* <Route path={ `${path}/` } exact>
                                <Loading title="TEST TITLE" />
                            </Route> */}
                            {/* <Route path={ `${path}/` } exact>
                                <PhonematchResult source={ getSource( 'PhonematchResult' ) } />
                            </Route> */}

                            <Route path={`${path}/more-details`}>
                                <PhonematchMoreDetails source={getSource('PhonematchMoreDetails')} />
                            </Route>

                            <Route path={`${path}/`} exact>
                                <PhonematchVerify source={getSource('PhonematchVerify')} />
                            </Route>
                            <Route path={`${path}/verify`} exact>
                                <PhonematchVerify source={getSource('PhonematchVerify')} />
                            </Route>
                            <Route path={`${path}/otp`} exact>
                                <PhonematchOtp source={getSource('PhonematchOtp')} />
                            </Route>
                            <Route path={`${path}/resend`} exact>
                                <PhonematchResend source={getSource('PhonematchResend')} />
                            </Route>
                            <Route path={`${path}/result`} exact>
                                <PhonematchResult source={getSource('PhonematchResult')} />
                            </Route>
                            <Route path={`${path}/loading`} exact>
                                {/* In this case, a history.push adds the title string to location.state.title */}
                                <Loading />
                            </Route>
                            <Route path={`${path}/authloading`} exact>
                                <AuthLoadingRedirect source={getSource('AuthLoadingRedirect')} />
                            </Route>
                        </Switch>
                    </Box>

                    <Dialogue exit />
                </Container>
            </Box>
        </MantineProvider>
    )
}
